import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Toaster from "../Basic/Toaster";
import { useService } from "../hooks/useService";

import HeaderNavbar from '../Basic/HeaderNavbar';
import Loader from '../Basic/Loader';

function Marketplace(props) {
    const navigate = useNavigate();
    const { marketplaceList } = useService();

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [marketplaceData, setMarketplaceData] = useState([]);
    const [loading, setLoading] = useState(false);

    const marketplaceCache = {};

    const getMarketplaceList = () => {
        // Check if data is in cache
        if (marketplaceCache["BUYER"]) {
            setMarketplaceData(marketplaceCache["BUYER"]);
            return;
        }

        setLoading(true);
        const maxLength = props.maxCount !== 'all' ? props.maxCount : 100;
        marketplaceList("BUYER", 1, maxLength)
            .then(response => {
                if (response && response.data && response.data.length) {
                    setMarketplaceData(response.data);
                    marketplaceCache["BUYER"] = response.data; // Update cache
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const renderMarketplaceListTemplate = (marketplaceData) => {

        return (

            //<Card.Img variant="top" src={item.image} />
            //<div className="liquid-label"><span className="liquid-text">Liquidation</span></div>
            //</Card.Header>
            //<Card.Body >
            //<Card.Title className='text-center'>View ({item.lots.filter(item => item.status === 'active').length || 0}) Active Lots{/*item.name*/}</Card.Title>
            //<Card.Text>

            <div className={props.center ? "row justify-content-center clearfix marketplace-list-wrapper" : "row clearfix"}>
                {marketplaceData.map((item, index) => (
                    <div className="col-lg-3 col-md-6 col-sm-12 category-block" key={index}>
                        <div className="category-block-one wow fadeInUp animated animated" data-wow-delay={index * 2 + "00ms"} data-wow-duration="1500ms" onClick={() => navigate(`/lot/${item.mpid}`)}>
                            <figure className="image-box"><img src={item.image} alt="" /></figure>
                            {props.showCount && <div className="active-lots"><span className="liquid-text">({item.lots.filter(item => item.status === 'active').length || 0}) Active Lots</span></div>}
                            <h5><a href="index.html">{item.name}</a></h5>
                        </div>
                    </div>
                ),
                )}
            </div>
        )
    };

    useEffect(() => {
        getMarketplaceList();
    }, []);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="auto-container">
                        <div className="sec-title">
                            {props.heading ? (
                                <h2>{props.heading}</h2>
                            ) : (
                                <h2>Leading liquidation marketplaces for all your inventory needs</h2>
                            )}
                            {props.subheading ? (
                                <p>{props.subheading}</p>
                            ) : (
                                <p>Follow the most popular marketplaces and get exclusive items from Togofogo Lots</p>
                            )}
                            <span className="separator" style={{ backgroundImage: 'url(assets/images/icons/separator-1.png)' }} />
                        </div>
                        {marketplaceData.length ? renderMarketplaceListTemplate(marketplaceData) : ''}
                    </div>
                </>
            )}


            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </>
    );
}

export default Marketplace;
