import React from 'react'
import Countdown from 'react-countdown';
import moment from 'moment-timezone';

const BidCounter = (props) => {

    const showLabel = props.showLabel !== undefined ? props.showLabel : true;
    const bidStartVal = props['bid-start'];
    const bidEndVal = props['bid-end'];

    const now = moment();
    const bidStart = bidStartVal ? moment(bidStartVal).valueOf() : now.valueOf();
    const bidEnd = bidEndVal ? moment(bidEndVal).valueOf() : now.valueOf();
    
    const Completionist = () => (
        <span className='bid-expired'>
            BID EXPIRED
        </span>
    );

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Completionist />;
        } else {
            return (
                <>
                    {showLabel ? (<span>Time Left: </span>) : ('')}
                    <span className='bid-timer'>
                        {days ? `${days} ${days > 1 ? ' Days ' : ' Day '}` : ''} {hours} Hr {minutes} Min {seconds} Sec
                    </span>
                </>
            );
        }
    };

    const countdownDate = now.isBefore(bidStart) ? bidStart : bidEnd;
    // let countdownDate = bidEnd;

    return (<Countdown
        date={countdownDate}
        renderer={renderer}
    />)
}

export default BidCounter;