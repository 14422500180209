import React from 'react';
// import Marketplace from '../Components/Buyer/Marketplace';
import HeaderNavbar from '../Components/Basic/HeaderNavbar';
import Footer from '../Components/Basic/Footer';
import Banner from '../Components/Basic/Banner';
import { useNavigate } from "react-router-dom";
import { useService } from "../Components/hooks/useService";
import LeadingLots from '../Components/Basic/LeadingLots';
import LeadingMarketplaces from '../Components/Basic/LeadingMarketplaces';
import moment from 'moment-timezone';

function HomePage() {
  const navigate = useNavigate();
  const { saveAnalyticData } = useService();

  const today = moment().tz("Asia/Kolkata");

  const getUserIP = async () => {
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      return console.error('Error:', error);
    }
  }

  const trackPageView = async () => {
    const data = {
      url: window.location.href,
      referrer: document.referrer,
      language: navigator.language,
      platform: navigator.platform,
      ip: await getUserIP(),
      userAgent: navigator.userAgent,
      date: today.format('YYYY-MM-DD'),
      timestamp: today.toISOString(),
    };

    saveAnalyticData(data);
  }

  trackPageView();

  return (
    <>
      <HeaderNavbar />
      <Banner type={'home'} />
      <LeadingMarketplaces maxCount={4} />
      <LeadingLots maxCount={4} />

      <div>
        {/* service-section */}
        <section className="service-section">
          <div className="auto-container">
            <div className="inner-container">
              <div className="row clearfix">
                <div className="col-lg-3 col-md-6 col-sm-12 service-block">
                  <div className="service-block-one">
                    <div className="inner-box">
                      <div className="icon-box"><i className="flaticon-truck" /></div>
                      <h3><a>Live Auctions Available</a></h3>
                      {/* <p>Free shipping on oder over $100</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 service-block">
                  <div className="service-block-one">
                    <div className="inner-box">
                      <div className="icon-box"><i className="flaticon-credit-card" /></div>
                      <h3><a>Trusted Marketplaces</a></h3>
                      {/* <p>We ensure secure payment with PEV</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 service-block">
                  <div className="service-block-one">
                    <div className="inner-box">
                      <div className="icon-box"><i className="flaticon-24-7" /></div>
                      <h3><a>No Middleman</a></h3>
                      {/* <p>Contact us 24 hours a day, 7 days a week</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 service-block">
                  <div className="service-block-one">
                    <div className="inner-box">
                      <div className="icon-box"><i className="flaticon-undo" /></div>
                      <h3><a>Diverse Product Categories</a></h3>
                      {/* <p>Simply return it within 30 days for an exchange.</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* service-section end */}
        {/* cta-section */}
        <section className="cta-section">
          {/* linear-gradient(90deg, var(--primary-color) 10%, transparent 110%), url(assets/img/home-banner.png) */}
          {/* <div className="image-layer" style={{ backgroundImage: 'url(assets/images/background/cta-bg-1.jpg)' }} /> */}
          <div className="image-layer" style={{ backgroundImage: 'linear-gradient(90deg, #0494bc 10%, transparent 110%), url(assets/img/home-banner.png)' }} />
          <div className="auto-container">
            <div className="cta-inner centred">
              <div className="pattern-layer">
                <div className="pattern-1" style={{ backgroundImage: 'url(assets/images/shape/shape-2.png)' }} />
                <div className="pattern-2" style={{ backgroundImage: 'url(assets/images/shape/shape-3.png)' }} />
              </div>
              <h2>Place your bids and win amazing lots.</h2>
              <p>Welcome to the new range of liquidation lots. We have over three decades of experience in
                liquidation industry</p>
              <a onClick={() => navigate('/all-lots')} className="theme-btn-one">Bid Now<i className="flaticon-right-1" /></a>
            </div>
          </div>
        </section>
        {/* cta-section end */}
      </div>


      {/*<Marketplace heading="Leading Marketplaces" maxCount={4} />*/}

      <Footer />
    </>
  );
}

export default HomePage;
