import React, { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function Toaster({ showToaster, setShowToaster, error, message }) {

    useEffect(() => {
        setShowToaster(showToaster);
    }, [showToaster]);

    return (
        <ToastContainer
            className="p-3"
            position={'bottom-start'}
            style={{ zIndex: 9999 }}
        >
            <Toast show={showToaster} bg={'light'} onClose={() => setShowToaster(false)} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">
                    <img
                        alt=""
                        src="https://togofogo.com/assets/images/logo.png"
                        width="120"
                        className="d-inline-block align-top toaster-logo"
                    />
                    </strong>
                </Toast.Header>
                <Toast.Body className={`Light ${error ? 'is-invalid-message' : 'is-valid-message'}`}>{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

export default Toaster;
