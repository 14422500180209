import React from 'react';
import { useNavigate } from "react-router-dom";
import Lot from './../Buyer/Lot';

function LeadingLots(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* shop-section */}
      <section className="shop-section">
        <div className="auto-container">
          <div className="sec-title">
            <h2>Our Top Bidding Lots</h2>
            <p>There are some lots that we featured for choose your best</p>
            <span className="separator" style={{ backgroundImage: 'url(assets/images/icons/separator-1.png)' }} />
          </div>
          <div className="sortable-masonry">
            <Lot 
            type="all" 
            showFilters="false" 
            maxCount={props.maxCount}
            showNewTag={true}
            disablePagination={true}
            />
          </div>
          <div className="more-btn centred"><a onClick={() => navigate('/all-lots')} className="theme-btn-one">View All Auctions<i className="flaticon-right-1" /></a></div>
        </div>
      </section>
      {/* shop-section end */}

    </>
  );
}

export default LeadingLots;