import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Toaster from "../Basic/Toaster";
import { useService } from "../hooks/useService";

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Footer from '../Basic/Footer';
import HeaderNavbar from '../Basic/HeaderNavbar';

function ResetPassword(props) {
    const navigate = useNavigate();
    const { resetPassword } = useService();

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');

    const [validated, setValidated] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            setShowToaster(true);
            setToasterMessage('Validation failed. Please check your inputs.');
            return false;
        } else if (password !== confirmPassword) {
            event.stopPropagation();
            setPasswordsMatch(password === confirmPassword);

            setShowToaster(true);
            setToasterMessage('Validation failed. Password could not be matched.');
            return false;
        }

        // Gather form data
        const formData = new FormData(form);
        const payload = {};
        for (let [key, value] of formData.entries()) {
            payload[key] = value;
        }

        // Submit data
        resetPassword(payload)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setShowToaster(true);
                    setToasterMessage('Password updated successfully.');
                    setTimeout(window.location.reload(), 5000);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    return (<>
        <HeaderNavbar />
        <div className="main-parant-1">
            <section className="sec-1 register-wrapper">
                <div className="main-width">
                    <div className="main-box">
                        <div className="main-toggel-grid">
                            {/* <div>
                                <div className="logo-details">
                                    <img src="assets/img/Layer.svg" className="img-icon adcw" alt="" />
                                </div>
                            </div> */}
                            <div>
                                <h1 className="Get-title mb-3">Reset Password</h1>
                            </div>
                        </div>

                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} xs="12" controlId="validationCustom02">
                                    <Form.Label>Email ID<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        required
                                        name='email'
                                        type="email"
                                        placeholder="Email Address"
                                        defaultValue=""
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid email.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} xs="12" controlId="validationCustom02">
                                    <Form.Label>New Password<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        required
                                        name='newPassword'
                                        type="password"
                                        placeholder="New Password"
                                        defaultValue=""
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} xs="12" controlId="validationCustom02">
                                    <Form.Label>Confirm Password<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        required
                                        name='confirmPassword'
                                        type="password"
                                        placeholder="confirm Password"
                                        defaultValue=""
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        isInvalid={!passwordsMatch}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Password didn't match.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <button className='theme-btn-one login-btn' type="submit">UPDATE PASSWORD<i className="flaticon-right-1" /></button>
                        </Form>
                    </div>
                </div>
            </section>
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </div>
        <Footer />
    </>
    );
}

export default ResetPassword;
