import React, { useEffect, useState } from 'react';
import Sidebar from "../Basic/Sidebar";
import { validateInput, content } from '../Config/config';
import Toaster from "../Basic/Toaster";
import ReactTooltip from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { useService } from "../hooks/useService";
import { format } from 'date-fns';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Loader from '../Basic/Loader';
import { LuUserCheck } from "react-icons/lu";
import { TiLockOpen, TiLockClosed } from "react-icons/ti";

function AdminUserList({ role }) {

    const navigate = useNavigate();
    const { getUserList, manageAccessToUsers, AuthorizeToUsers, adminSignup } = useService();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [usersData, setUserData] = useState([]);
    const [validated, setValidated] = useState(false);

    const getUserListHandler = (role) => {
        setTimeout(setLoading(true), 600);
        getUserList(role.toUpperCase())
            .then(response => {
                if (response && response.length) {
                    setUserData(response);
                } else {
                    setUserData([]);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const submitAddAdminHandler = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            setShowToaster(true);
            setToasterMessage('Validation failed. Please check your inputs.');
            return false;
        }

        // Gather form data
        const formData = new FormData(form);
        const payload = {};
        for (let [key, value] of formData.entries()) {
            payload[key] = value;
        }

        payload.uname = payload.email.split('@')[0];

        // Submit data
        adminSignup(payload)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setShowToaster(true);
                    setToasterMessage('Admin Added successfully');
                    setTimeout(window.location.reload(), 2000);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const handleSearch = () => {
        let searchText = document.getElementById('searchItem');
        if (searchText.value.length >= 3) {

            getUserListHandler(searchText.value);
        } else if (searchText.value.length === 0) {

            getUserListHandler();
        }
    }

    const accessHandler = (user, access) => {
        setTimeout(setLoading(true), 600);
        manageAccessToUsers(user.uid, access)
            .then(response => {
                setReload(true);
                setShowToaster(true);
                setToasterMessage(response.message);
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const confirmUserHandler = (user) => {
        setTimeout(setLoading(true), 600);
        AuthorizeToUsers(user.uid)
            .then(response => {
                setReload(true);
                setShowToaster(true);
                setToasterMessage(response.message);
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const _handleUserRows = (list) => {
        return (
            <tbody>
                {list.map((item, index) => (
                    <tr className="gry-tred" key={index}>
                        <td className="body-title">
                            {role === 'buyer' && (
                                <a onClick={() => navigate(`/buyer-info/${item.uid}`)} className="numberw">
                                    {item.firstName?.toLowerCase()?.capitalize()} {item.lastName?.toLowerCase()?.capitalize()}
                                </a>
                            )}
                            {role !== 'buyer' && (
                                <span>{item.firstName} {item.lastName}</span>
                            )}
                        </td>
                        <td className="body-title username-wrapper">{item.uname}</td>
                        <td className="body-title email-wrapper">{item.email}</td>
                        <td className="body-title">{item.phone}</td>
                        <td className="body-title">{item?.utype?.toLowerCase()?.capitalize()}</td>
                        <td className="body-title">{format(new Date(item?.createdAt), "dd/MM/yyyy")}</td>
                        <td className="body-title text-center">
                            <div className='user-actions-wrapper'>
                                {item.isBlocked ? (
                                    <TiLockClosed className={'block'} onClick={() => accessHandler(item, 'unblock')} />
                                ) : (
                                    <TiLockOpen className={'unblock'} onClick={() => accessHandler(item, 'block')} />
                                )}
                                <LuUserCheck className={item.isActive ? 'approved' : 'approve'} onClick={() => confirmUserHandler(item)} />
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        )
    }

    useEffect(() => {
        let adminSection = document.getElementById("adminSection");
        let buyerSection = document.getElementById("buyerSection");
        let sellerSection = document.getElementById("sellerSection");
        let userSummarySection = document.getElementById("userSummarySection");
        userSummarySection.classList.add("showMenu");

        if (role === 'buyer') {
            buyerSection.classList.add("active");
        } else if (role === 'admin') {
            adminSection.classList.add("active");
        } else if (role === 'seller') {
            sellerSection.classList.add("active");
        }

        setReload(false);
        getUserListHandler(role);
    }, [role, reload]);


    return (
        <div className="main-parant-1">
            <section className="sec-1">
                <Sidebar />
                <section className="home-section">
                    <div className="main-rightbar-box">
                        <div>
                            <div className="Rule-page">
                                <div className="main-jaste">
                                    <div className="mi">
                                        <h1 className="left-title">{role.capitalize()} List</h1>
                                    </div>
                                    <div className="main-flxwer">
                                        {/* <a className="btm-dwece pointer" data-bs-toggle="modal" data-bs-target="#ImportUsers"><img src="assets/img/arrow.up.doc.svg" className="pluserd" alt="" />IMPORT Users</a> */}
                                        {role === 'admin' && <a data-bs-toggle="modal" data-bs-target="#AddAdmin" className="btm-blueed pointer">Add New Admin</a>}
                                    </div>
                                </div>
                                <div>
                                    {/* <div className="main-search-side mb-3">
                                        <div className="btn-input wimor adcqdq search-wrapper">
                                            <input type="text" id="searchItem" placeholder="Search by User Name" className="name-poi aede search" onChange={handleSearch} />
                                            <div>
                                                <img src="assets/img/SAERCH1.svg" className="SAERCH1" alt="" />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="wihte-erya">
                                        <div>
                                            <table className="w-100 m-0">
                                                <thead>
                                                    <tr className="gry-tr h-74">
                                                        <td className="head-title padin-24">Name</td>
                                                        <td className="head-title padin-24">Username</td>
                                                        <td className="head-title padin-24">Email ID</td>
                                                        <td className="head-title padin-24">Phone</td>
                                                        <td className="head-title padin-24 width-100">Role</td>
                                                        <td className="head-title padin-24 width-120">Created On</td>
                                                        <td className="head-title padin-24 text-center">
                                                            {/* <div>Actions</div> */}
                                                            <div className='actions-admin-list'><span>Block/Unblock</span><span>Authorize</span></div>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                {usersData.length !== 0 &&
                                                    _handleUserRows(usersData)
                                                }
                                            </table>
                                        </div>
                                        {usersData.length === 0 &&
                                            <div className="space-26px">
                                                <p className="create-oneedw">You haven't got any {role} yet!</p>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            {/* <!-- Modal Add Admin --> */}
            <div className="modal fade" id="AddAdmin" tabIndex={"-1"} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog proder">
                    <div className="modal-content styled">
                        <div className="modal-FLAX">
                            <h5 className="modal-title mb-3">Add Admin</h5>
                            <button type="button" className="btner" data-bs-dismiss="modal" aria-label="Close"><img src="assets/img/multiply.svg" className="multiply-ic" alt="" /></button>
                        </div>
                        <Form noValidate validated={validated} onSubmit={submitAddAdminHandler}>
                            <Row className="mb-3">
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="First Name"
                                    >
                                        <Form.Control size="sm"
                                            required
                                            name='firstName'
                                            placeholder="Enter First Name"
                                            aria-label="First Name"
                                            aria-describedby="basic-addon2"
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Last Name"
                                    >
                                        <Form.Control size="sm"
                                            required
                                            name='lastName'
                                            placeholder="Enter Last Name"
                                            aria-label="Last Name"
                                            aria-describedby="basic-addon2"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Admin Email ID"
                                    >
                                        <Form.Control size="sm"
                                            required
                                            name='email'
                                            placeholder="Enter Admin Email ID"
                                            aria-label="Admin Email ID"
                                            aria-describedby="basic-addon2"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Admin Phone"
                                    >
                                        <Form.Control size="sm"
                                            required
                                            type='number'
                                            name='phone'
                                            placeholder="Enter Admin Phone Number"
                                            aria-label="Admin Phone"
                                            aria-describedby="basic-addon2"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Admin Password"
                                    >
                                        <Form.Control size="sm"
                                            required
                                            type='password'
                                            name='password'
                                            placeholder="Enter Password"
                                            aria-label="Password"
                                            aria-describedby="basic-addon2"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                label="Admin"
                                                name="utype"
                                                type={type}
                                                value='ADMIN'
                                                id={`inline-${type}-1`}
                                                required
                                            />
                                            <Form.Check
                                                inline
                                                label="Finance Admin"
                                                name="utype"
                                                type={type}
                                                value='FINANCE'
                                                id={`inline-${type}-2`}
                                                required
                                            />
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                            <div className="main-cntwr">
                                <Button className='btm-blueed-btn pointer' name='update-save' type="submit">Save</Button>
                                {/* <a className="SAVE-btn blue" >SAVE</a> */}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

            <ReactTooltip html={true} className="tooltip" />
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </div>
    );
}

export default AdminUserList;