import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Toaster from "../Basic/Toaster";
import { useService } from "../hooks/useService";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Footer from '../Basic/Footer';
import HeaderNavbar from '../Basic/HeaderNavbar';

function Signup(props) {
    const navigate = useNavigate();
    const { buyerSignup } = useService();

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');

    const [validated, setValidated] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            setShowToaster(true);
            setToasterMessage('Validation failed. Please check your inputs.');
            return false;
        }

        // Gather form data
        const formData = new FormData(form);
        const payload = {};
        for (let [key, value] of formData.entries()) {
            payload[key] = value;
        }

        payload.uname = payload.email.split('@')[0];

        // Submit data
        buyerSignup(payload)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setShowToaster(true);
                    setToasterMessage('Buyer Added successfully, please wait for the admin to approve your account.');
                    setTimeout(window.location.reload(), 5000);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    return (<>
        <HeaderNavbar />
        <div className="main-parant-1">
            <section className="sec-1 register-wrapper">
                <div className="main-width">
                    <div className="main-box">
                        <div className="main-toggel-grid">
                            {/* <div>
                                <div className="logo-details">
                                    <img src="assets/img/Layer.svg" className="img-icon adcw" alt="" />
                                </div>
                            </div> */}
                            <div>
                                <h1 className="Get-title mb-3">Buyer Registration</h1>
                            </div>
                        </div>
                        
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} xs="6" controlId="validationCustom01">
                                    <Form.Label>First name<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        required
                                        name='firstName'
                                        type="text"
                                        placeholder="First name"
                                        defaultValue=""
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter First name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs="6" controlId="validationCustom02">
                                    <Form.Label>Last name<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        required
                                        name='lastName'
                                        type="text"
                                        placeholder="Last name"
                                        defaultValue=""
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Last name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} xs="12" controlId="validationCustom02">
                                    <Form.Label>Email ID<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        required
                                        name='email'
                                        type="email"
                                        placeholder="Email Address"
                                        defaultValue=""
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid email.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} xs="12" controlId="validationCustom02">
                                    <Form.Label>Password<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        required
                                        name='password'
                                        type="password"
                                        placeholder="Password"
                                        defaultValue=""
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} xs="12" controlId="validationCustom02">
                                    <Form.Label>Phone Number<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        required
                                        name='phone'
                                        type="number"
                                        placeholder="Phone Number"
                                        defaultValue=""
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid 10 digit Phone Number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            {/* <Row className="mb-3">
                                <Form.Group as={Col} xs="12" controlId="validationCustom02">
                                    <Form.Label>Organization Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Organization Name"
                                        defaultValue=""
                                    />
                                </Form.Group>
                            </Row> */}
                            <Form.Group className="mb-3 signup-input d-flex">
                                <Form.Control
                                    type="checkbox"
                                    className='d-inline-block mr-3'
                                />
                                <label className='d-inline pr-4'>
                                    I'd like to receive emails about news & updates from Trader.
                                </label>
                                <Form.Control.Feedback type="invalid">
                                    Please Accept the Terms of use and Privacy policy
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3 signup-input">
                                <Form.Control
                                    type="checkbox"
                                    className='d-inline-block mr-3'
                                    required
                                />
                                <label className='d-inline'>I agree to <span className="border-botm">Terms</span> of use and <span className="border-botm">Privacy policy</span>
                                </label>
                                <Form.Control.Feedback type="invalid" className='w-100'>
                                    Please Accept the Terms of use and Privacy policy
                                </Form.Control.Feedback>
                            </Form.Group>
                            <button className='theme-btn-one login-btn' type="submit">REGISTER<i className="flaticon-right-1" /></button>
                        </Form>
                        <p className="mt-4">Already a user?
                            <a className="LOG-iN-btn" onClick={() => navigate("/")}>&nbsp;LOG iN</a>
                        </p>
                        <p className="mt-2">Forgot password?
                            <a className="LOG-iN-btn" onClick={() => navigate("/reset-password")}> 
                            &nbsp;Click here 
                                </a>
                            </p>

                    </div>
                </div>
            </section>
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </div>
        <Footer />
    </>
    );
}

export default Signup;
