import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import Toaster from "../Basic/Toaster";
import { useService } from "../hooks/useService";
import { useAuth } from "../hooks/useAuth";
import Sidebar from "../Basic/Sidebar";
import ProductList from './ProductList';
import PageNavigation from '../Basic/PageNavigation';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MultiSelect } from "react-multi-select-component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';

import ConfirmationModal from '../Basic/ConfirmationModal';

/**
 * Renders the AdminLotDetails component.
 *
 * @component
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The rendered AdminLotDetails component.
 */
function AdminLotDetails(props) {

    moment.tz.setDefault("Asia/Kolkata");

    const navigate = useNavigate();
    const { state } = useLocation();
    const { getlotDetails, updateLotDetails, publishNewLot, getSubcategories, deleteLot } = useService();
    const { adminUser } = useAuth();
    let { lotid } = useParams();

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [lotInformation, setLotDetails] = useState({});
    // const [loading, setLoading] = useState(false);
    // const [lotExpired, setLotExpired] = useState(false);
    const [lotSold, setLotSold] = useState(false);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [selectedSlab, setSelectedSlab] = useState(1000);

    const [reload, setReload] = useState(false);
    const [lotImages, setLotImages] = useState([]);

    const [validated, setValidated] = useState(false);
    // const [message, setMessage] = useState([]);
    // const [validateMessage, setValidateMessage] = useState('');

    // Get today's date in Asia/Kolkata timezone
    const today = moment().tz("Asia/Kolkata");

    // Get tomorrow's date by adding 1 day to today's date
    const tomorrow = today.clone().add(1, 'days');

    const [startDate, setStartDate] = useState(today.format());
    const [endDate, setEndDate] = useState(tomorrow.format());

    let mpid = state?.mpid || lotInformation?.marketplace_info?.mpid;

    const getLotHandler = async () => {
        // setTimeout(setLoading(true), 600);
        getlotDetails(lotid)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setLotDetails(response);
                    setLotImages(response?.lot_image_urls);
                    setSelectedSlab(response.incremental_slab);
                    setLotSold(response.status === 'sold' || response.status === 'winning');

                    response?.bid_start_date
                        ? setStartDate(moment(response.bid_start_date).tz("Asia/Kolkata").format())
                        : setStartDate(today.format());
                    response?.bid_end_date
                        ? setEndDate(moment(response.bid_end_date).tz("Asia/Kolkata").format())
                        : setEndDate(tomorrow.format());

                    const selectedCats = response?.sub_categories?.map((item) => {
                        return { value: item, label: item };
                    });
                    // console.log('Selected Categories: ', selectedCats);
                    setSelectedSubCategories(selectedCats);

                    // if (response.status === 'winning' || response.bid_end_date < today.format()) {
                    //     setLotExpired(true);
                    // } else if (response.status !== 'active') {
                    //     setLotExpired(true);
                    // }
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const getSubCategoriesHandler = async () => {
        // setTimeout(setLoading(true), 600);
        getSubcategories()
            .then(async response => {
                if (response && Object.keys(response).length) {
                    let subcategoriesArr = [];

                    for (let category of response) {
                        let selectedCats = category?.categories?.map((item) => {
                            return { value: item.category, label: item.category };
                        });

                        subcategoriesArr = [...subcategoriesArr, ...selectedCats];
                    }
                    setCategoriesList(subcategoriesArr);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const publishLotHandler = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            setShowToaster(true);
            setToasterMessage('Validation failed. Please check your inputs.');
            return false;
        }

        // Gather form data
        const formData = new FormData(form);
        const payload = {};
        for (let [key, value] of formData.entries()) {
            payload[key] = value;
        }

        payload.sub_categories = selectedSubCategories.map((item) => item.value) || [];
        payload.bid_start_date = moment(startDate).tz("Asia/Kolkata").toISOString();
        payload.bid_end_date = moment(endDate).tz("Asia/Kolkata").toISOString();
        payload.bidding_method = "hybrid";
        payload.lot_image_urls = lotImages;

        // Submit data
        publishNewLot(payload, lotid)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setShowToaster(true);
                    setToasterMessage('Lot published successfully');
                    setTimeout(navigate('/lot-list'), 2000);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const updateLotHandler = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            setShowToaster(true);
            setToasterMessage('Validation failed. Please check your inputs.');
            return false;
        }

        // Gather form data
        const formData = new FormData(form);
        const payload = {};
        for (let [key, value] of formData.entries()) {
            payload[key] = value;
        }

        payload.sub_categories = selectedSubCategories.map((item) => item.value) || [];
        payload.bid_start_date = moment(startDate).tz("Asia/Kolkata").toISOString();
        payload.bid_end_date = moment(endDate).tz("Asia/Kolkata").toISOString();
        payload.bidding_method = "hybrid";
        payload.lot_image_urls = lotImages;

        updateLotDetails(payload, lotid)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setShowToaster(true);
                    setToasterMessage('Lot updated successfully');
                    setReload(true);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const deleteLotHandler = async () => {
        // setLoading(true);

        deleteLot(lotid)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setShowToaster(true);
                    setToasterMessage('Lot deleted successfully');
                    setTimeout(navigate('/lot-list'), 600);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const submitLotHandler = async (event) => {
        event.preventDefault();
        const submitter = event.nativeEvent.submitter.name;

        if (submitter === 'update-publish') {
            publishLotHandler(event);
        } else if (submitter === 'update-save') {
            updateLotHandler(event);
        }
    };

    const handleInputChange = (index, event) => {
        const getImages = lotImages.filter(str => /\w+/.test(str));
        const newImageUrls = [...getImages];
        newImageUrls[index] = event.target.value;
        setLotImages(newImageUrls);
    };

    const addMoreImageHandler = () => {
        setLotImages([...lotImages, '']);
    };

    const handleToggleConfirmationModal = (data) => {
        setShowConfirmation(false);
    };

    const handleSubmitConfirmationModal = (data) => {
        deleteLotHandler(data);
    };

    const showConfirmationModal = () => {
        setShowConfirmation(true);
    };

    useEffect(() => {
        setReload(false);
        const getInitialData = async () => {
            await getSubCategoriesHandler();
            await getLotHandler();
        };

        getInitialData();
    }, [reload]);


    return (
        <div className="main-parant-1">
            <section className="sec-1">
                <Sidebar />
                <section className="home-section">
                    <div className="main-rightbar-box">
                        <div>
                            <Form noValidate validated={validated} onSubmit={submitLotHandler}>
                                <div className="Rule-page">
                                    <div className="main-jaste">
                                        <div className="mi">
                                            <h1 className="left-title">Lot Details</h1>
                                        </div>

                                        {adminUser?.role?.toUpperCase() !== 'FINANCE' && !lotSold ? (
                                            <div className="main-flxwer">
                                                <Button className='btm-blueed-btn pointer btn-delete' name='update-save' type="button" onClick={showConfirmationModal}>
                                                    <img src="./../assets/img/trash.svg" className="perspective_matt.svg" alt="" />
                                                </Button>
                                                <Button className='btm-blueed-btn pointer' name='update-save' type="submit">Update & Save</Button>
                                                {(lotInformation.status === 'unpublished' || lotInformation.status === 'closed') ? (<Button className="btm-blueed-btn pointer" name='update-publish' type="submit">Update & Publish</Button>) : ('')}
                                            </div>
                                        ) : ('')}

                                    </div>
                                    <PageNavigation page="lot-details" nav="admin" />
                                    <div>

                                        <div>
                                            <div className="wihte-erya mb-4">
                                                <section className="lot-info-wrapper admin-lots">
                                                    <section className='ld-wrapper mb-3'>
                                                        <Row className="mb-3">
                                                            <Col>
                                                                <FloatingLabel
                                                                    controlId="floatingTextarea2"
                                                                    label="Lot Title"
                                                                >
                                                                    <Form.Control size="lg"
                                                                        required
                                                                        as="textarea"
                                                                        name='lot_name'
                                                                        style={{ height: '100px' }}
                                                                        placeholder="Lot Title"
                                                                        aria-label="Lot Title"
                                                                        aria-describedby="basic-addon2"
                                                                        defaultValue={lotInformation.lot_name || ''}
                                                                        disabled={adminUser.role === "FINANCE" || lotSold}
                                                                    />
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col xs={5}>
                                                                <div className='ld-wrapper'>
                                                                    <div className="liquid-label"><span className="liquid-text">{lotInformation?.bidding_method?.toUpperCase()}</span></div>
                                                                </div>

                                                                {/* {imagePreviews && imagePreviews.length ? (
                                                                    <img src={imagePreviews?.[0] || './../assets/img/no_image.jpg'} className="mp-icon lot mb-4" alt="" />
                                                                ) : (
                                                                    <img src={lotInformation?.lot_image_urls?.[0] || './../assets/img/no_image.jpg'} className="mp-icon lot mb-4" alt="" />
                                                                )} */}

                                                                {lotImages?.length > 1 ? (
                                                                    <Carousel data-bs-theme="dark" variant="dark" className='mb-3'>
                                                                        {lotImages?.map((img, index) => (
                                                                            <Carousel.Item key={index}>
                                                                                <img src={img || './../assets/img/no_image.jpg'} className="pdp-carousel-image" alt="" />
                                                                            </Carousel.Item>
                                                                        ))}
                                                                    </Carousel>


                                                                ) : (
                                                                    <img src={lotInformation?.lot_image_urls?.[0] || './../assets/img/no_image.jpg'} className="pdp-carousel-image" alt="" />
                                                                )}

                                                                {lotImages?.length > 1 ? (
                                                                    <div className='image-add-wrapper'>
                                                                        {
                                                                            lotImages?.map((img, index) => (
                                                                                <Row className="mb-3" key={index}>
                                                                                    <Col>
                                                                                        <FloatingLabel
                                                                                            controlId="floatingInput"
                                                                                            label="Add Image Url"
                                                                                        >
                                                                                            <Form.Control size="sm"
                                                                                                required
                                                                                                name={`lot_image_urls_${index}`}
                                                                                                placeholder="Enter Image Url"
                                                                                                aria-label="Image Url"
                                                                                                aria-describedby="basic-addon2"
                                                                                                defaultValue={img || ''}
                                                                                                onChange={(e) => handleInputChange(index, e)}
                                                                                                disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                            />
                                                                                        </FloatingLabel>
                                                                                    </Col>
                                                                                </Row>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Add Image Url"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='lot_image_urls'
                                                                                    placeholder="Enter Image Url"
                                                                                    aria-label="Image Url"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.lot_image_urls?.[0] || ''}
                                                                                    onChange={(e) => handleInputChange(0, e)}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>
                                                                )}

                                                                {adminUser.role !== "FINANCE" && !lotSold ? (
                                                                    <div className='add-more-image-wrapper'>
                                                                        <span onClick={addMoreImageHandler}>+ Add More Images</span>
                                                                    </div>
                                                                ) : ('')
                                                                }

                                                            </Col>
                                                            <Col xs={7} >
                                                                <div className='lot-info-price-wrapper mb-3'>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingTextarea2"
                                                                                label="Lot Description"
                                                                            >
                                                                                <Form.Control size="md"
                                                                                    required
                                                                                    as="textarea"
                                                                                    name='lot_description'
                                                                                    style={{ height: '100px' }}
                                                                                    placeholder="Lot Description"
                                                                                    aria-label="Lot Description"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.lot_description || ''}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Location"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='storage_location'
                                                                                    placeholder="Location"
                                                                                    aria-label="Location"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.storage_location || ''}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Incremental Slab"
                                                                            >
                                                                                <Form.Select
                                                                                    name='increment_slab'
                                                                                    required aria-label="Select Slab"
                                                                                    className='slab-select-wrapper'
                                                                                    value={selectedSlab}
                                                                                    onChange={(e) => setSelectedSlab(e.target.value)}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                >
                                                                                    <option value="noSelected">Open to select slab</option>
                                                                                    <option value="100">100</option>
                                                                                    <option value="500">500</option>
                                                                                    <option value="1000">1000</option>
                                                                                    <option value="5000">5000</option>
                                                                                    <option value="10000">10000</option>
                                                                                </Form.Select>

                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Delivery Timeline"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='delivery_timeline'
                                                                                    className='days-input-wrapper'
                                                                                    placeholder="Delivery Timeline"
                                                                                    aria-label="Delivery Timeline"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.delivery_timeline || ''}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Delivery Mode"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='delivery_by'
                                                                                    placeholder="Delivery Mode"
                                                                                    aria-label="Delivery Mode"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.delivery_by || ''}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Items Quantity"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='items_count'
                                                                                    placeholder="Quantity"
                                                                                    aria-label="Quantity"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.items_count || ''}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Items Grade"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='grade_name'
                                                                                    placeholder="Grade"
                                                                                    aria-label="Grade"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.grade_name || ''}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="MRP"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='mrp'
                                                                                    placeholder="MRP"
                                                                                    aria-label="MRP"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.mrp || ''}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Floor Price"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='floor_price'
                                                                                    placeholder="Floor Price"
                                                                                    aria-label="Floor Price"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.floor_price || ''}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Reserve Price"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    name='benchmark_bid_price'
                                                                                    placeholder="Reserve Price"
                                                                                    aria-label="Reserve Price"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.benchmark_bid_price}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Buy Now Price"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='buy_now_price'
                                                                                    placeholder="Buy Now Price"
                                                                                    aria-label="Buy Now Price"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={lotInformation.buy_now_price || ''}
                                                                                    disabled={adminUser.role === "FINANCE" || lotSold}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <span className='categories-title-wrapper'>Start Date</span>
                                                                            <DatePicker
                                                                                name='bid_start_date'
                                                                                selected={startDate ? moment(startDate).tz("Asia/Kolkata").toDate() : today.toDate()}
                                                                                onChange={(date) => setStartDate(moment(date).tz("Asia/Kolkata"))}
                                                                                showTimeSelect
                                                                                dateFormat="Pp"
                                                                                className="form-control"
                                                                                disabled={adminUser.role === "FINANCE" || lotSold}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <span className='categories-title-wrapper'>End Date</span>
                                                                            <DatePicker
                                                                                name='bid_end_date'
                                                                                selected={endDate ? moment(endDate).tz("Asia/Kolkata").toDate() : tomorrow.toDate()}
                                                                                onChange={(date) => setEndDate(moment(date).tz("Asia/Kolkata"))}
                                                                                showTimeSelect
                                                                                dateFormat="Pp"
                                                                                className="form-control"
                                                                                disabled={adminUser.role === "FINANCE" || lotSold}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <span className='categories-title-wrapper'>Categories</span>
                                                                            <MultiSelect
                                                                                name='sub_categories'
                                                                                options={categoriesList}
                                                                                value={selectedSubCategories}
                                                                                onChange={setSelectedSubCategories}
                                                                                labelledBy="Select Sub Categories"
                                                                                className='categories-select-wrapper'
                                                                                disabled={adminUser.role === "FINANCE" || lotSold}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </section>
                                                </section>
                                            </div>
                                            <div className="wihte-erya">
                                                <section className='ld-product-list-wrapper mb-3'>
                                                    <ProductList lot={lotid} mp={mpid} />
                                                </section>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </section>
            </section>

            <ConfirmationModal submitConfirmationSetter={handleSubmitConfirmationModal} showConfirmationSetter={handleToggleConfirmationModal} show={showConfirmation} message='Are you sure you want to delete this lot?' />
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </div>
    );
}

export default AdminLotDetails;