export function content() {
    return {
        emailErrorMessage: 'Please enter valid email id.',
        passwordErrorMessage: 'Please enter a password.',
        companyNameErrorMessage: 'Please enter a company name.',
        companySizeErrorMessage: 'Please select a company size.',
        companyTypeErrorMessage: 'Please select acompany type.',
        productNameErrorMessage: 'Please enter a product name.',
        productIdErrorMessage: 'Please enter a product id.',
        productPriceErrorMessage: 'Please enter a product amount.',
        productImageErrorMessage: 'Please enter a product image url.'
    }
}

export function exportListData(data, fileName) {

    const exportData = data.productsList.map((item) => {
        return {
            'Product Description': `"${item?.products?.description?.replaceAll('"', '”') || ''}"`,
            'Product Brand': `"${item?.products?.brand || ''}"`,
            'Product Price': parseFloat(item?.products?.mrp?.replaceAll(',', '') || 0),
            'Product Floor Price': parseFloat(item?.products?.floor_price?.replaceAll(',', '') || 0),
            'Product Quantity': parseInt(item?.products?.quantity?.replaceAll(',', '') || 0),
            'Product Category': `"${item?.products?.category || ''}"`,
            'Product Images': `"${item?.products?.image_urls?.join(',') || ''}"`,
            'Lot Id': data?.lotInfo?.lotid || '',
            'Lot Name': `"${data?.lotInfo?.lot_name.replaceAll('"', '”') || ''}"`,
            'Lot Description': `"${data?.lotInfo?.lot_description?.replaceAll('"', '”') || ''}"`,
            'Delivery Type': `"${data?.lotInfo?.delivery_by || ''}"`,
            'Delivery Timeline': data?.lotInfo?.delivery_timeline || '',
            'Grade Name': `"${data?.lotInfo?.grade_name || ''}"`,
            'Items Count': parseInt(item?.products?.items_count?.replaceAll(',', '') || 0),
            'Storage Location': data?.lotInfo?.storage_location || '',
            'Categories': `"${data?.lotInfo?.sub_categories?.join(',') || ''}"`,
            'Lot Images': `"${data?.lotInfo?.lot_image_urls?.join(',') || ''}"`,
            'Seller': `"${data?.lotInfo?.marketplace_info?.name || ''}"`
        }
    });

    data = exportData;
    if (data && !(data instanceof Array)) {
        data = [data];
    }
    if (data.length) {
        if (!fileName) {
            fileName = "trader-manifest";
        }

        let headerData = Object.keys(data[0]);
        let downloadData = data.map(obj => Object.values(obj));
        downloadData.unshift(headerData);

        let csvContent = "data:text/csv;charset=utf-8,"
            + downloadData.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        link.click();
    }
}

export function addDays(days) {
    return new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000);
}

export function addMonths(months) {
    return new Date(new Date().setMonth(new Date().getMonth() + months));
}

export function addYears(years) {
    return new Date(new Date().setFullYear(new Date().getFullYear() + years));
}

// check if any parent has some specific class
export function hasSomeParentTheClass(element, classname) {
    if (element.className.split(' ').indexOf(classname) >= 0) {
        return element;
    } else {
        if (element.className !== 'sidebar') {
            return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
        } else {
            return false;
        }
    }
}

// validate the input fields
export function validateInput(Elm, errorMessage) {

    const _getNextSibling = function (elem, selector) {
        var sibling = elem.nextElementSibling;
        if (!selector) return sibling;
        while (sibling) {
            if (sibling.matches(selector)) return sibling;
            sibling = sibling.nextElementSibling
        }
    };

    const _validateEmail = function (email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return (true)
        }
        return (false)
    }

    let nextElm = _getNextSibling(Elm, '.error-message');

    switch (Elm.type) {
        case 'password':
            if (!Elm.value) {
                Elm.classList.add("error");

                let spanTag = document.createElement("span");
                spanTag.classList = "error-message";
                spanTag.innerHTML = errorMessage;
                if (!nextElm) {
                    Elm.parentNode.insertBefore(spanTag, Elm.nextSibling);
                }
                return false;
            } else {
                if (nextElm) {
                    nextElm.remove();
                }
                Elm.classList.remove("error");
                return true;
            }
        case 'number':
            if (!Elm.value) {
                Elm.classList.add("error");

                if (errorMessage) {
                    let spanTag = document.createElement("span");
                    spanTag.classList = "error-message";
                    spanTag.innerHTML = errorMessage;
                    if (!nextElm) {
                        Elm.parentNode.insertBefore(spanTag, Elm.nextSibling);
                    }
                }
                return false;
            } else if (Elm.value <= 0) {
                Elm.classList.add("error");

                if (errorMessage) {
                    let spanTag = document.createElement("span");
                    spanTag.classList = "error-message";
                    spanTag.innerHTML = errorMessage;
                    if (!nextElm) {
                        Elm.parentNode.insertBefore(spanTag, Elm.nextSibling);
                    }
                }
                return false;
            } else {
                if (nextElm) {
                    nextElm.remove();
                }
                Elm.classList.remove("error");
                return true;
            }
        case 'textarea':
        case 'text':
            if (!Elm.value) {
                Elm.classList.add("error");

                if (errorMessage) {
                    let spanTag = document.createElement("span");
                    spanTag.classList = "error-message";
                    spanTag.innerHTML = errorMessage;
                    if (!nextElm) {
                        Elm.parentNode.insertBefore(spanTag, Elm.nextSibling);
                    }
                }
                return false;
            } else {
                if (nextElm) {
                    nextElm.remove();
                }
                Elm.classList.remove("error");
                return true;
            }
        case 'email':
            if (!Elm.value) {
                Elm.classList.add("error");

                if (errorMessage) {
                    let spanTag = document.createElement("span");
                    spanTag.classList = "error-message";
                    spanTag.innerHTML = errorMessage;
                    if (!nextElm) {
                        Elm.parentNode.insertBefore(spanTag, Elm.nextSibling);
                    }
                }
                return false;
            } else if (!_validateEmail(Elm.value)) {
                Elm.classList.add("error");

                if (errorMessage) {
                    let spanTag = document.createElement("span");
                    spanTag.classList = "error-message";
                    spanTag.innerHTML = errorMessage;
                    if (!nextElm) {
                        Elm.parentNode.insertBefore(spanTag, Elm.nextSibling);
                    }
                }
                return false
            } else {
                if (nextElm) {
                    nextElm.remove();
                }
                Elm.classList.remove("error");
                return true;
            }
        case 'checkbox':
            if (!Elm.checked) {
                Elm.classList.add("error");
                return false;
            } else {
                Elm.classList.remove("error");
                return true;
            }
        case 'select-one':
            if (Elm.value === '0') {
                Elm.classList.add("error");
                return false;
            } else {
                Elm.classList.remove("error");
                return true;
            }
    }
    return true;
}

export function formatCurrency(price) {
    if (price) {
        let format = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0,
        });

        return format.format(price);
    }
};

export const getFileNameFromPath = (path) => {

    const lastIndex = path.lastIndexOf('/'); // Find the last occurrence of "/"
    const lastIndex2 = path.lastIndexOf("\/"); // Find the last occurrence of "\"
    const lastIndex3 = path.lastIndexOf("\\"); // Find the last occurrence of "\\"
    const lastIndex4 = path.lastIndexOf("//"); // Find the last occurrence of "//"

    if (lastIndex !== -1) {
        return path.substring(lastIndex + 1);
    } else if (lastIndex2 !== -1) {
        return path.substring(lastIndex2 + 1);
    } else if (lastIndex3 !== -1) {
        return path.substring(lastIndex3 + 1);
    } else if (lastIndex4 !== -1) {
        return path.substring(lastIndex4 + 1);
    } else {
        return path; // If no "/" is found, return the original path
    }
};

Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});