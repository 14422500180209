import './css/style.css';
import './css/traderStyle.css';
import { useRoutes } from "react-router-dom";

import PageNotFound from '../Components/Basic/PageNotFound';
import Signup from '../Components/Onboarding/Signup';
import ResetPassword from '../Components/Onboarding/ResetPassword';

import { ProtectedRoute, SuperAdminProtectedRoute, ProtectedBuyerRoute } from "../Components/Basic/ProtectedRoute";

import HomePage from '../Pages/HomePage';
import MarketplaceListPage from '../Components/Buyer/MarketplaceListPage';
import LotDetails from '../Components/Buyer/LotDetails';
import ProductListPage from '../Components/Buyer/ProductListPage';
import BidHistory from '../Components/Buyer/BidHistory';

import AdminLogin from '../Components/Admin/AdminLogin';
import Dashboard from '../Components/Admin/Dashboard';
import AdminMarketplaceList from '../Components/Admin/MarketplaceList';
import AdminLotList from '../Components/Admin/LotList';
import AdminLotDetails from '../Components/Admin/LotDetails';
import AdminProductList from '../Components/Admin/ProductList';
import AdminUserList from '../Components/Admin/UserList';
import AdminPaymentsList from '../Components/Admin/PaymentsList';
import AdminDeliveryList from '../Components/Admin/DeliveryList';
import AdminUserInfo from '../Components/Admin/UserInfo';

import Buyer from '../Pages/Buyers';
import Seller from '../Pages/Seller';
import AnalyticsDashboard from '../Components/Admin/AnalyticsDashboard';

function App() {
  window.userData = {};

  const routes = useRoutes([
    {
      path: "*",
      element: <PageNotFound />
    },
    {
      path: "404",
      element: <PageNotFound />
    },
    {
      path: "/register",
      element: <Signup />
    },
    {
      path: "/reset-password",
      element: <ResetPassword />
    },
    {
      path: "/",
      element: <HomePage />
    },
    {
      path: "/buyer",
      element: <Buyer />
    },
    {
      path: "/seller",
      element: <Seller />
    },
    {
      path: "/marketplace",
      element: <MarketplaceListPage />
    },
    {
      path: "/all-lots",
      element: <ProductListPage type="all" />
    },
    {
      path: "/lot/:mpid",
      element: <ProductListPage type="mp" />
    },
    {
      path: "/lot-details/:lotid",
      element: <LotDetails />
    },
    {
      path: "/bid-history",
      element: <ProtectedBuyerRoute><BidHistory /></ProtectedBuyerRoute>
    },

    /*ADMIN ROUTES*/
    {
      path: "/admin",
      element: <AdminLogin />
    },
    {
      path: "/dashboard",
      element: <ProtectedRoute><Dashboard /></ProtectedRoute>
    },
    {
      path: "/marketplace-list",
      element: <ProtectedRoute><AdminMarketplaceList /></ProtectedRoute>
    },
    {
      path: "/lot-list",
      element: <ProtectedRoute><AdminLotList /></ProtectedRoute>
    },
    {
      path: "/selected-lot/:lotid",
      element: <ProtectedRoute><AdminLotDetails /></ProtectedRoute>
    },
    {
      path: "/product-list",
      element: <ProtectedRoute><AdminProductList /></ProtectedRoute>
    },
    {
      path: "/marketplace-list",
      element: <ProtectedRoute><AdminMarketplaceList /></ProtectedRoute>
    },
    // {
    //   path: "/analytics-dashboard",
    //   element: <AnalyticsDashboard />
    // },
    {
      path: "/admin-list",
      element: <SuperAdminProtectedRoute><AdminUserList role="admin" /></SuperAdminProtectedRoute>
    },
    {
      path: "/buyer-list",
      element: <ProtectedRoute><AdminUserList role="buyer" /></ProtectedRoute>
    },
    {
      path: "/buyer-info/:uid",
      element: <ProtectedRoute><AdminUserInfo /></ProtectedRoute>
    },
    {
      path: "/seller-list",
      element: <ProtectedRoute><AdminUserList role="seller" /></ProtectedRoute>
    },
    {
      path: "/payments",
      element: <ProtectedRoute><AdminPaymentsList /></ProtectedRoute>
    },
    {
      path: "/deliveries",
      element: <ProtectedRoute><AdminDeliveryList /></ProtectedRoute>
    }
  ]);
  return routes;
}

export default App;