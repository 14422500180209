import React, { useEffect, useState } from 'react';
import { useStore } from "../context/StoreProvider";
import { formatCurrency } from '../Config/config';
// import { useNavigate } from "react-router-dom";
// import { hasSomeParentTheClassnclassName, getClientToken } from '../Config/config'
import Accordion from 'react-bootstrap/Accordion';

function ProductFilter(props) {
    const { filtersList, selectedFilters, setSelectedFilters } = useStore();
    const [priceRange, setPriceRange] = useState(50000000); // Default to max value
    // {
    //     name: "Sub Categories",
    //     items: filtersList.sub_categories || []
    // },
    var data = [
        {
            name: "Condition",
            items: filtersList.condition || []
        },
        {
            name: "Location",
            items: filtersList.location || []
        },
        {
            name: "Status",
            items: ["Active", "Expired"]
        }
    ]

    // Handle filter checkbox change
    const handleFilterChange = (filterCategory, filterValue) => {
        let updatedFilters = { ...selectedFilters };

        if (filterCategory === 'Price') {
            // Directly set the price filter with min and max values
            updatedFilters[filterCategory] = filterValue; // Assuming filterValue is an object { min: value, max: value }
        } else {
            const isSelected = selectedFilters[filterCategory]?.includes(filterValue);
            if (isSelected) {
                // Remove filter
                updatedFilters[filterCategory] = updatedFilters[filterCategory].filter(item => item !== filterValue);
                if (updatedFilters[filterCategory].length === 0) delete updatedFilters[filterCategory];
            } else {
                // Add filter
                updatedFilters[filterCategory] = updatedFilters[filterCategory] ? [...updatedFilters[filterCategory], filterValue] : [filterValue];
            }
        }

        setSelectedFilters(updatedFilters);
    };

    const handlePriceChange = (event) => {
        const newPrice = event.target.value;
        setPriceRange(newPrice);
        handleFilterChange('Price', newPrice); // Assuming handleFilterChange is defined elsewhere
    };

    // Clear all selected filters
    const clearFilters = () => {
        setSelectedFilters({});
    };

    return (
        <>
            <div className='p-3 border-bottom'>
                Filters
                <a role='button' className='float-end' onClick={clearFilters}>
                    Clear
                </a>
            </div>
            <Accordion flush>
                <Accordion.Item eventKey="price">
                    <Accordion.Header><span className='text-uppercase'>MRP range</span></Accordion.Header>
                    <Accordion.Body>
                        <div className="d-flex justify-content-between price-range-fltr">

                            <label>0</label>
                            <input
                                type='range'
                                min={0}
                                max={50000000}
                                value={priceRange}
                                onChange={handlePriceChange}
                            />
                            <label className="max-currency-filter-label">{formatCurrency(priceRange)}</label>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                {data.map((item, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header><span className='text-uppercase'>{item.name}</span></Accordion.Header>
                        <Accordion.Body className="filter-item-container">
                            {item.items.map((subItem, subIndex) => (
                                <div className="form-check py-2" key={subIndex}>
                                    <input
                                        className="form-check-input filter-input"
                                        type="checkbox"
                                        value={subItem}
                                        id={"filter" + index + '-' + subIndex}
                                        onChange={() => handleFilterChange(item.name, subItem)}
                                        checked={selectedFilters[item.name]?.includes(subItem) || false}
                                    />
                                    <label className="form-check-label" htmlFor={"filter" + index + '-' + subIndex}>
                                        {subItem}
                                    </label>
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
}

export default ProductFilter;
