import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Toaster from "../Basic/Toaster";
import ReactTooltip from 'react-tooltip';
import { exportListData } from '../Config/config';
import { useStore } from "../context/StoreProvider";
import { useAuth } from "../hooks/useAuth";
import { useService } from "../hooks/useService";
import Login from '../Onboarding/Login';
import ListPagination from '../Basic/Pagination';

function ProductList(props) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { productList } = useService();
    const { selectedLotData, productsForSelectecLot, setProductList } = useStore();

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');

    const [showProducts, setShowProducts] = useState(false);
    const [fullProductsList, setFullProductsList] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handleToggleLoginModal = (data) => {
        setShowLogin(false);
    };

    const lotid = props?.lot;
    const mpid = props?.mp;

    let exportData = null;
    if (selectedLotData) {
        exportData = {
            lotInfo: selectedLotData,
            productsList: fullProductsList
        };
    }

    const downloadManifest = () => {
        setShowToaster(true);
        setToasterMessage('Downloading manifest...  Please wait!');

        if (fullProductsList.length === 0) {
            getFullProductList(lotid);
        } else {
            exportListData(exportData, "manifest");
        }
    }

    const getFullProductList = (lotid) => {
        productList(lotid, "BUYER", 1, 50000)
            .then(response => {
                if (response && response.status === 200) {

                    const productsList = response?.results?.data || [];
                    setFullProductsList(productsList);
                    exportListData({
                        lotInfo: selectedLotData,
                        productsList: productsList
                    }, "manifest");
                }
            });
    }

    const getProductList = (lotid, currentPage) => {
        productList(lotid, "BUYER", currentPage, 10)
            .then(response => {
                if (response && response.status === 200) {
                    setShowProducts(true);
                    const productsList = response?.results?.data || [];
                    setTotalPages(response?.results?.totalPages || 1);
                    setProductList(productsList);
                } else if (response && response.status === 401 || response && response.status === 440) {
                    setShowProducts(false);
                }
            });
    }

    const _handleProductRows = (list) => {
        return (
            <tbody>
                {list.map((item, index) => (
                    <tr className="gry-tred" key={index}>
                        <td className="body-title text-center"><img width="80px" src={item?.products?.image_urls?.[0] || './../assets/img/products.jpeg'} alt="" /></td>
                        <td className="body-title">{item?.products?.description}</td>
                        <td className="body-title">{item?.products?.brand}</td>
                        <td className="body-title text-center">{item?.products?.category}</td>
                        <td className="body-title text-center">{item?.products?.mrp}</td>
                        <td className="body-title text-center">{item?.products?.quantity}</td>
                    </tr>
                ))}
            </tbody>
        )
    }

    useEffect(() => {
        if (lotid && user && user.isLoggedIn) {
            getProductList(lotid, currentPage);
        }
    }, [currentPage]);

    return (
        <>
            <section className="sec-1">
                <div className="Rule-page">
                    <div className="main-jaste">
                        {/* <div className="mi">
                            <h1 className="left-title">All Products</h1>
                        </div> */}
                        {showProducts ? (
                            <div className="main-flxwer">
                                <a className="theme-btn-one login-btn manifest-btn" onClick={downloadManifest}>DOWNLOAD MANIFEST</a>
                            </div>
                        ) : ('')}
                    </div>
                    <div className="wihte-erya overflow-auto p-0">
                        <table className="w-100 m-0">
                            <thead>
                                <tr className="gry-tr h-74">
                                    <td className="head-title padin-24">Stock Image</td>
                                    <td className="head-title padin-24">Description</td>
                                    <td className="head-title padin-24">Brand</td>
                                    <td className="head-title padin-24 text-center">Category</td>
                                    <td className="head-title padin-24 text-center">MRP</td>
                                    <td className="head-title padin-24 text-center">Quantity</td>
                                </tr>
                            </thead>
                            {productsForSelectecLot.length !== 0 &&
                                _handleProductRows(productsForSelectecLot)
                            }
                        </table>
                        {showProducts && productsForSelectecLot.length === 0 &&
                            <div className="space-26px">
                                {/* <p className="create-oneedw">You haven't got any products in the lot yet, <a className="add-custm-btn" data-bs-toggle="modal" data-bs-target="#Addproduct"> create one</a></p> */}
                                <p className="create-oneedw">You haven't got any products in the lot yet!</p>
                            </div>
                        }

                        {showProducts && productsForSelectecLot.length !== 0 &&
                            <ListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        }
                        {!showProducts &&
                            <div className="space-26px">
                                <p className="create-oneedw">You are not authorized to view the products, <a className="add-custm-btn" onClick={() => setShowLogin(true)}> Sign In</a></p>
                            </div>
                        }
                    </div>
                    <div className="main-jaste"></div>
                </div>

                
            </section>

            {/* <!-- Modal LOGOUT data-bs-toggle="modal" data-bs-target="#LOGOUT" --> */}
            <Login showLoginSetter={handleToggleLoginModal} show={showLogin} />
            <ReactTooltip html={true} className="tooltip" />
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </>
    );
}

export default ProductList;
