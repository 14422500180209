import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { validateInput, content } from '../Config/config';
import Toaster from "../Basic/Toaster";
import { useAuth } from "../hooks/useAuth";
import { useService } from "../hooks/useService";
import Modal from 'react-bootstrap/Modal';

function Login({ showLoginSetter, show }) {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { userLogin, validateToken } = useService();
    const showModalProp = show || false;

    const [showModal, setShowModal] = useState(false);
    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [showPassword, setShowPassword] = useState('password');

    const handleClose = () => {
        setShowModal(false);
        showLoginSetter(false);
    }
    const handleShow = () => setShowModal(true);

    const handleLogin = async () => {
        let email = document.getElementById('userEmail');
        let password = document.getElementById('userPass');

        let checkEmail = validateInput(email, content().emailErrorMessage);
        let checkPassword = validateInput(password, content().passwordErrorMessage);

        if (checkEmail && checkPassword) {
            userLogin({ "email": email.value, "password": password.value })
                .then(async response => {
                    if (response.loggedIn) {
                        // Set user data
                        const accessToken = response.token;
                        const role = response.role;
                        const uid = response.uid;
                        await login({ isLoggedIn: true, token: accessToken, uid: uid || null, email: email.value, role: role });


                        // Redirect to dashboard
                        window.location.reload();
                        // navigate("/");
                    } else {
                        console.log('error_', response);
                        setShowToaster(true);
                        setToasterMessage(response.message);
                    }
                })
                .catch(error => {
                    console.log('ERROR', error.response.status);
                    if (error && error.response && error.response.data) {
                        if (error.response.status === 400) {
                            setShowToaster(true);
                            setToasterMessage(error.response.data.message);

                        } else {
                            setShowToaster(true);
                            setToasterMessage(error.response.data.message);
                        }
                    }
                });
        }
    }

    const handleVisiblePass = () => {
        const password = document.getElementById('userPass');

        if (password.type === "password") {
            password.type = "text";
            setShowPassword("text");
        } else {
            password.type = "password";
            setShowPassword("password");
        }
    }

    useEffect(() => {
        if (showModalProp) {
            handleShow();
        }

    }, [showModalProp]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose}>
                <div className="modal-dialog addtag-three side-right">
                    <div className="modal-content styled">
                        <div className="email-box">
                            <div className="input-dpadcf">
                                <div className="email-gird">
                                    <p className="main-pra-text">Email</p>
                                    <input type="email" id="userEmail" className="main-input" placeholder="Enter your work email address" required />
                                </div>
                                <div className="email-gird">
                                    <p className="main-pra-text">Password</p>
                                    <div className="porelet">
                                        <input type="password" id="userPass" className="main-input" placeholder="Enter password" required />
                                        <img src="assets/img/eye.fill.svg" className="eys-ic" alt="" />

                                        {/* <!-- show password ic --> */}
                                        {showPassword === "password" && (<img src="assets/img/eye.slash.fill.svg" className="eys-ic" alt="" onClick={handleVisiblePass} />)}
                                        {showPassword === "text" && (<img src="assets/img/eye.fill.svg" className="eys-ic" alt="" onClick={handleVisiblePass} />)}

                                    </div>
                                </div>
                            </div>
                            <div className="two-btn">
                                <div>
                                    <a className="theme-btn-one login-btn" onClick={handleLogin} >LOG IN<i className="flaticon-right-1" /></a>

                                    {/* <!-- grey btn second page -->
                                <!-- <a href="" className="GET-STARTED blak">LOG IN</a> --> */}
                                </div>
                                <div>
                                    <p className="Already-pra">Don’t have an account?<a className="LOG-iN-btn" onClick={() => navigate("/register")}> sign up</a></p>
                                    <p className="Already-pra">Forgot password?<a className="LOG-iN-btn" onClick={() => navigate("/reset-password")}> Click here </a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </>
    );
}

export default Login;
