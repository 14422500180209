import React, { useEffect, useState } from 'react';
import Sidebar from "../Basic/Sidebar";
import { validateInput, content } from '../Config/config';
import Toaster from "../Basic/Toaster";
import ReactTooltip from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { useService } from "../hooks/useService";
import { useAuth } from "../hooks/useAuth";

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListPagination from '../Basic/Pagination';
import Placeholder from 'react-bootstrap/Placeholder';

import ConfirmationModal from '../Basic/ConfirmationModal';

import Loader from '../Basic/Loader';

function AdminMarketplaceList(props) {

    const navigate = useNavigate();
    const { adminMarketplaceList, createNewMarketplace, updateMarketplace, deleteMarketplace } = useService();
    const { adminUser } = useAuth();
    const [loading, setLoading] = useState(false);

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [marketplacesData, setMarketplaceData] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [selectedMarketplace, setSelectedMarketplace] = useState({});

    const [validated, setValidated] = useState(false);
    const [editValidated, setEditValidated] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchText, setSearchText] = useState('');

    const getMarketplaceList = (currentPage, search = '') => {
        setLoading(true);
        adminMarketplaceList("ADMIN", currentPage, 10, search)
            .then(response => {
                if (response && response.data && response.data.length) {
                    setTotalPages(response.totalPages);
                    setMarketplaceData(response.data);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const deleteMarketplaceHandler = async () => {
        // setLoading(true);
        const mpid = selectedMarketplace.mpid;

        deleteMarketplace(mpid)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setShowToaster(true);
                    setToasterMessage('Marketplace deleted successfully');
                    setTimeout(() => {
                        window.location.reload();
                    }, 600);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const submitMarketplaceHandler = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            setShowToaster(true);
            setToasterMessage('Validation failed. Please check your inputs.');
            return false;
        }

        // Gather form data
        const formData = new FormData(form);
        const payload = {};
        for (let [key, value] of formData.entries()) {
            payload[key] = value;
        }
        payload.uid = adminUser?.email || 'admin';

        // Submit data
        console.log(adminUser);
        createNewMarketplace(payload, adminUser?.role, adminUser?.uid)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setShowToaster(true);
                    setToasterMessage('Marketplace Added successfully');
                    setTimeout(window.location.reload(), 2000);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const submitUpdateMarketplaceHandler = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.currentTarget;
        setEditValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            setShowToaster(true);
            setToasterMessage('Validation failed. Please check your inputs.');
            return false;
        }

        // Gather form data
        const formData = new FormData(form);
        const payload = {};
        for (let [key, value] of formData.entries()) {
            payload[key] = value;
        }

        // Submit data
        updateMarketplace(payload, adminUser?.role, selectedMarketplace.mpid)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setShowToaster(true);
                    setToasterMessage('Marketplace Updated successfully');
                    setTimeout(window.location.reload(), 2000);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleSearch = () => {
        if (searchText.length >= 3) {

            getMarketplaceList(currentPage, searchText);
        } else if (searchText.length === 0) {
            getMarketplaceList(currentPage);
        }
    }

    const handleActions = (marketplace) => {
        setSelectedMarketplace(marketplace.item);
        document.getElementById("updatedBillingType").value = marketplace.item.billing_type;
    }

    const _handleMarketplaceRows = (list) => {
        const placeholderImage = './../assets/img/products.jpeg'; // Placeholder image path

        return (
            <tbody>
                {list.map((item, index) => (
                    <tr className="gry-tred" key={index}>
                        <td className="body-title">
                            {/* <a href="" className="numberw"> */}
                            {item.mpid}
                            {/* </a> */}
                        </td>
                        <td className="body-title">{item.name}</td>
                        <td className="body-title">
                            <img
                                alt={item.name}
                                width="80px"
                                src={item?.image || placeholderImage}
                                style={{ display: 'none' }} // Initially hide the image
                                onLoad={(e) => e.target.style.display = 'block'} // Show image on load
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite callback loop
                                    e.target.src = placeholderImage; // Fallback image
                                    e.target.style.display = 'block'; // Show fallback image
                                }}
                            />
                        </td>
                        {adminUser?.role?.toUpperCase() !== 'FINANCE' ? (
                            <td className="body-title text-center">
                                <img src="assets/img/Frame1.svg" data-bs-toggle="modal" data-bs-target="#Edittmarketplace" className="pancen-1st" alt="" onClick={() => handleActions({ item })} />
                                <img src="./../assets/img/trash.svg" className="marketplace-delete-btn" alt="" onClick={() => showConfirmationModal({ item })} />
                            </td>
                        ) : null}
                    </tr>
                ))}
            </tbody>
        )
    }

    const handleToggleConfirmationModal = (data) => {
        setShowConfirmation(false);
    };

    const handleSubmitConfirmationModal = (data) => {
        deleteMarketplaceHandler(data);
    };

    const showConfirmationModal = (marketplace) => {
        setSelectedMarketplace(marketplace.item);
        setShowConfirmation(true);
    };

    useEffect(() => {
        let marketplaceSection = document.getElementById("marketplaceSection");
        let lotSummarySection = document.getElementById("lotSummarySection");
        lotSummarySection.classList.add("showMenu");
        marketplaceSection.classList.add("active");
    }, []);

    useEffect(() => {
        if (searchText.length >= 3 || searchText.length === 0) {
            handleSearch();
        }
    }, [searchText]);

    useEffect(() => {
        getMarketplaceList(currentPage);
    }, [currentPage]);


    return (
        <div className="main-parant-1">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <section className="sec-1">
                        <Sidebar />
                        <section className="home-section">
                            <div className="main-rightbar-box">
                                <div>
                                    <div className="Rule-page">
                                        <div className="main-jaste">
                                            <div className="mi">
                                                <h1 className="left-title">Marketplaces</h1>
                                            </div>
                                            {adminUser?.role?.toUpperCase() !== 'FINANCE' ? (
                                                <div className="main-flxwer">
                                                    <a data-bs-toggle="modal" data-bs-target="#Addmarketplace" className="btm-blueed pointer">Add Marketplace</a>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <div className="main-search-side mb-3">
                                                <div className="btn-input wimor adcqdq search-wrapper">
                                                    <input type="text" id="searchItem" placeholder="Search by Marketplace Name" className="name-poi aede search" value={searchText} onChange={handleSearchChange} />
                                                    <div>
                                                        <img src="assets/img/SAERCH1.svg" className="SAERCH1" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wihte-erya">
                                                <div>
                                                    <table className="w-100 m-0">
                                                        <thead>
                                                            <tr className="gry-tr h-74">
                                                                <td className="head-title padin-24">Marketplaces ID (123)</td>
                                                                <td className="head-title padin-24">Marketplace Name</td>
                                                                <td className="head-title padin-24">Image</td>
                                                                {adminUser?.role?.toUpperCase() !== 'FINANCE' ? (
                                                                    <td className="head-title padin-24 text-center">Actions</td>
                                                                ) : null}
                                                            </tr>
                                                        </thead>
                                                        {marketplacesData.length !== 0 &&
                                                            _handleMarketplaceRows(marketplacesData)
                                                        }
                                                    </table>
                                                </div>
                                                {marketplacesData.length === 0 &&
                                                    <div className="space-26px">
                                                        {adminUser?.role?.toUpperCase() !== 'FINANCE' ? (
                                                            <p className="create-oneedw">You haven't got any marketplace yet, <a className="add-custm-btn" data-bs-toggle="modal" data-bs-target="#Addmarketplace"> create one</a></p>
                                                        ) : (
                                                            <p className="create-oneedw">You haven't got any marketplace yet!</p>
                                                        )}
                                                    </div>
                                                }
                                                <ListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </>
            )}

            {/* <!-- Modal Add marketplace --> */}
            <div className="modal fade" id="Addmarketplace" tabIndex={"-1"} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog proder">
                    <div className="modal-content styled">
                        <div className="modal-FLAX">
                            <h5 className="modal-title mb-3">Add marketplace</h5>
                            <button type="button" className="btner" data-bs-dismiss="modal" aria-label="Close"><img src="assets/img/multiply.svg" className="multiply-ic" alt="" /></button>
                        </div>
                        <Form noValidate validated={validated} onSubmit={submitMarketplaceHandler}>
                            <Row className="mb-3">
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Marketplace name"
                                    >
                                        <Form.Control size="sm"
                                            required
                                            name='name'
                                            placeholder="Enter Marketplace name"
                                            aria-label="Marketplace name"
                                            aria-describedby="basic-addon2"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Image url"
                                    >
                                        <Form.Control size="sm"
                                            required
                                            name='imageUrl'
                                            placeholder="Enter Image url"
                                            aria-label="Image url"
                                            aria-describedby="basic-addon2"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <div className="main-cntwr">
                                <Button className='btm-blueed-btn pointer' name='update-save' type="submit">SUBMIT</Button>
                                {/* <a className="SAVE-btn blue" >SAVE</a> */}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

            {/* <!-- Modal Update marketplace --> */}
            <div className="modal fade" id="Edittmarketplace" tabIndex={"-1"} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog proder">
                    <div className="modal-content styled">
                        <div className="modal-FLAX">
                            <h5 className="modal-title mb-3">Edit marketplace</h5>
                            <button type="button" className="btner" data-bs-dismiss="modal" aria-label="Close"><img src="assets/img/multiply.svg" className="multiply-ic" alt="" /></button>
                        </div>
                        <Form noValidate validated={editValidated} onSubmit={submitUpdateMarketplaceHandler}>
                            <Row className="mb-3">
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Marketplace name"
                                    >
                                        <Form.Control size="sm"
                                            required
                                            name='name'
                                            placeholder="Enter Marketplace name"
                                            aria-label="Marketplace name"
                                            aria-describedby="basic-addon2"
                                            defaultValue={selectedMarketplace?.name}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Image url"
                                    >
                                        <Form.Control size="sm"
                                            required
                                            name='imageUrl'
                                            placeholder="Enter Image url"
                                            aria-label="Image url"
                                            aria-describedby="basic-addon2"
                                            defaultValue={selectedMarketplace?.image}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <div className="main-cntwr">
                                <Button className='btm-blueed-btn pointer' name='update-save' type="submit">SUBMIT</Button>
                                {/* <a className="SAVE-btn blue" >SAVE</a> */}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

            {/* <!-- Modal DELETE marketplace --> */}
            <div className="modal fade" id="DELETEmarketplace" tabIndex={"-1"} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog KEEP delete-modal">
                    <div className="modal-content styled">
                        <div className="modal-FLAX">
                            <h5 className="modal-title">Are you sure you want to <span className="dis-bolk"> delete this Collection?</span></h5>
                            <button type="button" className="btner" data-bs-dismiss="modal" aria-label="Close"><img src="assets/img/multiply.svg" className="multiply-ic" alt="" /></button>
                        </div>
                        <div className="space-rede">
                            <img src="assets/img/error.svg" className="errer-ic" alt="" />
                            <p className="red-dlt">This will delete the marketplace in the rules</p>
                        </div>
                        <div className="modal-btn tp-24px">
                            <a className="btned-secondary" data-bs-dismiss="modal">NO</a>
                            <a className="btned-primary">yes, DELETE</a>
                        </div>
                    </div>
                </div>
            </div>

            <ReactTooltip html={true} className="tooltip" />
            <ConfirmationModal submitConfirmationSetter={handleSubmitConfirmationModal} showConfirmationSetter={handleToggleConfirmationModal} show={showConfirmation} message='Are you sure you want to delete this lot?' />
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </div>
    );
}

export default AdminMarketplaceList;