import React, { useEffect, useState } from 'react';
import HeaderNavbar from '../Basic/HeaderNavbar';
import Footer from '../Basic/Footer';
import ProductFilter from '../Basic/ProductFilter';
import Lot from '../Buyer/Lot';
import PageNavigation from '../Basic/PageNavigation';

function ProductListPage({ type }) {
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (type === 'all') {
      window.scrollTo(0, 0);
      // nav-all-lots-btn
      document.querySelector('.nav-all-lots-btn').classList.add('current');
    }
  }, [type]);

  return (
    <>
      <HeaderNavbar />
      <div className="row m-0">

        {showFilters && <div className="col-12 col-md-4 col-lg-3 col-xxl-2 card card-body p-0">
          <ProductFilter />
        </div>}
        <div className={showFilters ? "col-12 col-md-8 col-lg-9 col-xxl-10 product-list-wrapper" : "col-12 product-list-wrapper"}>
          <div className="d-flex">
            <i className="fa-solid fa-sliders fa-fw fa-xl pt-4 filter-btn" role='button' onClick={() => setShowFilters(!showFilters)}></i>
            <PageNavigation page="lot" nav="buyer" />
          </div>

          <Lot type={type} showFilters={showFilters} disablePagination={false} maxCount={20}/>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProductListPage;
