import React, { useEffect, useState } from 'react';
import Sidebar from "../Basic/Sidebar";
import { format } from 'date-fns';
import Toaster from "../Basic/Toaster";
import ReactTooltip from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { useService } from "../hooks/useService";
import { useAuth } from "../hooks/useAuth";
import { formatCurrency } from '../Config/config';

import HeaderNavbar from '../Basic/HeaderNavbar';
import Loader from '../Basic/Loader';

function BidHistory(props) {

    const navigate = useNavigate();
    const { user } = useAuth();
    const { getBidHistory, getWinningLots, getActiveUserLots } = useService();

    const [loading, setLoading] = useState(false);
    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [bidHistory, setBidHistory] = useState([]);
    const [winningLotsHistory, setWinningLotsHistory] = useState([]);
    const [activeUserLotsHistory, setActiveUserLotsHistory] = useState([]);

    const [currentSegment, setCurrentSegment] = useState("bidHistorySegment");

    const changeSegment = async (segment) => {
        setCurrentSegment(segment);
        if (segment === "wonLotsSegment") {
            document.getElementById("activeLotsSegment").classList.remove("active");
            document.getElementById("bidHistorySegment").classList.remove("active");
            document.getElementById("wonLotsSegment").classList.add("active");
        } else if (segment === "activeLotsSegment") {
            document.getElementById("wonLotsSegment").classList.remove("active");
            document.getElementById("bidHistorySegment").classList.remove("active");
            document.getElementById("activeLotsSegment").classList.add("active");
        } else {
            document.getElementById("activeLotsSegment").classList.remove("active");
            document.getElementById("wonLotsSegment").classList.remove("active");
            document.getElementById("bidHistorySegment").classList.add("active");
        }

    }

    const getBidHistoryHandler = () => {
        setTimeout(setLoading(true), 600);
        getBidHistory(user?.uid)
            .then(response => {
                if (response && response.length) {
                    setBidHistory(response);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const getWinningLotsHandler = () => {
        setTimeout(setLoading(true), 600);
        getWinningLots(user?.uid)
            .then(response => {
                if (response && response.length) {
                    console.log(response);
                    setWinningLotsHistory(response);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const getActiveLotsHandler = () => {
        setTimeout(setLoading(true), 600);
        getActiveUserLots(user?.uid)
            .then(response => {
                if (response && response.length) {
                    setActiveUserLotsHistory(response);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const handleSearch = () => {
        let searchText = document.getElementById('searchItem');
        if (searchText.value.length >= 3) {
            getBidHistoryHandler();
        } else if (searchText.value.length === 0) {
            getBidHistoryHandler();
        }
    }

    const _handleBidHistoryRows = (list) => {
        return (
            <tbody>
                {list.map((item, index) => (
                    <tr className="gry-tred" key={index}>
                        <td className="body-title">{item.bidid}</td>
                        <td className="body-title">{item.bid_amount}</td>
                        <td className="body-title">{format(new Date(item.bid_time), "dd/MM/yyyy, hh:MM aaaaa'm'")}</td>
                        <td className="body-title">
                            <a onClick={() => navigate(`/lot-details/${item.lotDetails.lotid}`)} className="numberw"> {item.lotDetails.lotid}</a>
                        </td>
                        <td className="body-title">{item.lotDetails.lot_name}</td>
                        <td className="body-title">{item.lotDetails.lot_description}</td>
                        <td className="body-title">{formatCurrency(item.lotDetails.mrp)}</td>
                        <td className="body-title"><img width="80px" src={item?.lotDetails?.lot_image_urls?.[0] || './../assets/img/no_image.jpg'} alt="" /></td>
                        <td className="body-title">{item.status === 'active' ? (<span className="is-valid-message"><strong>Active</strong></span>) : (<span className="is-invalid-message"><strong>Closed</strong></span>)}</td>
                        <td className="body-title">{item.lotDetails.status !== 'active' ? (<span className="is-invalid-message"><strong>Sold</strong></span>) : (<span className="is-valid-message"><strong>Active</strong></span>)}</td>
                    </tr>
                ))}
            </tbody>
        )
    }

    const _handleWonLotsHistoryRows = (list) => {
        return (
            <tbody>
                {list.map((item, index) => (
                    <tr className="gry-tred" key={index}>
                        <td className="body-title">
                            <a onClick={() => navigate(`/lot-details/${item.lotid}`)} className="numberw"> {item.lotid}</a>
                        </td>
                        <td className="body-title">{item.lot_name}</td>
                        <td className="body-title">{item.lot_description}</td>
                        <td className="body-title"><img width="80px" src={item?.lot_image_urls?.[0] || './../assets/img/no_image.jpg'} alt="" /></td>
                        <td className="body-title">{formatCurrency(item.mrp)}</td>
                        <td className="body-title">{item.status === 'sold' ? formatCurrency(item.buy_now_price) : formatCurrency(item.highest_bid_price)}</td>
                        <td className="body-title"><span className="is-valid-message"><strong>Won!</strong></span></td>
                    </tr>
                ))}
            </tbody>
        )
    }

    const _handleActiveUserLotsHistoryRows = (list) => {
        console.log('List : ', list);
        return (
            <tbody>
                {list.map((item, index) => (
                    <tr className="gry-tred" key={index}>
                        <td className="body-title">
                            <a onClick={() => navigate(`/lot-details/${item.lotid}`)} className="numberw"> {item.lotid}</a>
                        </td>
                        <td className="body-title">{item.lot_name}</td>
                        <td className="body-title">{item.lot_description}</td>
                        <td className="body-title"><img width="80px" src={item?.lot_image_urls?.[0] || './../assets/img/no_image.jpg'} alt="" /></td>
                        <td className="body-title">{formatCurrency(item.mrp)}</td>
                        <td className="body-title">{formatCurrency(item.bidDetails.bid_amount)}</td>
                        <td className="body-title">{item.bidDetails.bid_amount >= item.highest_bid_price ? (<span className="is-valid-message"><strong>Winning!</strong></span>) : (<span className="is-invalid-message"><strong>Losing!</strong></span>) }</td>
                    </tr>
                ))}
            </tbody>
        )
    }

    useEffect(() => {
        const fetchBids = async () => {
            setLoading(true);
            try {
                if (currentSegment === "wonLotsSegment") {
                    getWinningLotsHandler();
                } else if (currentSegment === "activeLotsSegment") {
                    getActiveLotsHandler();
                } else {
                    getBidHistoryHandler();
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setShowToaster(true);
                setToasterMessage('Failed to load data');
            } finally {
                setLoading(false);
            }
        };

        fetchBids();
    }, [currentSegment]); // Re-fetch when currentSegment changes

    useEffect(() => {
        // nav-bid-history-btn
        document.querySelector('.nav-bid-history-btn').classList.add('current');
    }, []);

    return (
        <>
            <HeaderNavbar />
            <div className="main-parant-1">
                <section className="sec-1">
                    <section className="bid-history-section">
                        <div>
                            <div className="Rule-page">
                                <div className="main-jaste">
                                    <div className="mi">
                                        <h1 className="left-title">My History</h1>
                                    </div>
                                </div>
                                <div>
                                    <div className="wihte-erya">
                                        <div className="flaxertw">
                                            <div className="activ-sab">
                                                <a id="bidHistorySegment" className="pointer active-sub active" onClick={() => changeSegment("bidHistorySegment")}>My Bid History</a>
                                                <a id="wonLotsSegment" className="pointer active-sub" onClick={() => changeSegment("wonLotsSegment")}>My Winning History</a>
                                                <a id="activeLotsSegment" className="pointer active-sub" onClick={() => changeSegment("activeLotsSegment")}>My Current Active Lots</a>
                                            </div>
                                        </div>
                                        <div>
                                            {currentSegment === "bidHistorySegment" &&
                                                (
                                                    <table className="w-100 m-0">
                                                        <thead>
                                                            <tr className="gry-tr h-74">
                                                                <td className="head-title padin-24">Bid ID (123)</td>
                                                                <td className="head-title padin-24">Bid Amount</td>
                                                                <td className="head-title padin-24">Bid Placed At</td>
                                                                <td className="head-title padin-24">Lot ID (123)</td>
                                                                <td className="head-title padin-24">Lot Name</td>
                                                                <td className="head-title padin-24">Lot Description</td>
                                                                <td className="head-title padin-24">Lot MRP</td>
                                                                <td className="head-title padin-24">Image</td>
                                                                <td className="head-title padin-24">Bid Status</td>
                                                                <td className="head-title padin-24">Lot Status</td>
                                                            </tr>
                                                        </thead>
                                                        {bidHistory.length !== 0 &&
                                                            _handleBidHistoryRows(bidHistory)
                                                        }
                                                    </table>
                                                )
                                            }
                                            {currentSegment === "wonLotsSegment" &&
                                                (
                                                    <table className="w-100 m-0">
                                                        <thead>
                                                            <tr className="gry-tr h-74">
                                                                <td className="head-title padin-24">Lot ID (123)</td>
                                                                <td className="head-title padin-24">Lot Name</td>
                                                                <td className="head-title padin-24">Lot Description</td>
                                                                <td className="head-title padin-24">Image</td>
                                                                <td className="head-title padin-24">Lot MRP</td>
                                                                <td className="head-title padin-24">Winning Price</td>
                                                                <td className="head-title padin-24">Lot Status</td>
                                                            </tr>
                                                        </thead>
                                                        {winningLotsHistory.length !== 0 &&
                                                            _handleWonLotsHistoryRows(winningLotsHistory)
                                                        }
                                                    </table>
                                                )
                                            }
                                            {currentSegment === "activeLotsSegment" &&
                                                (
                                                    <table className="w-100 m-0">
                                                        <thead>
                                                            <tr className="gry-tr h-74">
                                                                <td className="head-title padin-24">Lot ID (123)</td>
                                                                <td className="head-title padin-24">Lot Name</td>
                                                                <td className="head-title padin-24">Lot Description</td>
                                                                <td className="head-title padin-24">Image</td>
                                                                <td className="head-title padin-24">Lot MRP</td>
                                                                <td className="head-title padin-24">Winning Price</td>
                                                                <td className="head-title padin-24">Lot Status</td>
                                                            </tr>
                                                        </thead>
                                                        {activeUserLotsHistory.length !== 0 &&
                                                            _handleActiveUserLotsHistoryRows(activeUserLotsHistory)
                                                        }
                                                    </table>
                                                )
                                            }
                                        </div>
                                        {(currentSegment === "wonLotsSegment" ? winningLotsHistory.length === 0 : currentSegment === "activeLotsSegment" ? activeUserLotsHistory.length === 0 : bidHistory.length === 0) &&
                                            (
                                                <div className="space-26px">
                                                    <p className="create-oneedw">You haven't got any results yet !</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <ReactTooltip html={true} className="tooltip" />
                <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
            </div>
        </>
    );
}

export default BidHistory;