import React, { useEffect, useState } from 'react';
import Sidebar from "../Basic/Sidebar";
import { format } from 'date-fns';
import Toaster from "../Basic/Toaster";
import ReactTooltip from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { useService } from "../hooks/useService";
import { useAuth } from "../hooks/useAuth";
import ListPagination from '../Basic/Pagination';
import Loader from '../Basic/Loader';

function AdminLotList(props) {

    const navigate = useNavigate();
    const { adminUser } = useAuth();

    const { lotList, importLots, adminMarketplaceList, unpublishedLotList } = useService();
    const [loading, setLoading] = useState(false);

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [lotsData, setLotData] = useState([]);
    const [unpublishedLotData, setUnpublishedLotData] = useState([]);
    const [marketplacesData, setMarketplaceData] = useState([]);
    const [selectedMarketplace, setSelectedMarketplace] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUploadedStatus, setFileUploadedStatus] = useState("no_image");
    const [otherUploadError, setOtherUploadError] = useState(null);
    const [uploaderErrors, setUploaderErrors] = useState([]);
    const [uploadStatus, setUploadStatus] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchText, setSearchText] = useState('');

    const [currentSegment, setCurrentSegment] = useState("actLotSegment");

    const changeSegment = async (segment) => {
        setCurrentSegment(segment);
        if (segment === "unPublishedLotSegment") {
            document.getElementById("actLotSegment").classList.remove("active");
            document.getElementById("unPublishedLotSegment").classList.add("active");
        } else {
            document.getElementById("unPublishedLotSegment").classList.remove("active");
            document.getElementById("actLotSegment").classList.add("active");
        }

    }

    const getLotList = (currentPage, search = '') => {
        setLoading(true);
        lotList('ALL_ADMIN', currentPage, 10, search)
            .then(response => {
                if (response && response.data && response.data.length) {
                    setTotalPages(response?.totalPages || 1);
                    setCurrentSegment("actLotSegment");
                    setLotData(response.data);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const getUnpublisdhedLotListHandler = (currentPage, search = '') => {
        setLoading(true);
        unpublishedLotList(currentPage, 10, search)
            .then(response => {
                if (response && response.data && response.data.length) {
                    setTotalPages(response?.totalPages || 1);
                    setCurrentSegment("unPublishedLotSegment");
                    setUnpublishedLotData(response.data);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const getMarketplaceList = () => {
        adminMarketplaceList("ADMIN")
            .then(response => {
                if (response && response.data && response.data.length) {
                    setMarketplaceData(response.data);
                }
            })
            .catch(error => {
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const handleReupload = (e) => {
        e.preventDefault();

        setUploaderErrors([]);
        setUploadStatus(false);
        setOtherUploadError(null);
        setFileUploadedStatus("no_image");
        setSelectedFile(null);
    }

    const handleSubmitUpload = (e) => {
        e.preventDefault();

        importLots(selectedFile, selectedMarketplace)
            .then(response => {
                console.log(response);
                if (response.status !== 200 && response.status !== 201) {
                    setUploaderErrors(response);
                    setUploadStatus(false);
                    setOtherUploadError(response.Error);
                } else if (response.status === 200 || response.status === 201) {
                    setUploaderErrors([]);
                    setOtherUploadError(null);
                    setUploadStatus(true);
                    setTimeout(window.location.reload(), 2000);
                } else {
                    setOtherUploadError(null);
                    setUploadStatus(false);
                    setUploaderErrors([]);
                }
                setFileUploadedStatus("image_uploaded");
            })
            .catch(error => {
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const handleProductUpload = (e) => {
        console.log(e.target.files[0]);
        setSelectedFile(e.target.files[0]);
        setFileUploadedStatus("image_added");
    }

    const handleSearch = () => {
        if (searchText.length >= 3) {

            if (currentSegment === 'unPublishedLotSegment') {

                document.getElementById("actLotSegment").classList.remove("active");
                document.getElementById("unPublishedLotSegment").classList.add("active");
                getUnpublisdhedLotListHandler(currentPage, searchText);
            } else {

                document.getElementById("unPublishedLotSegment").classList.remove("active");
                document.getElementById("actLotSegment").classList.add("active");
                getLotList(currentPage, searchText);
            }
        } else if (searchText.length === 0) {

            if (currentSegment === 'unPublishedLotSegment') {

                document.getElementById("actLotSegment").classList.remove("active");
                document.getElementById("unPublishedLotSegment").classList.add("active");
                getUnpublisdhedLotListHandler(currentPage);
            } else {

                document.getElementById("unPublishedLotSegment").classList.remove("active");
                document.getElementById("actLotSegment").classList.add("active");
                getLotList(currentPage);
            }
        }
    }

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const _handleLotRows = (list, renderType) => {
        return (
            <tbody>
                {list.map((item, index) => (
                    <tr className="gry-tred" key={index}>
                        <td className="body-title">
                            <a onClick={() => navigate(`/selected-lot/${item.lotid}`)} className="numberw"> {item.lotid}</a>
                        </td>
                        <td className="body-title">
                            {/* <a href="" className="numberw"> {item.marketplace_info?.name}</a> */}
                            <img width="80px" src={item.marketplace_info?.image || './../assets/img/no_image.jpg'} alt="" />
                        </td>
                        <td className="body-title">{item.lot_name}</td>
                        <td className="body-title">{format(new Date(item.created_date), "dd/MM/yyyy, hh:MM aaaaa'm'")}</td>
                        <td className="body-title lot-image-wrapper text-center"><img src={item?.lot_image_urls?.[0] || './../assets/img/no_image.jpg'} alt="" /></td>

                        {renderType === 'active' && (
                            <td className="body-title">{item.status === 'winning'
                                ? (<span className="is-invalid-message">Sold in a bid</span>)
                                : item.status === 'active' ? (<span className="is-valid-message">Active</span>)
                                    : (<span className="is-invalid-message">{item?.status?.capitalize()}</span>)}
                            </td>
                        )}

                        {renderType === 'unpublished' && (
                            <td className="body-title"><span className="is-invalid-message">Unpublished</span></td>
                        )}
                    </tr>
                ))}
            </tbody>
        )
    }

    useEffect(() => {
        let lotSection = document.getElementById("lotSection");
        let lotSummarySection = document.getElementById("lotSummarySection");
        lotSummarySection.classList.add("showMenu");
        lotSection.classList.add("active");

        getLotList();
        getMarketplaceList();
    }, []);

    useEffect(() => {
        if (searchText.length >= 3 || searchText.length === 0) {
            handleSearch();
        }
    }, [searchText]);

    useEffect(() => {
        const fetchBids = async () => {
            setLoading(true);
            try {
                if (currentSegment === "unPublishedLotSegment") {

                    document.getElementById("actLotSegment").classList.remove("active");
                    document.getElementById("unPublishedLotSegment").classList.add("active");
                    getUnpublisdhedLotListHandler(currentPage);
                } else {

                    document.getElementById("unPublishedLotSegment").classList.remove("active");
                    document.getElementById("actLotSegment").classList.add("active");
                    getLotList(currentPage);
                }
            } catch (error) {
                console.error('Failed to fetch bids:', error);
                setShowToaster(true);
                setToasterMessage('Failed to load data');
            } finally {
                setLoading(false);
            }
        };

        fetchBids();
    }, [currentSegment, currentPage]); // Re-fetch when currentSegment changes


    return (
        <div className="main-parant-1">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <section className="sec-1">
                        <Sidebar />
                        <section className="home-section">
                            <div className="main-rightbar-box">
                                <div>
                                    <div className="Rule-page">
                                        <div className="main-jaste">
                                            <div className="mi">
                                                <h1 className="left-title">Lots</h1>
                                            </div>
                                            {adminUser?.role?.toUpperCase() !== 'FINANCE' ? (
                                                <div className="main-flxwer">
                                                    <a className="btm-dwece pointer" data-bs-toggle="modal" data-bs-target="#ImportLots"><img src="assets/img/arrow.up.doc.svg" className="pluserd" alt="" />IMPORT Lots</a>
                                                </div>
                                            ) : ('')}
                                        </div>
                                        <div>
                                            <div className="main-search-side mb-3">
                                                <div className="btn-input wimor adcqdq search-wrapper">
                                                    <input type="text" id="searchItem" placeholder="Search by Lot ID" className="name-poi aede search" value={searchText} onChange={handleSearchChange} />
                                                    <div>
                                                        <img src="assets/img/SAERCH1.svg" className="SAERCH1" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="wihte-erya">
                                                    <div className="flaxertw">
                                                        <div className="activ-sab">
                                                            <a id="actLotSegment" className="pointer active-sub active" onClick={() => changeSegment("actLotSegment")}>Current Published Lots</a>
                                                            <a id="unPublishedLotSegment" className="pointer active-sub" onClick={() => changeSegment("unPublishedLotSegment")}>Unpublished Lots</a>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {currentSegment === "actLotSegment" &&
                                                            (
                                                                <table className="w-100 m-0">
                                                                    <thead>
                                                                        <tr className="gry-tr h-74">
                                                                            <td className="head-title padin-24">Lots ID (123)</td>
                                                                            <td className="head-title padin-24">Marketplace</td>
                                                                            <td className="head-title padin-24">Lot Description</td>
                                                                            <td className="head-title padin-24">Created On</td>
                                                                            <td className="head-title padin-24">Image</td>
                                                                            <td className="head-title padin-24">Lot Status</td>
                                                                        </tr>
                                                                    </thead>
                                                                    {lotsData.length !== 0 &&
                                                                        _handleLotRows(lotsData, 'active')
                                                                    }
                                                                </table>
                                                            )
                                                        }
                                                        {currentSegment === "unPublishedLotSegment" &&
                                                            (
                                                                <table className="w-100 m-0">
                                                                    <thead>
                                                                        <tr className="gry-tr h-74">
                                                                            <td className="head-title padin-24">Lots ID (123)</td>
                                                                            <td className="head-title padin-24">Marketplace</td>
                                                                            <td className="head-title padin-24">Lot Description</td>
                                                                            <td className="head-title padin-24">Created On</td>
                                                                            <td className="head-title padin-24">Image</td>
                                                                            <td className="head-title padin-24">Lot Status</td>
                                                                        </tr>
                                                                    </thead>
                                                                    {unpublishedLotData.length !== 0 &&
                                                                        _handleLotRows(unpublishedLotData, 'unpublished')
                                                                    }
                                                                </table>
                                                            )
                                                        }
                                                    </div>
                                                    {(currentSegment === "unPublishedLotSegment" ? unpublishedLotData.length === 0 : lotsData.length === 0) &&
                                                        (
                                                            <div className="space-26px">
                                                                <p className="create-oneedw">No results</p>
                                                            </div>
                                                        )
                                                    }
                                                    <ListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </>
            )}

            {/* <!-- Modal Import Lots --> */}
            <div className="modal fade" id="ImportLots" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog proder">
                    <div className="modal-content styled">
                        <div className="modal-FLAX">
                            <h5 className="modal-title amkw">Import Lots</h5>
                            <button type="button" className="btner" data-bs-dismiss="modal" aria-label="Close"><img src="assets/img/multiply.svg" className="multiply-ic" alt="" /></button>
                        </div>
                        <div>
                            <div className="adcwf">
                                <div className="kbiqud">
                                    <h5 className="modal-title amkw" >Step 1: Download template</h5>
                                    <p className="dodo-err">Download the template, edit it according to given format and upload the excel file</p>
                                </div>
                                <div>
                                    <a href="assets/sample/Upload_sample.csv" className="DOWNATE download-template" download><img src="assets/img/12345.doc.svg" className="DOWNLO-ic" alt="" /><span>DOWNLOAD TEMPLATE</span></a>
                                </div>
                            </div>
                            <div className="adcwf pt-0">
                                <div className="kbiqud">
                                    <h5 className="modal-title amkw" >Step 2: Select Marketplace</h5>
                                    <p className="dodo-err">Select a specific marketplace in which you want to upload the lots.</p>
                                </div>
                                <div>
                                    <div className="select-marketplace">
                                        <label htmlFor="marketplaceSelect"></label>
                                        <select
                                            id="marketplaceSelect"
                                            className="form-select"
                                            value={selectedMarketplace}
                                            onChange={(e) => setSelectedMarketplace(e.target.value)}
                                        >
                                            <option value="">Select a marketplace</option>
                                            {marketplacesData.map((marketplace, index) => (
                                                <option key={index} value={marketplace.mpid}>
                                                    {marketplace.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h5 className="modal-title amkw">Step 3: Upload file</h5>
                                </div>

                                {fileUploadedStatus === "no_image" &&
                                    (
                                        <label htmlFor="fileToUpload" className={selectedMarketplace ? `upload-file-wrapper stap-12` : `upload-file-wrapper stap-12 disabled`}>
                                            <div>
                                                <div className="klknwed">
                                                    <div>
                                                        <img src="assets/img/123455.png" className="qwed" alt="" />
                                                    </div>
                                                    <p className="Dropqw">Drop your xslx file to upload</p>
                                                    <a className="Dropqw-like">Choose file </a>
                                                    <p className="Maxqw">Max file size : 5MB</p>
                                                    <input type="file" name="fileToUpload" className="hidden" id="fileToUpload" accept=".xlsx, .xls, .csv" onChange={(event) => handleProductUpload(event)}></input>
                                                </div>
                                            </div>
                                        </label>
                                    )
                                }

                                {fileUploadedStatus === "image_added" &&
                                    (<div className="stap-12">
                                        <div className="main-exert">
                                            <div className="in-exerqq">
                                                <div className="spacerr">
                                                    <div className="main-exert12">
                                                        <div>
                                                            <img src="assets/img/c.text.svg" className="qwe12" alt="" />
                                                        </div>
                                                        <div className="main-exert422">
                                                            <p className="dxasd">{selectedFile.name}</p>
                                                            <p className="qwedq">{selectedFile && (Math.round(selectedFile.size / 1024) + " KB")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="rang"></div>
                                                </div>
                                                <div className="main-exert12">
                                                    {/* <p className="qwedq">40%</p> */}
                                                    <img src="assets/img/multiply.circle.svg" className="qwe12" alt="" onClick={(event) => handleReupload(event)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                }


                                {fileUploadedStatus === "image_uploaded" &&
                                    (
                                        <div>
                                            <div className="stap-file">
                                                <div className="main-exert">
                                                    <div className="in-exerqq wdq">
                                                        <div className="spacerr">
                                                            <div className="main-exert12">
                                                                <div>
                                                                    <img src="assets/img/c.text.svg" className="qwe12" alt="" />
                                                                </div>
                                                                <div className="main-exert422">
                                                                    <p className="dxasd">{selectedFile.name}</p>
                                                                    {uploadStatus && (<p className="qwedq">Uploaded successfully</p>)}
                                                                    {!uploadStatus && (<p className="qwedq edd">Uploading failed</p>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="main-exert12">
                                                            <img src="assets/img/a.svg" className="qwe12" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {otherUploadError &&
                                                (
                                                    <div className="kjiuqw">
                                                        <img src="assets/img/exclamationmark.circle.fill.svg" className="mhuiq error" alt="" />
                                                        <p className="kjh9u error">{otherUploadError}</p>
                                                    </div>
                                                )
                                            }

                                            {uploaderErrors.length !== 0 &&
                                                (
                                                    <div className="kjbiq">
                                                        <div className="kjiuqw">
                                                            <img src="assets/img/exclamationmark.circle.fill.svg" className="mhuiq" alt="" />
                                                            <p className="kjh9u">Here are the lots with incorrect data</p>
                                                        </div>
                                                        <div className="khgqiw">
                                                            <table className="wdqe w-100 m-0">
                                                                <thead>
                                                                    <tr className="main-row">
                                                                        <td className="main-tabbo">error</td>
                                                                        <td className="main-tabbo">lot_name</td>
                                                                        <td className="main-tabbo">lot_id</td>
                                                                        <td className="main-tabbo">price</td>
                                                                        <td className="main-tabbo">image_url</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {uploaderErrors.map((item, index) => (
                                                                        <tr className="gry-tred" key={index}>
                                                                            <td className="main-tabbo red">{item.error}</td>
                                                                            <td className="main-tabbo">{item.lot_name}</td>
                                                                            <td className="main-tabbo">{item.lot_id}</td>
                                                                            <td className="main-tabbo">Rs. {item.price}</td>
                                                                            <td className="main-tabbo">{item.image_url}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }

                                {fileUploadedStatus === "image_added" &&
                                    (
                                        <div className="wsdq">
                                            <a href="" className="UPLOA-DUCTS bluww" onClick={(event) => handleSubmitUpload(event)}>UPLOAD LOTS</a>
                                        </div>
                                    )
                                }

                                {fileUploadedStatus === "image_uploaded" && !uploadStatus &&
                                    (
                                        <div className="wsdq">
                                            <a href="" className="UPLOA-DUCTS bluww" onClick={(event) => handleReupload(event)}>RE-UPLOAD</a>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ReactTooltip html={true} className="tooltip" />
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </div>
    );
}

export default AdminLotList;