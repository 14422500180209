import { createContext, useContext, useMemo, useState } from "react";

const StoreContext = createContext();

export function useStore() {
  return useContext(StoreContext);
};

// =============================================================================
// STORE PROVIDER FUNCTIONS
// =============================================================================

export function StoreProvider({ children }) {

  const [savedlotList, setSavedlotList] = useState([]);
  const [selectedLotData, setSelectedLotData] = useState({});
  const [productsForSelectecLot, setProductList] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filtersList, setFiltersList] = useState({});

  const value = useMemo(
    () => ({
      filtersList,
      selectedFilters,
      selectedLotData,
      savedlotList,
      productsForSelectecLot,
      setFiltersList,
      setSelectedLotData,
      setProductList,
      setSavedlotList,
      setSelectedFilters
    }),
    [savedlotList, selectedLotData, productsForSelectecLot, selectedFilters]
  );

  // const value = {
  //   selectedLotData,
  //   productsForSelectecLot,
  //   setSelectedLotData,
  //   setProductList
  // };

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};