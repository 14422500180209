import React, { useEffect, useState } from 'react';
import Sidebar from "../Basic/Sidebar";
import { validateInput, content } from '../Config/config';
import Toaster from "../Basic/Toaster";
import ReactTooltip from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { useService } from "../hooks/useService";
import Badge from 'react-bootstrap/Badge';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Loader from '../Basic/Loader';

function AdminPaymentsList({ role }) {

    const navigate = useNavigate();
    const { getPayments, updatePaymentStatus } = useService();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [paymentsData, setPaymentsData] = useState([]);

    const [selectedItem, setSelectedItem] = useState({});
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('pending');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [fullPaymentAmount, setFullPaymentAmount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const gePaymentsHandler = (currentPage, search = '') => {
        setTimeout(setLoading(true), 600);
        getPayments(currentPage, search)
            .then(response => {
                if (response && response.length) {
                    setPaymentsData(response);
                } else {
                    setPaymentsData([]);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const handleActions = (item) => {
        console.log(item);
        setSelectedItem(item);
        if (item?.payment_status) {
            setSelectedPaymentStatus(item?.payment_status);
        }
    }

    const updatePaymentStatusHandler = (e) => {
        let status = e.target.value;
        setSelectedPaymentStatus(status);
        let fullPayment = selectedItem?.highest_bid_price || 0;
    
        if (selectedItem.status === "sold") {
            fullPayment = selectedItem?.buy_now_price || 0;
        }

        setFullPaymentAmount(fullPayment);
    
        if (status === "complete") {
           
            const remainingAmount = fullPayment - (selectedItem?.payment_amount || 0);
            const finalPaymentAmount = remainingAmount > 0 ? remainingAmount : 0;
            setPaymentAmount(finalPaymentAmount);
        } else {
            setPaymentAmount('');
        }
    }

    const updatePaymentHandler = (e) => {
        const payment = (paymentAmount || 0) + (selectedItem?.payment_amount || 0);
        let payload = {
            payment_status: selectedPaymentStatus,
            payment_amount: payment > fullPaymentAmount ? fullPaymentAmount : payment
        }

        setLoading(true);
        updatePaymentStatus(selectedItem.lotid, payload)
            .then(response => {
                setReload(true);
                setShowToaster(true);
                setToasterMessage(response.message);

                setTimeout(() => {
                    window.location.reload();
                }, 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const _handleUserRows = (list) => {
        return (
            <tbody>
                {list.map((item, index) => (
                    <tr className="gry-tred" key={index}>
                        <td className="body-title">
                            <a className="numberw" onClick={() => navigate(`/selected-lot/${item.lotid}`)}>
                                {item.lotid}
                            </a>
                        </td>
                        <td className="body-title">{item.lot_name}</td>
                        <td className="body-title">{item.benchmark_bid_price || 0}</td>
                        <td className="body-title">{item.status === "sold" ? item.buy_now_price : item.highest_bid_price || 0}</td>
                        <td className="body-title">{item.winner_uid || "--"}</td>
                        <td className="body-title">
                            {item?.payment_status === "partial" ? (
                                <Badge bg="warning">Partially Done</Badge>
                            ) : item?.payment_status === "complete" ? (
                                <Badge bg="success">Completed</Badge>
                            ) : (
                                <Badge bg="danger">Pending</Badge>
                            )
                            }
                        </td>
                        <td className="body-title">{item.status === "sold" ? item?.buy_now_price - item?.payment_amount : item?.highest_bid_price - item?.payment_amount}</td>
                        <td className="body-title text-center">
                            {item?.payment_status === "complete" ?
                                (<img width="30" src="assets/img/Frame1.svg" className="pancen-1st t-btn-disabled" alt="" />) :
                                (<img width="30" src="assets/img/Frame1.svg" data-bs-toggle="modal" data-bs-target="#updatePayment" className="pancen-1st" alt="" onClick={() => handleActions(item)} />)}
                        </td>
                    </tr>
                ))}
            </tbody>
        )
    }

    const handleSearch = () => {
        let searchText = document.getElementById('searchItem');
        if (searchText.value.length >= 3) {

            gePaymentsHandler(currentPage, searchText.value);
        } else if (searchText.value.length === 0) {
            gePaymentsHandler(currentPage);
        }
    }

    useEffect(() => {
        let paymentsSection = document.getElementById("paymentsSection");
        paymentsSection.classList.add("active");
        setReload(false);
        gePaymentsHandler(currentPage);
    }, [reload, currentPage]);


    return (
        <div className="main-parant-1">
            <section className="sec-1">
                <Sidebar />
                <section className="home-section">
                    <div className="main-rightbar-box">
                        <div>
                            <div className="Rule-page">
                                <div className="main-jaste">
                                    <div className="mi">
                                        <h1 className="left-title">Payments</h1>
                                    </div>
                                </div>
                                <div>
                                    {/* <div className="main-search-side mb-3">
                                        <div className="btn-input wimor adcqdq search-wrapper">
                                            <input type="text" id="searchItem" placeholder="Search by Lot ID" className="name-poi aede search" onChange={handleSearch} />
                                            <div>
                                                <img src="assets/img/SAERCH1.svg" className="SAERCH1" alt="" />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="wihte-erya">
                                        <div>
                                            <table className="w-100 m-0">
                                                <thead>
                                                    <tr className="gry-tr h-74">
                                                        <td className="head-title padin-24">Lot ID (123)</td>
                                                        <td className="head-title padin-24">Lot Name</td>
                                                        <td className="head-title padin-24">Reserve Price</td>
                                                        <td className="head-title padin-24">Winning Price</td>
                                                        <td className="head-title padin-24">Winner</td>
                                                        <td className="head-title padin-24">Payment Status</td>
                                                        <td className="head-title padin-24">Pending Payment</td>
                                                        <td className="head-title padin-24">Action</td>
                                                    </tr>
                                                </thead>
                                                {paymentsData.length !== 0 &&
                                                    _handleUserRows(paymentsData)
                                                }
                                            </table>
                                        </div>
                                        {paymentsData.length === 0 &&
                                            <div className="space-26px">
                                                <p className="create-oneedw">You haven't got any data yet, <a className="add-custm-btn" onClick={() => navigate("/lot-list")}> Check The Lots</a></p>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            {/* <!-- Modal Edit payment --> */}
            <div className="modal fade" id="updatePayment" tabIndex={"-1"} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog proder">
                    <div className="modal-content styled">
                        <div className="modal-FLAX">
                            <h5 className="modal-title">Update payment</h5>
                            <button type="button" className="btner" data-bs-dismiss="modal" aria-label="Close"><img src="assets/img/multiply.svg" className="multiply-ic" alt="" /></button>
                        </div>

                        <div className="four-inpute">
                            <Row>
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Payment Status"
                                    >
                                        <Form.Select aria-label="Select Slab" className='trader-dropdown' defaultValue={selectedPaymentStatus} onChange={updatePaymentStatusHandler}>
                                            <option>Open to select payment status</option>
                                            <option value="pending">Pending Payment</option>
                                            <option value="partial">Partial Payment</option>
                                            <option value="complete">Complete Payment</option>
                                        </Form.Select>

                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Payment Amount"
                                    >
                                        <Form.Control size="sm"
                                            placeholder="Location"
                                            aria-label="Location"
                                            aria-describedby="basic-addon2"
                                            className='trader-payment-input'
                                            defaultValue={paymentAmount}
                                            onChange={(e) => setPaymentAmount(e.target.value)}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                        </div>
                        <div className="main-cntwr">
                            <a className="SAVE-btn blue" onClick={updatePaymentHandler} >UPDATE</a>
                        </div>
                    </div>
                </div>
            </div>

            <ReactTooltip html={true} className="tooltip" />
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </div>
    );
}

export default AdminPaymentsList;