import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useService } from "../hooks/useService";
import { useAuth } from "../hooks/useAuth";
import Toaster from "../Basic/Toaster";

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Login from '../Onboarding/Login';
// import traderLogo from 'https://togofogo.com/assets/imges/logo.png';

import { jwtDecode } from 'jwt-decode';

function HeaderNavbar(props) {
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const { getUserBidsHistory } = useService();

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [showLogin, setShowLogin] = useState(false);

    const handleToggleLoginModal = (data) => {
        setShowLogin(false);
    };

    function isTokenExpired(token) {
        if (!token) return true; // No token provided, treat as expired

        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000);
            return decodedToken.exp < currentTime;
        } catch (error) {
            console.error('Error decoding token:', error);
            return true; // Error decoding, treat as expired
        }
    };

    function handleToggleMobileMenu() {
        document.querySelector('body').classList.toggle('mobile-menu-visible');
    }

    function navigateToMobilePage(route) {
        document.querySelector('body').classList.remove('mobile-menu-visible');
        navigate(route);
    }

    useEffect(() => {
        if (user && Object.keys(user).length && user.isLoggedIn) {
            if (isTokenExpired(user.token)) {
                logout("BUYER");
                setShowToaster(true);
                setToasterMessage('Session expired. Please login again.');
            }
        }
    }, [user]);

    return (
        <>
            {/* main header */}
            <header className="main-header">
                <div className="header-top">
                    <div className="auto-container">
                        <div className="top-inner clearfix">
                            <div className="top-left pull-left">
                                <ul className="info clearfix">
                                    <li><i className="flaticon-email" /><a href="mailto:contactus@xtracover.com">contactus@xtracover.com</a></li>
                                    <li><i className="flaticon-global" /> New Delhi, South Delhi 110020</li>
                                </ul>
                            </div>
                            <div className="top-right pull-right">
                                <ul className="social-links clearfix hide">
                                    <li><a onClick={() => navigate('/')}><i className="fab fa-facebook-f" /></a></li>
                                    <li><a onClick={() => navigate('/')}><i className="fab fa-instagram" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-lower">
                    <div className="auto-container">
                        <div className="outer-box">
                            <figure className="logo-box"><a onClick={() => navigate(`/`)}>
                                <img width={180} src="https://togofogo.com/assets/images/logo.png" alt="" /></a></figure>
                            <div className="menu-area">
                                <div className="mobile-nav-toggler" onClick={() => handleToggleMobileMenu()}>
                                    <i className="icon-bar" />
                                    <i className="icon-bar" />
                                    <i className="icon-bar" />
                                </div>
                                <nav className="main-menu navbar-expand-md navbar-light">
                                    <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                        <ul className="navigation clearfix">
                                            <li><a onClick={() => navigate(`/`)}>Home</a></li>
                                            <li className='nav-all-mp-btn'><a onClick={() => navigate(`/marketplace`)}>Explore All Marketplaces</a></li>
                                            <li className='nav-all-lots-btn'><a onClick={() => navigate(`/all-lots`)}>Explore All Lots<span>Hot</span></a></li>
                                            {user && Object.keys(user).length && user.isLoggedIn ? ('') : (
                                                <>
                                                    <li className='nav-seller-btn'><a onClick={() => navigate(`/seller`)}>Be a Seller</a></li>
                                                    <li className='nav-buyer-btn'><a onClick={() => navigate(`/buyer`)}>Be a Buyer</a></li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <ul className="menu-right-content navigation clearfix">
                                {user && Object.keys(user).length && user.isLoggedIn ? (
                                    <>
                                        <li className='nav-bid-history-btn'><a onClick={() => navigate(`/bid-history`)}><i className="fa-solid fa-clock-rotate-left" /></a></li>
                                        <li><a data-bs-toggle="modal" data-bs-target="#LOGOUT"><i className="fa-solid fa-circle-user" /></a></li>
                                    </>
                                ) : (
                                    <>
                                        {/* <li><a onClick={() => navigate(`/register`)}><i className="fi fi-bs-user" /></a></li>
                                        <li><a onClick={() => setShowLogin(true)}><i className="fi fi-rr-key" /></a></li> */}
                                        <li><img className='pointer' onClick={() => setShowLogin(true)} width={55} src="https://togofogo.com/assets/img/togofogo-login.png" alt="" /></li>
                                        <li><img className='pointer' onClick={() => navigate(`/register`)} width={55} src="https://togofogo.com/assets/img/togofogo-signup.png" alt="" /></li>
                                        <li><img className='pointer' onClick={() => navigate(`/reset-password`)} width={30} src="https://togofogo.com/assets/img/reset-password.png" alt="" /></li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                {/*sticky Header*/}
                <div className="sticky-header">
                    <div className="auto-container">
                        <div className="outer-box clearfix">
                            <div className="logo-box pull-left">
                                <figure className="logo"><a onClick={() => navigate('/')}><img width={180} src="https://togofogo.com/assets/images/logo.png" alt="" /></a></figure>
                            </div>
                            <div className="menu-area sticky-menu-wrapper pull-right">
                                <nav className="main-menu pull-left">
                                    <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                        <ul className="navigation clearfix">
                                            <li><a onClick={() => navigate(`/`)}>Home</a></li>
                                            <li className='nav-all-mp-btn'><a onClick={() => navigate(`/marketplace`)}>Explore All Marketplaces</a></li>
                                            <li className='nav-all-lots-btn'><a onClick={() => navigate(`/all-lots`)}>Explore All Lots<span>Hot</span></a></li>
                                            {user && Object.keys(user).length && user.isLoggedIn ? ('') : (
                                                <>
                                                    <li className='nav-seller-btn'><a onClick={() => navigate(`/seller`)}>Be a Seller</a></li>
                                                    <li className='nav-buyer-btn'><a onClick={() => navigate(`/buyer`)}>Be a Buyer</a></li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </nav>
                                <ul className="menu-right-content navigation clearfix">
                                    {user && Object.keys(user).length && user.isLoggedIn ? (
                                        <>
                                            <li className='nav-bid-history-btn'><a onClick={() => navigate(`/bid-history`)}><i className="fa-solid fa-clock-rotate-left" /></a></li>
                                            <li><a data-bs-toggle="modal" data-bs-target="#LOGOUT"><i className="fa-solid fa-circle-user" /></a></li>
                                        </>
                                    ) : (
                                        <>
                                            <li><img className='pointer' onClick={() => setShowLogin(true)} width={55} src="https://togofogo.com/assets/img/togofogo-login.png" alt="" /></li>
                                            <li><img className='pointer' onClick={() => navigate(`/register`)} width={55} src="https://togofogo.com/assets/img/togofogo-signup.png" alt="" /></li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* main-header end */}
            {/* Mobile Menu  */}
            <div className="mobile-menu">
                <div className="menu-backdrop" />
                <div className="close-btn" onClick={() => handleToggleMobileMenu()}><i className="fas fa-times" /></div>
                <nav className="menu-box">
                    <div className="nav-logo"><a onClick={() => navigateToMobilePage('/')}><img width={180} src="https://togofogo.com/assets/images/logo.png" alt="" title="true" /></a></div>
                    <div className="menu-outer">
                        <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                            <ul className="navigation clearfix">
                                <li><a onClick={() => navigateToMobilePage(`/`)}>Home</a></li>
                                <li className='nav-all-mp-btn'><a onClick={() => navigateToMobilePage(`/marketplace`)}>Explore All Marketplaces</a></li>
                                <li className='nav-all-lots-btn'><a onClick={() => navigateToMobilePage(`/all-lots`)}>Explore All Lots<span>Hot</span></a></li>
                                {user && Object.keys(user).length && user.isLoggedIn ? ('') : (
                                    <>
                                        <li className='nav-seller-btn'><a onClick={() => navigateToMobilePage(`/seller`)}>Be a Seller</a></li>
                                        <li className='nav-buyer-btn'><a onClick={() => navigateToMobilePage(`/buyer`)}>Be a Buyer</a></li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="contact-info">
                        <h4>Contact Info</h4>
                        <ul className="info-list clearfix">
                            <li>New Delhi, South Delhi
                                <br />India Pin-110020
                            </li>
                            <li><a href="tel:+91-8860396039">+91-8860396039</a></li>
                            <li><a href="mailto:contactus@xtracover.com">contactus@xtracover.com</a></li>
                        </ul>
                    </div>
                    <div className="social-links hide">
                        <ul className="clearfix">
                            <li><a onClick={() => navigate('/')}><span className="fab fa-facebook-square" /></a></li>
                            <li><a onClick={() => navigate('/')}><span className="fab fa-instagram" /></a></li>
                        </ul>
                    </div>
                </nav>
            </div>
            {/* End Mobile Menu */}

            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />

            {/* <!-- Modal LOGOUT data-bs-toggle="modal" data-bs-target="#LOGOUT" --> */}
            <div className="modal fade" id="LOGOUT" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog addtag-three side-right">
                    <div className="modal-content styled">
                        <div className="">
                            <div className="positiqw ger">
                                <div className="custm-gride">
                                    <div className='logout-modal-role-wrapper'>
                                        <p className="Customer-13px">Logged in as </p>
                                        <p className='Customer-13px admin-role-wrapper'>{`${user?.role?.toLowerCase().capitalize()}`}</p>
                                    </div>
                                    <p className="Customer-15px bold">{user ? user?.email : 'User'}</p>
                                </div>
                                <div>
                                    <a className="theme-btn-one login-btn" onClick={() => logout("BUYER")} data-bs-dismiss="modal">LOG OUT</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal LOGOUT data-bs-toggle="modal" data-bs-target="#LOGOUT" --> */}
            <Login showLoginSetter={handleToggleLoginModal} show={showLogin} />
        </>
    );
}

export default HeaderNavbar;
