import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

function ListPagination({ totalPages, currentPage, setCurrentPage }) {

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const maxPagesToShow = 5; // Maximum number of pages to display in the pagination
    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
        // Less than maxPagesToShow total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // More than maxPagesToShow total pages so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            startPage = 1;
            endPage = maxPagesToShow;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    let items = [];
    // First and Previous buttons
    items.push(<Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />);
    items.push(<Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />);

    // Page number buttons
    for (let number = startPage; number <= endPage; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                {number}
            </Pagination.Item>,
        );
    }

    // Next and Last buttons
    items.push(<Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />);
    items.push(<Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />);

    return (
        <div className='pagination-wrapper'>
            {totalPages > 1 && <Pagination size="md">{items}</Pagination>}
        </div>
    );
}

export default ListPagination;