import React from 'react';
import { useNavigate } from "react-router-dom";
import './../../assets/css/style.css';
import './../../assets/css/owl.css';
import OwlCarousel from 'react-owl-carousel';

function Banner(props) {
  const navigate = useNavigate();

  const options = {
    loop: true,
    margin: 0,
    nav: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    active: true,
    smartSpeed: 1000,
    autoplay: 6000,
    navText: ['<span class="flaticon-left-2"></span>', '<span class="flaticon-right-1"></span>'],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      800: {
        items: 1
      },
      1024: {
        items: 1
      }
    }
  };

  return (
    <>
      {props.type === 'home' ? (
        <section className="banner-style-one">
          <div className="pattern-layer" style={{ backgroundImage: 'url(./../../assets/images/shape/shape-1.png)' }} />
          <OwlCarousel className='banner-carousel owl-theme' margin={1} options={options} nav>
            <div className="slide-item item">
              <div className="auto-container">
                <div className="content-inner">
                  <div className="content-box">
                    <h1>Place your <br /><span>bids</span> now</h1>
                    <h3>Explore our latest lots</h3>
                    <p>Explore our latest lots and place bids to buy.</p>
                    <div className="btn-box">
                      <a onClick={() => navigate('/all-lots')} className="theme-btn-one">Bid Now<i className="flaticon-right-1" /></a>
                    </div>
                  </div>
                  {/* <figure className="image-box image-1"><img src="assets/images/banner/banner-image-1.png" alt="" /> */}
                  <figure className="image-box image-1">
                    <img src="assets/img/bidding_03.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </section>
      ) : props.type === 'buyer' ? (
        <div className="banner buyer-banner container-fluid px-4 py-5 text-white">
          <div className="row flex-xl-row-reverse align-items-center g-5 p-5">
            <div className="col-12 col-sm-8 offset-sm-2 col-xl-5 mt-0 offset-xl-0">
              {/* <img src="./../assets/img/banner-products.png" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" /> */}
            </div>
            <div className="col-xl-7">
              <p className='mb-4 d-none'>A Trusted B2B Market Place To Buy Tested and Graded Excess, Returned, Refurnished, and Decommissioned inventory</p>
              <h1 className="display-5 fw-normal lh-1 mb-3 py-5 text-white">Join us and get access to direct <br /> community of buyers and sellers</h1>
              <b>
                <div className="row d-none">
                  <div className="col-lg-6 mt-3">
                    <i className="far fa-check-square me-3"></i>
                    No Middleman
                  </div>
                  <div className="col-lg-6 mt-3">
                    <i className="far fa-check-square me-3"></i>
                    All Lot Sizes
                  </div>
                  <div className="col-lg-6 mt-3 ">
                    <i className="far fa-check-square me-3"></i>
                    Attractive Prices
                  </div>
                </div>
              </b>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-5">
                <button type="button" onClick={() => navigate(`/register`)} className="btn btn-light btn-lg px-4 me-md-2">REGISTER AS BUYER</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="banner seller-banner container-fluid px-4 py-5 text-white">
          <div className="row flex-xl-row-reverse align-items-center g-5 p-5">
            <div className="col-12 col-sm-8 offset-sm-2 col-xl-5 mt-0 offset-xl-0">
              {/* <img src="./../assets/img/banner-products.png" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" /> */}
            </div>
            <div className="col-xl-7">
              <p className='mb-4 d-none'>A Trusted B2B Market Place To Buy Tested and Graded Excess, Returned, Refurnished, and Decommissioned inventory</p>
              <h1 className="display-5 fw-normal lh-1 mb-3 py-5 text-white">Sell your inventory directly to a <br /> pool of bulk buyers across India </h1>
              <b>
                <div className="row d-none">
                  <div className="col-lg-6 mt-3">
                    <i className="far fa-check-square me-3"></i>
                    No Middleman
                  </div>
                  <div className="col-lg-6 mt-3">
                    <i className="far fa-check-square me-3"></i>
                    All Lot Sizes
                  </div>
                </div>
              </b>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-5">
                <a type="button" className="btn btn-light btn-lg px-4 me-md-2" href="tel:8860396039">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Banner;