import React, { useEffect } from 'react';
import HeaderNavbar from '../Components/Basic/HeaderNavbar';
import Footer from '../Components/Basic/Footer';
import { useNavigate } from "react-router-dom";

function Buyer() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    // nav-buyer-btn
    document.querySelector('.nav-buyer-btn').classList.add('current');
  }, []);

  return (
    <>
      <HeaderNavbar />
      <div className='bg-white buyer-page-wrapper'>
        <div>
          {/* page-title */}
          <section className="page-title centred">
            <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/background/page-title.jpg)' }} />
            <div className="auto-container">
              <div className="content-box">
                <h1>Be a Buyer with us</h1>
                <ul className="bread-crumb clearfix">
                  <li><i className="flaticon-home-1" /><a onClick={() => navigate('/')}>Home</a></li>
                  <li>Buyer</li>
                </ul>
              </div>
            </div>
          </section>
          {/* page-title end */}

          {/* service-page-section */}
          <section className="service-page-section">
            <div className="auto-container">

              {/* Step 1: Register Here */}
              <div className="service-block-two mb-100">
                <div className="inner-box">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 image-column">
                      <figure className="image-box mr-30">
                        <img 
                        src="assets/img/buyer_01.jpg"
                        srcSet="assets/img/buyer_01_min.jpg 300w, assets/img/buyer_01_min.jpg 600w, assets/img/buyer_01.jpg 900w"
                        alt="" />
                      </figure>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                      <div className="content-box ml-30 mr-100">
                        <h2><a >Step 1: Register Here</a></h2>
                        <p>Choose the Brand or Retailer store you want to buy from. Read the terms of purchase and with few simple steps register for the store’s access.</p>
                        <a onClick={() => navigate('/register')} className="theme-btn-one">Register as Buyer Now<i className="flaticon-right-1" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 2: Get Approved */}
              <div className="service-block-two mb-100">
                <div className="inner-box">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                      <div className="content-box text-right ml-100 mr-30">
                        <h2><a >Step 2: Get Approved</a></h2>
                        <p>We will inform you as soon as your registration is approved. Our team will help you with the approval process.</p>
                        <a href="tel:8860396039" className="theme-btn-one">Contact Us<i className="flaticon-right-1" /></a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 image-column order-first order-sm-1">
                      <figure className="image-box mr-30">
                        <img 
                        src="assets/img/buyer_05.jpg" 
                        srcSet="assets/img/buyer_05_min.jpg 300w, assets/img/buyer_05_min.jpg 600w, assets/img/buyer_05.jpg 900w"
                        alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 3: Browse */}
              <div className="service-block-two">
                <div className="inner-box">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 image-column">
                      <figure className="image-box mr-30">
                        <img 
                        src="assets/img/buyer_06.jpg" 
                        srcSet="assets/img/buyer_06_min.jpg 300w, assets/img/buyer_06_min.jpg 600w, assets/img/buyer_06.jpg 900w"
                        alt="" />
                      </figure>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                      <div className="content-box ml-30 mr-100">
                        <h2><a >Step 3: Browse</a></h2>
                        <p>View available inventory from top retailers and brands across conditions and at attractive prices from a variety of categories. You can use our filter for focused browsing.</p>
                        <a onClick={() => navigate('/marketplace')} className="theme-btn-one">Browse Marketplaces<i className="flaticon-right-1" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 4: Bid your offer */}
              <div className="service-block-two mb-100">
                <div className="inner-box">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                      <div className="content-box text-right ml-100 mr-30">
                        <h2><a >Step 4: Bid your offer</a></h2>
                        <p>Place your bid. We will notify you if you have been out-bidded.</p>
                        <p>If you want the inventory to buy right away? Just click on buy now price and we will take the inventory off the auction.</p>
                        <a onClick={() => navigate('/all-lots')} className="theme-btn-one">Browse Auctions<i className="flaticon-right-1" /></a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 image-column order-first order-sm-1">
                      <figure className="image-box mr-30">
                        <img 
                        src="assets/img/buyer_08.jpg" 
                        srcSet="assets/img/buyer_08_min.jpg 300w, assets/img/buyer_08_min.jpg 600w, assets/img/buyer_08.jpg 900w"
                        alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 5: Win the bid */}
              <div className="service-block-two">
                <div className="inner-box">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 image-column">
                      <figure className="image-box mr-30">
                        <img 
                        src="assets/img/buyer_03.jpg" 
                        srcSet="assets/img/buyer_03_min.jpg 300w, assets/img/buyer_03_min.jpg 600w, assets/img/buyer_03.jpg 900w"
                        alt="" />
                      </figure>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                      <div className="content-box ml-30 mr-100">
                        <h2><a >Step 5: Win</a></h2>
                        <p>We will notify you if you have won. You can pay immediately and plan for pickup and Delivery.</p>
                        <a href="tel:8860396039" className="theme-btn-one">Contact Us<i className="flaticon-right-1" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
          {/* service-page-section end */}

          {/* cta-section */}
          <section className="cta-section">
            <div className="image-layer" style={{ backgroundImage: 'linear-gradient(90deg, #0494bc 10%, transparent 110%), url(assets/img/home-banner.png)' }} />
            <div className="auto-container">
              <div className="cta-inner centred">
                <div className="pattern-layer">
                  <div className="pattern-1" style={{ backgroundImage: 'url(assets/images/shape/shape-2.png)' }} />
                  <div className="pattern-2" style={{ backgroundImage: 'url(assets/images/shape/shape-3.png)' }} />
                </div>
                <h2>Place your bids and win amazing lots.</h2>
                <p>Welcome to the new range of liquidation lots. We have over three decades of experience in
                  liquidation industry</p>
                <a onClick={() => navigate('/all-lots')} className="theme-btn-one">Bid Now<i className="flaticon-right-1" /></a>
              </div>
            </div>
          </section>
          {/* cta-section end */}

        </div>

      </div>
      <Footer />
    </>
  );
}

export default Buyer;
