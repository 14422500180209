import { Bars } from "react-loader-spinner";

function Loader() {

    // return (
    //     <Bars
    //         visible={true}
    //         height="80"
    //         width="80"
    //         color="#dc3545"
    //         ariaLabel="bars-loading"
    //         wrapperStyle={{}}
    //         wrapperClass="trader-loader"
    //     />
    // )

    return (
        <div className="loader-wrap">
            <div className="preloader"><div className="preloader-close">Loading...</div></div>
            <div className="layer layer-one"><span className="overlay"></span></div>
            <div className="layer layer-two"><span className="overlay"></span></div>
            <div className="layer layer-three"><span className="overlay"></span></div>
        </div>
    )
}

export default Loader;