import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { hasSomeParentTheClass } from '../Config/config'
import { useAuth } from "../hooks/useAuth";
// import traderLogo from 'https://togofogo.com/assets/img/trader-logo.png';

function Sidebar(props) {
    const [routeState, setRouteState] = useState(0);
    const navigate = useNavigate();
    const { adminUser } = useAuth();

    let clickSideBar = () => {
        let sidebar = document.querySelector(".sidebar");
        let MainSection = document.querySelector(".home-section");
        sidebar.classList.toggle("close");
        MainSection.classList.toggle("closed");
    }

    let toggleMenu = (sectionId) => {
        let arrowParent = document.getElementById(sectionId);
        arrowParent.classList.toggle("showMenu");
    }

    let handleRoute = (e, value, sectionId) => {
        // let arrowParent = document.getElementById(sectionId);
        // const listEl = e.target.closest('li');
        // const subMenuElm = hasSomeParentTheClass(e.target, 'sub-menu');
        // if (subMenuElm) {
        //     arrowParent.classList.add("showMenu");
        //     listEl.classList.add("active");
        // } else {
        //     listEl.classList.add("active");
        // }

        switch (value) {
            case 'D':
                console.warn('Dashboard loaded !');
                navigate("/dashboard");
                setRouteState(value);
                break;
            case 'M':
                console.warn('Marketplaces loaded !');
                navigate("/marketplace-list");
                setRouteState(value);
                break;
            case 'L':
                console.warn('Lots loaded !');
                navigate("/lot-list");
                setRouteState(value);
                break;
            case 'SA':
                console.warn('Super Admin loaded !');
                navigate("/super-admin-list");
                setRouteState(value);
                break;
            case 'A':
                console.warn('Admin loaded !');
                navigate("/admin-list");
                setRouteState(value);
                break;
            case 'B':
                console.warn('Buyer loaded !');
                navigate("/buyer-list");
                setRouteState(value);
                break;
            case 'P':
                console.warn('Payments loaded !');
                navigate("/payments");
                setRouteState(value);
                break;
            case 'DL':
                console.warn('Deliveries loaded !');
                navigate("/deliveries");
                setRouteState(value);
                break;
            case 'SET':
                console.warn('Setting loaded !');
                navigate("/settings");
                setRouteState(value);
                break;
        }
    }

    return (
        <div>
            <div className="sidebar">
                <span onClick={clickSideBar}>
                    <svg className='bx bx-menu' width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.27609 9.25725C5.4368 9.25725 5.57743 9.19699 5.69294 9.0865L9.60031 5.26451C9.73591 5.12891 9.80622 4.97824 9.80622 4.80246C9.80622 4.62667 9.73591 4.46596 9.59528 4.33538L5.68792 0.518415C5.57743 0.407924 5.4368 0.347656 5.27609 0.347656C4.93959 0.347656 4.67341 0.608817 4.67341 0.94029C4.67341 1.101 4.74372 1.2567 4.85924 1.37221L8.37486 4.79743L4.85924 8.2327C4.74372 8.34822 4.67341 8.49888 4.67341 8.66462C4.67341 8.99609 4.93959 9.25725 5.27609 9.25725ZM0.786133 9.25725C0.951869 9.25725 1.08747 9.19699 1.20299 9.0865L5.11035 5.26451C5.25098 5.12891 5.31627 4.97824 5.31627 4.80246C5.31627 4.62667 5.24595 4.46596 5.11035 4.33538L1.20299 0.518415C1.08747 0.407924 0.951869 0.347656 0.786133 0.347656C0.449637 0.347656 0.188477 0.608817 0.188477 0.94029C0.188477 1.101 0.253767 1.2567 0.36928 1.37221L3.88993 4.79743L0.36928 8.2327C0.258789 8.34822 0.188477 8.49888 0.188477 8.66462C0.188477 8.99609 0.449637 9.25725 0.786133 9.25725Z" fill="#FAFAFA" />
                    </svg>
                </span>

                <div className="logo-details logo_name clickable" onClick={() => navigate("/dashboard")}>
                    <img width="180" src="https://togofogo.com/assets/images/logo.png" className="img-icon adcw" alt="" />
                    {/* <span className="logo_name">Trader Admin</span> */}
                </div>
                <div className="logo-details small_logo_name clickable" onClick={() => navigate("/dashboard")}>
                    <img width="40" src="https://togofogo.com/assets/img/trader-logo-small.png" className="img-icon adcw" alt="" />
                    {/* <span className="logo_name">Trader Admin</span> */}
                </div>

                <ul className="nav-links">
                    <li id="dashboardSection" onClick={event => handleRoute(event, 'D', "dashboardSection")}>
                        <a className="sidebar-link">
                            <span className="img-icon awwc">
                                <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.12563 7.44758H9.0489C10.2919 7.44758 10.9172 6.82983 10.9172 5.59434V2.46041C10.9172 1.22492 10.2919 0.607178 9.0489 0.607178H2.12563C0.882603 0.607178 0.257324 1.22492 0.257324 2.46041V5.59434C0.257324 6.82983 0.882603 7.44758 2.12563 7.44758ZM15.0305 13.7832H19.867C21.11 13.7832 21.7353 13.173 21.7353 11.93V3.59044C21.7353 2.35495 21.11 1.74473 19.867 1.74473H15.0305C13.795 1.74473 13.1697 2.35495 13.1697 3.59044V11.93C13.1697 13.173 13.795 13.7832 15.0305 13.7832ZM2.30643 6.03128C1.89209 6.03128 1.67362 5.81281 1.67362 5.38341V2.67135C1.67362 2.23441 1.89209 2.02347 2.30643 2.02347H8.86056C9.28244 2.02347 9.50091 2.23441 9.50091 2.67135V5.38341C9.50091 5.81281 9.28244 6.03128 8.86056 6.03128H2.30643ZM15.2113 12.3745C14.8045 12.3745 14.5785 12.156 14.5785 11.7266V3.80137C14.5785 3.36443 14.8045 3.15349 15.2113 3.15349H19.6786C20.1005 3.15349 20.319 3.36443 20.319 3.80137V11.7266C20.319 12.156 20.1005 12.3745 19.6786 12.3745H15.2113ZM4.01653 14.7551H9.89265C11.1281 14.7551 11.761 14.1448 11.761 12.9018V10.7774C11.761 9.54189 11.1281 8.93168 9.89265 8.93168H4.01653C2.77351 8.93168 2.14823 9.54189 2.14823 10.7774V12.9018C2.14823 14.1448 2.77351 14.7551 4.01653 14.7551ZM4.19734 13.3463C3.78299 13.3463 3.56452 13.1278 3.56452 12.6984V10.9883C3.56452 10.5589 3.78299 10.348 4.19734 10.348H9.70431C10.1187 10.348 10.3447 10.5589 10.3447 10.9883V12.6984C10.3447 13.1278 10.1187 13.3463 9.70431 13.3463H4.19734Z" fill="#FAFAFA" />
                                </svg>

                            </span>
                            <span className="link_name">Overview</span>
                        </a>
                        <ul className="sub-menu blank bg">
                            <li><a className="link_name" >Overview</a></li>
                        </ul>
                    </li>

                    <li id="lotSummarySection">
                        <div className="icon-link" onClick={() => toggleMenu("lotSummarySection")}>
                            <a className="sidebar-link">
                                <span className="img-icon">
                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.40074 13.5175L7.36726 16.8473C7.7816 17.0809 8.20347 17.0809 8.61782 16.8473L14.5843 13.5175C15.3151 13.1107 15.6767 12.6889 15.6767 11.6266V5.51699C15.6767 4.71844 15.3753 4.21369 14.6973 3.82949L9.4013 0.861293C8.47468 0.341483 7.51793 0.341483 6.58378 0.861293L1.28774 3.82949C0.617257 4.21369 0.315918 4.71844 0.315918 5.51699V11.6266C0.315918 12.6889 0.677525 13.1107 1.40074 13.5175ZM11.5257 5.95393L5.9585 2.8426L7.19399 2.14952C7.7364 1.84065 8.24867 1.83311 8.79862 2.14952L13.5447 4.8239L11.5257 5.95393ZM7.99254 7.9277L2.44789 4.8239L4.52713 3.64115L10.0868 6.75248L7.99254 7.9277ZM2.22942 12.3499C1.81508 12.1163 1.67194 11.8828 1.67194 11.4835V6.0142L7.28439 9.17826V15.205L2.22942 12.3499ZM13.7632 12.3499L8.70068 15.205V9.17826L14.3131 6.0142V11.4835C14.3131 11.8828 14.17 12.1163 13.7632 12.3499Z" fill="#FAFAFA" />
                                    </svg>

                                </span>
                                <span className="link_name">Lot Summary</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>
                        <ul className="sub-menu bg">
                            <li><a className="link_name" >Products</a></li>
                            <li id="marketplaceSection" className='clickable' onClick={event => handleRoute(event, 'M', "marketplaceSection")}><a >Marketplaces</a></li>
                            <li id="lotSection" className='clickable' onClick={event => handleRoute(event, 'L', "lotSection")}><a >Lots</a></li>
                        </ul>
                    </li>

                    {adminUser?.role?.toUpperCase() !== 'FINANCE' ? (
                        <li id="userSummarySection">
                            <div className="icon-link" onClick={() => toggleMenu("userSummarySection")}>
                                <a className="sidebar-link">
                                    <span className="img-icon">
                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.00007 7.86935C8.82317 7.86935 10.2997 6.25718 10.2997 4.28341C10.2997 2.33977 8.82317 0.79541 7.00007 0.79541C5.1845 0.79541 3.69287 2.36237 3.7004 4.29848C3.70794 6.26472 5.17697 7.86935 7.00007 7.86935ZM7.00007 6.55099C5.96045 6.55099 5.07903 5.5641 5.07903 4.29848C5.0715 3.06299 5.95292 2.11377 7.00007 2.11377C8.05476 2.11377 8.92111 3.04792 8.92111 4.28341C8.92111 5.54904 8.04722 6.55099 7.00007 6.55099ZM2.29918 14.8755H11.6934C12.9967 14.8755 13.622 14.4611 13.622 13.5722C13.622 11.5005 11.038 8.74323 7.00007 8.74323C2.96212 8.74323 0.370605 11.5005 0.370605 13.5722C0.370605 14.4611 0.995884 14.8755 2.29918 14.8755ZM2.06564 13.5571C1.88484 13.5571 1.81703 13.4969 1.81703 13.3613C1.81703 12.2086 3.6778 10.0616 7.00007 10.0616C10.3148 10.0616 12.1756 12.2086 12.1756 13.3613C12.1756 13.4969 12.1078 13.5571 11.927 13.5571H2.06564Z" fill="#FAFAFA" />
                                        </svg>
                                    </span>

                                    <span className="link_name">User Summary</span>
                                </a>
                                <i className='bx bxs-chevron-down arrow'></i>
                            </div>
                            <ul className="sub-menu bg">
                                <li><a className="link_name" >Products</a></li>
                                {adminUser?.role?.toUpperCase() === 'SUPERADMIN' ? (
                                    <li id="adminSection" className='clickable' onClick={event => handleRoute(event, 'A', "adminSection")}><a >Admin List</a></li>
                                ) : ('')}
                                <li id="buyerSection" className='clickable' onClick={event => handleRoute(event, 'B', "buyerSection")}><a >Buyer List</a></li>
                            </ul>
                        </li>
                    ) : ('')}

                    <li id="paymentsSection" onClick={event => handleRoute(event, 'P', "paymentsSection")}>
                        <a className="sidebar-link">
                            <span className="img-icon awwc">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.875 16.124L3.75 16.124" stroke="#FAFAFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.25 16.124L17.625 16.124" stroke="#FAFAFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.75 17.999C16.7855 17.999 17.625 17.1596 17.625 16.124C17.625 15.0885 16.7855 14.249 15.75 14.249C14.7145 14.249 13.875 15.0885 13.875 16.124C13.875 17.1596 14.7145 17.999 15.75 17.999Z" stroke="#FAFAFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.875 7.8741L3.75 7.87402" stroke="#FAFAFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.25 7.87402L11.625 7.8741" stroke="#FAFAFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.75 9.74902C10.7855 9.74902 11.625 8.90956 11.625 7.87402C11.625 6.83849 10.7855 5.99902 9.75 5.99902C8.71447 5.99902 7.875 6.83849 7.875 7.87402C7.875 8.90956 8.71447 9.74902 9.75 9.74902Z" stroke="#FAFAFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </span>
                            <span className="link_name">Payments</span>
                        </a>
                        <ul className="sub-menu blank bg">
                            <li><a className="link_name" >Payments</a></li>
                        </ul>
                    </li>

                    {/* {adminUser?.role?.toUpperCase() !== 'FINANCE' ? ( */}
                    <li id="dispatchSection" onClick={event => handleRoute(event, 'DL', "dispatchSection")}>
                        <a className="sidebar-link">
                            <span className="img-icon awwc">
                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.20515 11.9902H14.9741C15.3282 11.9902 15.6521 11.7115 15.6521 11.3122C15.6521 10.9204 15.3282 10.6417 14.9741 10.6417H6.37842C6.01681 10.6417 5.79081 10.3931 5.73807 10.0089L5.62507 9.22541H15.0344C16.187 9.22541 16.7972 8.5248 16.963 7.37971L17.528 3.60543C17.543 3.5075 17.5581 3.37943 17.5581 3.29656C17.5581 2.85209 17.2492 2.55075 16.7294 2.55075H4.65325L4.54025 1.73713C4.44231 1.08172 4.17864 0.750244 3.34242 0.750244H0.75844C0.381766 0.750244 0.050293 1.08172 0.050293 1.46592C0.050293 1.85767 0.381766 2.18914 0.75844 2.18914H3.11642L4.28411 10.1596C4.44985 11.2971 5.05253 11.9902 6.20515 11.9902ZM15.9911 3.89924L15.5165 7.24411C15.4563 7.62831 15.2529 7.86939 14.8837 7.86939L5.4292 7.87692L4.84912 3.89924H15.9911ZM6.82289 15.772C7.55364 15.772 8.14125 15.1844 8.14125 14.4536C8.14125 13.7229 7.55364 13.1353 6.82289 13.1353C6.09215 13.1353 5.50453 13.7229 5.50453 14.4536C5.50453 15.1844 6.09215 15.772 6.82289 15.772ZM13.7838 15.772C14.5146 15.772 15.0947 15.1844 15.0947 14.4536C15.0947 13.7229 14.5146 13.1353 13.7838 13.1353C13.0531 13.1353 12.4579 13.7229 12.4579 14.4536C12.4579 15.1844 13.0531 15.772 13.7838 15.772Z" fill="#FAFAFA" />
                                </svg>
                            </span>

                            <span className="link_name">Deliveries</span>
                        </a>
                        <ul className="sub-menu blank bg">
                            <li><a className="link_name" >Deliveries</a></li>
                        </ul>
                    </li>
                    {/* ) : ('')} */}

                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
