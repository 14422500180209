import React, { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

/**
 * Confirmation modal component.
 * 
 * @param {Object} props - The component props.
 * @param {Function} props.submitConfirmationSetter - The function to set the submit confirmation state.
 * @param {Function} props.showConfirmationSetter - The function to set the show confirmation state.
 * @param {boolean} props.show - The flag to determine if the modal should be shown.
 * @param {string} props.message - The message to display in the modal body.
 * @returns {JSX.Element} The confirmation modal component.
 */
function ConfirmationModal({ submitConfirmationSetter, showConfirmationSetter, show, message }) {
    const showModalProp = show || false;

    const [showModal, setShowModal] = useState(false);

    /**
     * Handles the close event of the modal.
     */
    const handleClose = () => {
        setShowModal(false);
        showConfirmationSetter(false);
    }

    /**
     * Handles the submit event of the modal.
     */
    const handleSubmit = () => {
        setShowModal(false);
        showConfirmationSetter(false);
        submitConfirmationSetter(false);
    }

    /**
     * Handles the show event of the modal.
     */
    const handleShow = () => setShowModal(true);

    useEffect(() => {
        if (showModalProp) {
            handleShow();
        }
    }, [showModalProp]);

    return (
        <>
            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className='confirmation-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <img
                            alt=""
                            src="https://togofogo.com/assets/images/logo.png"
                            width="150"
                            className="d-inline-block align-top toaster-logo"
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message || 'Are you sure you want to do this?'}
                </Modal.Body>
                <Modal.Footer>
                    <a className='theme-btn-one cancel-modal-btn' onClick={handleClose}>
                        Cancel
                    </a>
                    <a className='theme-btn-one yes-modal-btn' onClick={handleSubmit}>Yes</a>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConfirmationModal;
