import { createContext, useContext, useMemo } from "react";
import axios from "axios";
import { useAuth, logout } from "./useAuth";

const ServiceContext = createContext();

export const ServiceProvider = ({ children }) => {
  axios.defaults.baseURL = process.env.REACT_APP_CLIENT_HOST || 'https://admin.togofogo.com';
  const { user, adminUser, logout } = useAuth();

  // Api calls

  /**
   * Trader APIs
   * -----------
   */

  async function buyerSignup(payload) {
    try {
      const response = await axios.post(`/auth/signup/BUYER`, payload);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function resetPassword(payload) {
    try {
      const response = await axios.post(`/auth/reset`, payload);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function userLogin(payload) {
    try {
      const response = await axios.post(`/auth/signin/BUYER`, payload);
      if (response && response.status === 200) {
        const result = {
          loggedIn: true,
          data: response.data,
          token: response.headers['client-secret'],
          role: response.headers['Trader-Role'] || response.headers['trader-role'],
          uid: response.headers['User-Id'] || response.headers['user-id']
        };
        return result;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function validateToken(accessToken) {
    try {
      const response = await axios.post(`/auth/check`, {}, {
        headers: {

          'Client-Secret': accessToken
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function marketplaceList(utype, currentPage = 1, limit = 10, search = '') {
    const apiUrl = `/buyer/getAllMarketplaces/${utype}?page=${currentPage}&limit=${limit}&search=${search}`;

    try {
      const response = await axios.get(apiUrl);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        const role = utype === "ADMIN" || utype === "SUPERADMIN" || utype === "FINANCE" ? adminUser.role : user?.role;
        logout(role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function adminMarketplaceList(utype, currentPage = 1, limit = 10, search = '') {
    const apiUrl = `/admin/getAdminMarketplaces?page=${currentPage}&limit=${limit}&search=${search}`;

    try {
      const response = await axios.get(apiUrl);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        const role = utype === "ADMIN" || utype === "SUPERADMIN" || utype === "FINANCE" ? adminUser.role : user?.role;
        logout(role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function lotList(mpid, currentPage = 1, limit = 10, search = '') {
    const apiUrl = `/buyer/getLotList/${mpid}?page=${currentPage}&limit=${limit}&search=${search}`;

    try {
      const response = await axios.get(apiUrl);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(user?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getBidHistory(uid) {
    const apiUrl = `/buyer/getUserBids/${uid}`;

    try {
      const response = await axios.get(apiUrl);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(user?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getWinningLots(uid) {
    const apiUrl = `/buyer/getUserWinnings/${uid}`;

    try {
      const response = await axios.get(apiUrl);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(user?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getActiveUserLots(uid) {
    const apiUrl = `/buyer/getUserActiveLots/${uid}`;

    try {
      const response = await axios.get(apiUrl);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(user?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getlotDetails(lotid) {
    const apiUrl = `/buyer/getLotDetails/${lotid}`;

    try {
      const response = await axios.get(apiUrl);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(user?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function productList(lotid, utype, currentPage = 1, limit = 10) {
    const apiUrl = `/buyer/getProductsForLot/${utype}/${lotid}?page=${currentPage}&limit=${limit}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {

          'Client-Secret': utype === "ADMIN" || utype === "SUPERADMIN" || utype === "FINANCE" ? adminUser.token : user?.token
        }
      });

      if (response && response.status === 200) {
        return { status: response.status, code: 'SUCCESS', results: response.data };
      } else if (response && response.status === 401) {
        return { status: response.status, code: 'NOT_AUTHORIZED', results: null };
      } else if (response && response.status === 440) {
        return { status: response.status, code: 'SESSION_EXPIRED', results: null };
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response && e.response.status === 401) {
        return { status: 401, code: 'NOT_AUTHORIZED', results: null };
      }
      if (e?.response && e.response.status === 440) {
        const role = utype === "ADMIN" || utype === "SUPERADMIN" || utype === "FINANCE" ? adminUser.role : user?.role;
        logout(role);
        return { status: 440, code: 'SESSION_EXPIRED', results: null };
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getTopBrandsAndCategories(lotid) {
    const apiUrl = `/buyer/getTopBrandsAndCategories/${lotid}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {

          'Client-Secret': user?.token
        }
      });

      if (response && response.status === 200) {
        return { status: response.status, code: 'SUCCESS', results: response.data };
      } else if (response && response.status === 401) {
        return { status: response.status, code: 'NOT_AUTHORIZED', results: null };
      } else if (response && response.status === 440) {
        return { status: response.status, code: 'SESSION_EXPIRED', results: null };
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response && e.response.status === 401) {
        return { status: 401, code: 'NOT_AUTHORIZED', results: null };
      }
      if (e?.response && e.response.status === 440) {
        logout(user?.role);
        return { status: 440, code: 'SESSION_EXPIRED', results: null };
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function placeABid(payload) {
    const userRole = user?.role || 'BUYER';
    try {
      const response = await axios.post(`/bid/placeABidWithTimeCheck/${userRole}`, payload, {
        headers: {
          'Client-Secret': user?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(user?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function buyNowLot(payload) {
    const userRole = user?.role || 'BUYER';
    try {
      const response = await axios.post(`/bid/buyNowLot/${userRole}`, payload, {
        headers: {
          'Client-Secret': user?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(user?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function checkBidStatus(lotid) {
    try {
      const response = await axios.post(`/bid/checkBidStatus/${lotid}/${user?.uid}`, {}, {
        headers: {
          'Client-Secret': user?.token
        }
      });
      if (response && response.status === 200) {
        return { status: response.status, code: 'SUCCESS', results: response.data };
      } else if (response && response.status === 401) {
        return { status: response.status, code: 'NOT_AUTHORIZED', results: null };
      } else if (response && response.status === 440) {
        return { status: response.status, code: 'SESSION_EXPIRED', results: null };
      } else {
        return { status: 400, code: 'SESSION_EXPIRED', results: null };
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(user?.role);
      }
      return { status: e?.response?.status || 400, code: 'SESSION_EXPIRED', results: null };
    }
  }

  /**
 * ADMIN
 **/

  async function adminSignup(payload) {
    try {
      const response = await axios.post(`/auth/signup/${payload?.utype || 'ADMIN'}`, payload);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function adminLogin(payload) {
    try {
      const response = await axios.post(`/auth/signin/ADMIN`, payload);
      if (response && response.status === 200) {
        const result = {
          loggedIn: true,
          data: response.data,
          token: response.headers['client-secret'],
          uid: response.headers['User-Id'] || response.headers['user-id'],
          role: response.headers['Trader-Role'] || response.headers['trader-role']
        };
        return result;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getDashboardMatrics() {
    try {
      const response = await axios.get(`/admin/getDashboardMatrics`, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getCategoriesChartData() {
    try {
      const response = await axios.get(`/admin/getCategoriesChartData`, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getLocationsChartData() {
    try {
      const response = await axios.get(`/admin/getLocationsChartData`, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getBidsChartData() {
    try {
      const response = await axios.get(`/admin/getBidsChartData`, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function createNewMarketplace(payload, utype, uid) {

    try {
      const response = await axios.post(`/buyer/createMarketplace/${utype}/${uid}`, payload, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function updateMarketplace(payload, utype, mpid) {

    try {
      const response = await axios.put(`/buyer/updateMarketplace/${utype}/${mpid}`, payload, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function bidsList(status, currentPage = 1, limit = 10) {
    const apiUrl = `/admin/getAllBids/${status}?page=${currentPage}&limit=${limit}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });

      if (response && response.status === 200) {
        return { status: response.status, code: 'SUCCESS', results: response.data };
      } else if (response && response.status === 401) {
        return { status: response.status, code: 'NOT_AUTHORIZED', results: null };
      } else if (response && response.status === 440) {
        return { status: response.status, code: 'SESSION_EXPIRED', results: null };
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response && e.response.status === 401) {
        return { status: 401, code: 'NOT_AUTHORIZED', results: null };
      }
      if (e?.response && e.response.status === 440) {
        logout(adminUser?.role);
        return { status: 440, code: 'SESSION_EXPIRED', results: null };
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function soldLots(currentPage = 1, limit = 10, search = '') {
    const apiUrl = `/admin/getSoldLots?page=${currentPage}&limit=${limit}&search=${search}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });

      if (response && response.status === 200) {
        return { status: response.status, code: 'SUCCESS', results: response.data };
      } else if (response && response.status === 401) {
        return { status: response.status, code: 'NOT_AUTHORIZED', results: null };
      } else if (response && response.status === 440) {
        return { status: response.status, code: 'SESSION_EXPIRED', results: null };
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response && e.response.status === 401) {
        return { status: 401, code: 'NOT_AUTHORIZED', results: null };
      }
      if (e?.response && e.response.status === 440) {
        logout(adminUser?.role);
        return { status: 440, code: 'SESSION_EXPIRED', results: null };
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getSubcategories() {
    const apiUrl = `/admin/getCategoriesList`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getUserList(utype) {
    const apiUrl = `/admin/getUsers/${utype}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getUserDetails(uid) {
    const apiUrl = `/admin/getUserDetails/${uid}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(user?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function updateUserDetails(payload, uid) {
    try {
      const response = await axios.put(`/admin/updateUser/${uid}`, payload, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function uploadDocuments(gstFile, panFile, adhaarFile, uid) {
    const formData = new FormData();
    if (gstFile) {
      formData.append("gst", gstFile);
    }
    if (panFile) {
      formData.append("pan", panFile);
    }
    if (adhaarFile) {
      formData.append("aadhaar", adhaarFile);
    }

    try {
      const response = await axios.post(`/admin/uploadDocuments/${uid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Client-Secret': adminUser?.token
        }
      });

      if (response && response.status === 200) {
        return { status: response.status, code: 'SUCCESS', results: response.data };
      } else if (response && response.status === 401) {
        return { status: response.status, code: 'NOT_AUTHORIZED', results: null };
      } else if (response && response.status === 440) {
        return { status: response.status, code: 'SESSION_EXPIRED', results: null };
      } else {
        return { status: response.status, code: 'ERRORS', results: response.data };
      }

      // if (response && response.status === 200 || response && response.status === 201) {
      //   return response.data;
      // }
      // else {
      //   throw response;
      // }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function updateLotDetails(payload, lotid) {
    try {
      const response = await axios.put(`/buyer/updateLotsForMarketplace/ADMIN/${lotid}`, payload, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function deleteMarketplace(mpid) {
    try {
      const response = await axios.delete(`/buyer/deleteMarketplace/${mpid}`, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function deleteLot(lotid) {
    try {
      const response = await axios.delete(`/buyer/deleteLotsForMarketplace/ADMIN/${lotid}`, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function publishNewLot(payload, lotid) {
    try {
      const response = await axios.put(`/buyer/publishLot/ADMIN/${lotid}`, payload, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function unpublishedLotList(currentPage = 1, limit = 10, search = '') {
    const apiUrl = `/buyer/getUnPublishedLotList?page=${currentPage}&limit=${limit}&search=${search}`;

    try {
      const response = await axios.get(apiUrl);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function importLots(file, mpid) {
    const formData = new FormData();
    formData.append("import_csv", file);

    try {
      const response = await axios.post(`/buyer/uploadBulkLotsForMarketplace/${mpid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Client-Secret': adminUser?.token
        }
      });

      if (response && response.status === 200) {
        return { status: response.status, code: 'SUCCESS', results: response.data };
      } else if (response && response.status === 401) {
        return { status: response.status, code: 'NOT_AUTHORIZED', results: null };
      } else if (response && response.status === 440) {
        return { status: response.status, code: 'SESSION_EXPIRED', results: null };
      } else {
        return { status: response.status, code: 'ERRORS', results: response.data };
      }

      // if (response && response.status === 200 || response && response.status === 201) {
      //   return response.data;
      // }
      // else {
      //   throw response;
      // }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function manageAccessToUsers(uid, access) {
    const userRole = adminUser?.role || 'ADMIN';

    try {
      const response = await axios.put(`/admin/revokeAccess/${userRole}/${uid}/${access}`, {}, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function AuthorizeToUsers(uid) {
    const userRole = adminUser?.role || 'ADMIN';

    try {
      const response = await axios.put(`/admin/approveUserRole/${userRole}/${uid}`, {}, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getPayments() {
    const apiUrl = `/admin/getPayments`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getDeliveries() {
    const apiUrl = `/admin/getDeliveries`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {

          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function updatePaymentStatus(lotid, payload) {

    try {
      const response = await axios.put(`/admin/updatePaymentStatus/${lotid}`, payload, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function updateDeliveryStatus(lotid, payload) {

    try {
      const response = await axios.put(`/admin/updateDeliveryStatus/${lotid}`, payload, {
        headers: {
          'Client-Secret': adminUser?.token
        }
      });
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      if (e?.response?.status === 440) {
        logout(adminUser?.role);
      }
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function getAnalyticData() {
    try {
      const response = await axios.get(`/admin/track`);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  async function saveAnalyticData(payload) {
    try {
      const response = await axios.post(`/admin/save`, payload);
      if (response && response.status === 200) {
        return response.data;
      }
      else {
        throw response;
      }
    }
    catch (e) {
      console.log(e?.response?.data?.error || 'Something went wrong!');
      throw e;
    }
  }

  const value = useMemo(
    () => ({
      buyerSignup,
      adminLogin,
      adminSignup,
      userLogin,
      validateToken,
      resetPassword,
      marketplaceList,
      adminMarketplaceList,
      lotList,
      getBidHistory,
      getSubcategories,
      unpublishedLotList,
      updateLotDetails,
      publishNewLot,
      importLots,
      deleteMarketplace,
      deleteLot,
      getlotDetails,
      productList,
      bidsList,
      placeABid,
      buyNowLot,
      checkBidStatus,
      getDashboardMatrics,
      getCategoriesChartData,
      getLocationsChartData,
      getBidsChartData,
      getTopBrandsAndCategories,
      getUserList,
      getUserDetails,
      uploadDocuments,
      updateUserDetails,
      manageAccessToUsers,
      AuthorizeToUsers,
      getPayments,
      getDeliveries,
      updatePaymentStatus,
      updateDeliveryStatus,
      updateMarketplace,
      createNewMarketplace,
      getWinningLots,
      getActiveUserLots,
      soldLots,
      saveAnalyticData,
      getAnalyticData
    }),
    [user, adminUser]
  );
  return <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>;
};

export const useService = () => {
  return useContext(ServiceContext);
};