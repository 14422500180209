import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProtectedRoute = ({ children }) => {
  const { user, adminUser } = useAuth();
  if (!adminUser || adminUser && (adminUser?.role?.toUpperCase() !== "ADMIN" && adminUser?.role?.toUpperCase() !== "SUPERADMIN" && adminUser?.role?.toUpperCase() !== "FINANCE")) {
    // user is not authenticated
    return <Navigate to="/admin" />;
  }
  return children;
};

export const ProtectedBuyerRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user || user && (user?.role?.toUpperCase() !== "BUYER")) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};

export const SuperAdminProtectedRoute = ({ children }) => {
  const { user, adminUser } = useAuth();
  if (!adminUser || adminUser && adminUser?.role?.toUpperCase() !== "SUPERADMIN") {
    // user is not authenticated
    return <Navigate to="/dashboard" />;
  }
  return children;
};