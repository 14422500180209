import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Toaster from "../Basic/Toaster";
import ReactTooltip from 'react-tooltip';
import { useAuth } from "../hooks/useAuth";
import { useService } from "../hooks/useService";
import Login from '../Onboarding/Login';
import ListPagination from '../Basic/Pagination';

function AdminProductList(props) {
    const navigate = useNavigate();
    const { adminUser } = useAuth();
    const { productList } = useService();

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');

    const [productsData, setProductsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const lotid = props?.lot;
    const mpid = props?.mp;

    const getProductList = (lotid, currentPage) => {
        productList(lotid, "ADMIN", currentPage, 10)
            .then(response => {
                if (response && response.status === 200) {
                    const productsList = response?.results?.data || [];
                    setTotalPages(response?.results?.totalPages || 1);
                    setProductsData(productsList);
                }
            });
    }

    const _handleProductRows = (list) => {
        return (
            <tbody>
                {list.map((item, index) => (
                    <tr className="gry-tred" key={index}>
                        <td className="body-title text-center"><img width="80px" src={item?.products?.image_urls?.[0] || './../assets/img/products.jpeg'} alt="" /></td>
                        <td className="body-title">{item?.products?.description}</td>
                        <td className="body-title">{item?.products?.brand}</td>
                        <td className="body-title text-center">{item?.products?.category}</td>
                        <td className="body-title text-center">{item?.products?.mrp}</td>
                        <td className="body-title text-center">{item?.products?.quantity}</td>
                    </tr>
                ))}
            </tbody>
        )
    }

    useEffect(() => {
        if (lotid && adminUser && adminUser.isLoggedIn) {
            getProductList(lotid, currentPage);
        }
    }, [currentPage, lotid, adminUser]);

    return (
        <>
            <section className="sec-1">
                <div className="Rule-page">
                    <div className="main-jaste">
                        <div className="mi">
                            <h1 className="left-title">Products</h1>
                        </div>
                    </div>
                    <div className="wihte-erya p-0">
                        <table className="w-100 m-0">
                            <thead>
                                <tr className="gry-tr h-74">
                                    <td className="head-title padin-24">Stock Image</td>
                                    <td className="head-title padin-24">Description</td>
                                    <td className="head-title padin-24">Brand</td>
                                    <td className="head-title padin-24 text-center">Category</td>
                                    <td className="head-title padin-24 text-center">MRP</td>
                                    <td className="head-title padin-24 text-center">Quantity</td>
                                </tr>
                            </thead>
                            {productsData.length !== 0 &&
                                _handleProductRows(productsData)
                            }
                        </table>
                        {productsData.length === 0 &&
                            <div className="space-26px">
                                {/* <p className="create-oneedw">You haven't got any products in the lot yet, <a className="add-custm-btn" data-bs-toggle="modal" data-bs-target="#Addproduct"> create one</a></p> */}
                                <p className="create-oneedw">You haven't got any products in the lot yet!</p>
                            </div>
                        }
                        <ListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                </div>
            </section>

            <ReactTooltip html={true} className="tooltip" />
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </>
    );
}

export default AdminProductList;
