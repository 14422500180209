import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
// import { userLogin } from '../Services/UserService';
import { validateInput, content } from '../Config/config';
import Toaster from "../Basic/Toaster";
import { useAuth } from "../hooks/useAuth";
import { useService } from "../hooks/useService";
// import traderLogo from 'https://togofogo.com/assets/img/trader-logo.png';

/**
 * Renders the AdminLogin component.
 *
 * @component
 * @example
 * return (
 *   <AdminLogin />
 * )
 */
function AdminLogin(props) {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { adminLogin } = useService();

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [showPassword, setShowPassword] = useState('password');

    const handleLogin = () => {
        let email = document.getElementById('userEmail');
        let password = document.getElementById('userPass');

        let checkEmail = validateInput(email, content().emailErrorMessage);
        let checkPassword = validateInput(password, content().passwordErrorMessage);

        if (checkEmail && checkPassword) {
            adminLogin({ "email": email.value, "password": password.value })
                .then(async response => {
                    setShowToaster(false);
                    if (response.loggedIn) {
                        // Set user data
                        const accessToken = response.token;
                        const role = response.role;
                        const uid = response.uid;
                        await login({ isLoggedIn: true, token: accessToken, email: email.value, role: role, uid: uid });

                        navigate("/dashboard");
                    } else {
                        console.log('error_', response);
                    }
                })
                .catch(error => {
                    if (error && error.response && error.response.data) {
                        if (error.response.status === 400) {
                            setShowToaster(true);
                            setToasterMessage(error.response.data.message);
                        } else {
                            setShowToaster(true);
                            setToasterMessage(error.response.data.message);
                        }
                    }
                });
        }
    }

    const handleVisiblePass = () => {
        const password = document.getElementById('userPass');

        if (password.type === "password") {
            password.type = "text";
            setShowPassword("text");
        } else {
            password.type = "password";
            setShowPassword("password");
        }
    }

    return (
        <div className="main-parant-1">
            <section className="sec-1">
                <div className="main-width">
                    <div className="main-box">
                        <div className="main-toggel-grid">
                            <div>
                                <div className="logo-details">
                                    <img width="180" src="https://togofogo.com/assets/images/logo.png" className="img-icon adcw" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="email-box">
                            <div className="input-dpadcf">
                                <div className="email-gird">
                                    <p className="main-pra-text">Email</p>
                                    <input type="email" id="userEmail" className="main-input" placeholder="Enter your work email address" required />
                                </div>
                                <div className="email-gird">
                                    <p className="main-pra-text">Password</p>
                                    <div className="porelet">
                                        <input type="password" id="userPass" className="main-input" placeholder="Enter password" required />
                                        <img src="assets/img/eye.fill.svg" className="eys-ic" alt="" />

                                        {/* <!-- show password ic --> */}
                                        {showPassword === "password" && (<img src="assets/img/eye.slash.fill.svg" className="eys-ic" alt="" onClick={handleVisiblePass} />)}
                                        {showPassword === "text" && (<img src="assets/img/eye.fill.svg" className="eys-ic" alt="" onClick={handleVisiblePass} />)}

                                    </div>
                                </div>
                            </div>
                            <div className="two-btn">
                                <div>
                                    <a className="theme-btn-one login-btn" onClick={handleLogin} >LOG IN</a>

                                    {/* <!-- grey btn second page -->
                                <!-- <a href="" className="GET-STARTED blak">LOG IN</a> --> */}
                                </div>
                                <div>
                                    <p className="Already-pra">Want to visit Buyer Panel?<a className="LOG-iN-btn" onClick={() => navigate("/")}> CLICK HERE</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </div>
    );
}

export default AdminLogin;
