import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useAuth } from "../hooks/useAuth";

function PageNavigation({ page, pageid, nav }) {
    const navigate = useNavigate();
    const { user, adminUser } = useAuth();

    const handleHomeNavigation = () => {

        if (nav && nav === 'buyer') {
            navigate(`/marketplace`);
        } else {
            if (user && user?.role?.toUpperCase() === "ADMIN" || user?.role?.toUpperCase() === "SUPERADMIN" || user?.role?.toUpperCase() === "FINANCE") {
                navigate(`/dashboard`);
            } else {
                navigate(`/`);
            }
        }
    };

    return (
        <>
            {nav === "admin" ? (
                <Breadcrumb className="admin-page-nav">
                    <Breadcrumb.Item onClick={() => navigate(`/dashboard`)}>Home</Breadcrumb.Item>
                    {page === 'lot-details' ? (<Breadcrumb.Item onClick={() => navigate(`/lot-list`)}>Lots List</Breadcrumb.Item>) : ''}
                    {page === 'lot-details' || page === 'lot' ? (<Breadcrumb.Item active>{page === 'lot-details' ? 'Lot Details' : 'Lots List'}</Breadcrumb.Item>) : ''}
                    {page === 'buyer-info' ? (<Breadcrumb.Item onClick={() => navigate(`/buyer-list`)}>Buyer List</Breadcrumb.Item>) : ''}
                    {page === 'buyer-info' || page === 'lot' ? (<Breadcrumb.Item active>{page === 'buyer-info' ? 'Buyer Details' : 'Buyer List'}</Breadcrumb.Item>) : ''}
                </Breadcrumb>
            ) : (
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => handleHomeNavigation()}>Home</Breadcrumb.Item>
                    {page === 'lot-details' ? (<Breadcrumb.Item onClick={() => navigate(`/lot/${pageid}`)}>Lots List</Breadcrumb.Item>) : ''}
                    {page === 'lot-details' || page === 'lot' ? (<Breadcrumb.Item active>{page === 'lot-details' ? 'Lot Details' : 'Lots List'}</Breadcrumb.Item>) : ''}
                </Breadcrumb>
            )}
        </>
    );
}

export default PageNavigation;
