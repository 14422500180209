import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useService } from "../hooks/useService";
import { formatCurrency } from '../Config/config';
import { useStore } from "../context/StoreProvider";

import ListPagination from '../Basic/Pagination';
import Toaster from "../Basic/Toaster";
import Loader from '../Basic/Loader';
import BidCounter from '../Basic/BidCounter';

function Lot(props) {
    const navigate = useNavigate();
    let { mpid } = useParams();
    const { lotList } = useService();
    const { selectedFilters, savedlotList, setSavedlotList, setFiltersList } = useStore();

    const lotType = props.type;
    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [lotData, setLotData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    const getLotList = (currentPage) => {
        setLoading(true);
        const payloadId = lotType == 'all' ? lotType : mpid;
        lotList(payloadId, currentPage, props.maxCount)
            .then(response => {
                if (response && response.data && response.data.length) {
                    setTotalPages(response?.totalPages || 1);
                    setLotData(response.data);
                    setSavedlotList(response.data);
                    setFilterDataHandler(response.data);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const setFilterDataHandler = (lots) => {
        const filterListData = {
            sub_categories: [],
            condition: [],
            location: []
        };

        lots.forEach(lot => {
            if (lot?.sub_categories) {
                // filterListData.sub_categories.push(lot.sub_categories);
                filterListData.sub_categories = [...filterListData.sub_categories, ...lot.sub_categories];
            }

            if (lot?.storage_location) {
                filterListData.location.push(lot.storage_location);
            }

            if (lot?.grade_name) {
                filterListData.condition.push(lot.grade_name);
            }
        });

        const uniqueSubCategories = [...new Set(filterListData.sub_categories)];
        const uniqueLocations = [...new Set(filterListData.location)];
        const uniqueConditions = [...new Set(filterListData.condition)];

        const filters = {
            sub_categories: uniqueSubCategories,
            location: uniqueLocations,
            condition: uniqueConditions
        };
        setFiltersList(filters);
    };

    const renderLotListTemplate = (lotData) => {

        return (
            <div className="items-container row clearfix">
                {lotData.map((item, index) => (
                    <div
                        className="col-lg-3 col-md-6 col-sm-12 shop-block masonry-item small-column best_seller"
                        key={index}
                        onClick={() => navigate(`/lot-details/${item.lotid}`, { state: { mpid: mpid } })}
                    >
                        <div className="shop-block-one">
                            <div className="inner-box">
                                <figure className="image-box">
                                    <img src={item?.lot_image_urls?.[0] || './../assets/img/no_image.jpg'} alt="" />
                                    {props.showNewTag && <span className="category green-bg">Hot</span>}
                                    <ul className="info-list clearfix">
                                        <li><a hidden onClick={() => navigate(`/lot-details/${item.lotid}`, { state: { mpid: mpid } })} style={{ backgroundColor: 'transparent' }}><i className="flaticon-cursor" /></a></li>
                                        <li><a onClick={() => navigate(`/lot-details/${item.lotid}`, { state: { mpid: mpid } })}>{item?.marketplace_info?.name}</a></li>
                                        <li><a hidden href="assets/images/resource/shop/shop-1.jpg" className="lightbox-image" data-fancybox="gallery"><i className="flaticon-search" /></a></li>
                                    </ul>
                                </figure>
                                <div className="lower-content">
                                    <div className='list-bid-counter-wrapper'>
                                        <BidCounter bid-start={item.bid_start_date} bid-end={item.bid_end_date} showLabel={false} />
                                    </div>
                                    <a onClick={() => navigate(`/lot-details/${item.lotid}`, { state: { mpid: mpid } })}>{item.lot_name}</a>
                                    <div className="card-price">
                                        <span className="price"><span>MRP: </span>{formatCurrency(item.mrp)}</span>
                                        <span className="price"><span>Quantity: </span>{item.items_count}</span>
                                    </div>
                                    <span className="price"><span>Floor Price: </span>{formatCurrency(item.floor_price)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {props.disablePagination ? '' : <ListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
            </div>

        )
    };

    const handleFilterChange = () => {
        let filteredData = savedlotList;

        if (selectedFilters && Object.keys(selectedFilters).length) {
            if (selectedFilters['Sub Categories']?.length) {
                filteredData = filteredData.filter(lot => selectedFilters['Sub Categories'].includes(lot.sub_categories));
            }

            if (selectedFilters['Location']?.length) {
                filteredData = filteredData.filter(lot => selectedFilters['Location'].includes(lot.storage_location));
            }

            if (selectedFilters['Condition']?.length) {
                filteredData = filteredData.filter(lot => selectedFilters['Condition'].includes(lot.grade_name));
            }

            if (selectedFilters['Status']?.length) {
                filteredData = filteredData.filter(lot => {
                    if (selectedFilters['Status'].includes("Active") && lot.status.toLowerCase() === "active") {
                        return true;
                    } else if (selectedFilters['Status'].includes("Expired") && lot.status.toLowerCase() !== 'active') {
                        return true;
                    }
                    return false;
                });
            }

            if (selectedFilters['Price']) {
                filteredData = filteredData.filter(lot => {
                    return lot.mrp <= selectedFilters['Price'];
                });
            }

            setLotData(filteredData);
        } else {
            setLotData(savedlotList);
        }
    };

    useEffect(() => {
        handleFilterChange();
    }, [selectedFilters]);

    useEffect(() => {
        getLotList(currentPage);
    }, [mpid, currentPage]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>

                    <Container>
                        <div className='buyer-lot-list-wrapper'>
                            <div className="Rule-page">
                                {lotData.length ? renderLotListTemplate(lotData) : ''}
                            </div>
                        </div>
                    </Container>
                </>
            )}
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </>
    );
}

export default Lot;
