import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { useAuth } from "../hooks/useAuth";
import { useService } from "../hooks/useService";
import { formatCurrency } from '../Config/config';
import ListPagination from '../Basic/Pagination';
import Chart from "chart.js/auto";
import { PolarArea, Scatter, Line, Bar, Doughnut } from "react-chartjs-2";

function AnalyticsDashboard(props) {
    const navigate = useNavigate();
    const { getAnalyticData } = useService();

    const chartOptions = {
        plugins: {
            legend: {
                "display": true,
                "position": "bottom",
                "align": "start"
            }
        }
    };

    const [loading, setLoading] = useState(false);
    const [dashboardMatricsVal, setDashboardMatrics] = useState({});
    const [categoriesChart, setCategoriesChartdData] = useState({});
    const [locationChart, setLocationChartData] = useState({});
    const [bidChart, setBidChartData] = useState({});

    const dashboardMatrics = async () => {
        getAnalyticData()
            .then(response => {
                if (response && response.data) {
                    setDashboardMatrics(response.data);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
            });
    };

    const trackPageView = () => {
        const data = {
            url: window.location.href,
            referrer: document.referrer,
            userAgent: navigator.userAgent,
            timestamp: new Date().toISOString(),
        };

        navigator.sendBeacon('/track', JSON.stringify(data));
    }

    // trackPageView();

    const _CategoriesChartWrapper = () => {
        const categoriesData = categoriesChart.lotsByCategory;
        const data = {
            labels: categoriesData.map(item => item.parent_category),
            datasets: [
                {
                    label: "Total Lots",
                    data: categoriesData.map(item => item.lotCount),
                    hoverOffset: 6
                }
            ],
        };

        // options={options}
        return (
            <div className="categories-chart">
                <Doughnut options={chartOptions} data={data} />
            </div>
        );
    };

    const _LocationChartWrapper = () => {
        const locationData = locationChart.locationsCount;
        const data = {
            labels: locationData.map(item => item._id),
            datasets: [
                {
                    label: "Total Lots",
                    data: locationData.map(item => item.count),
                    hoverOffset: 4
                },
            ],
        };

        return (
            <div className="location-chart">
                <PolarArea options={chartOptions} data={data} />
            </div>
        );
    };

    const _BidsChartWrapper = () => {
        const marketplaceData = bidChart.marketplaceLotsBids;
        const data = {
            labels: marketplaceData.map(item => item._id),
            datasets: [
                {
                    label: "Bids Status ",
                    data: marketplaceData.map(item => item.totalBids),
                    hoverOffset: 4
                },
                {
                    label: "Lots Status ",
                    data: marketplaceData.map(item => item.totalLots),
                    hoverOffset: 4
                },
            ],
        };

        return (
            <div className="location-chart">
                <Bar data={data} />
            </div>
        );
    };


    useEffect(() => {
        // getDashboardDataHandler();
    }, []);

    return (
        <div className="main-parant-1">
            <section className="sec-1">
                <section className="analytic-section">
                    <div className="main-rightbar-box">
                        <div>
                            <div className="Rule-page">
                                <div>
                                    <div className="blue-box-roki">
                                        <div className="starwed">
                                            <div>
                                                <img src="assets/img/perspective_matt.svg" className="perspective_matt.svg" alt="" />
                                            </div>
                                            <div className="selli12">
                                                <p className="stepse">Let’s get started!</p>
                                                <p className="stepsw">Ready to start bidding?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grider-threw">
                                        <div className="gri-tile">
                                            <p className="order-titl">CURRENT ACTIVE BIDS</p>
                                            <h6 className="zero-titl">{dashboardMatricsVal.totalActiveBidsCount}</h6>
                                            <p className="monthr">{dashboardMatricsVal.activeBidsLast30Days} in last 30 days</p>
                                        </div>
                                        <div className="gri-tile">
                                            <p className="order-titl">EXPIRED BIDS</p>
                                            <h6 className="zero-titl">{dashboardMatricsVal.totalClosedBidsCount}</h6>
                                            <p className="monthr">{dashboardMatricsVal.closedBidsLast30Days} in last 30 days</p>
                                        </div>
                                        {/* ₹ */}
                                        <div className="gri-tile clickable" onClick={() => navigate('/lot-list')}>
                                            <p className="order-titl">TOTAL ACTIVE LOTS</p>
                                            <h6 className="zero-titl">{dashboardMatricsVal.totalActiveLotsCount}</h6>
                                            <p className="monthr">{dashboardMatricsVal.activeLotsLast30Days} in last 30 days</p>
                                        </div>
                                    </div>
                                    <div className="grider-threw">
                                        <div className="gri-tile categories-chart-wrapper">
                                            <p className="order-titl">CATEGORIES</p>
                                            {categoriesChart && categoriesChart.lotsByCategory && categoriesChart.lotsByCategory.length > 0 ?
                                                (_CategoriesChartWrapper())
                                                : ('')}
                                        </div>
                                        <div className="gri-tile">
                                            <p className="order-titl">LOCATIONS</p>
                                            {locationChart && locationChart.locationsCount && locationChart.locationsCount.length > 0 ?
                                                (_LocationChartWrapper())
                                                : ('')}
                                        </div>
                                        <div className="gri-tile">
                                            <p className="order-titl">BID STATUS</p>
                                            {bidChart && bidChart.marketplaceLotsBids && bidChart.marketplaceLotsBids.length > 0 ?
                                                (_BidsChartWrapper())
                                                : ('')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default AnalyticsDashboard;
