import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Toaster from "../Basic/Toaster";
import { useService } from "../hooks/useService";
import { useAuth } from "../hooks/useAuth";
import Sidebar from "../Basic/Sidebar";
import PageNavigation from '../Basic/PageNavigation';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-datepicker/dist/react-datepicker.css';
import { getFileNameFromPath } from '../Config/config';
import moment from 'moment-timezone';
import { format } from 'date-fns';

/**
 * Renders the AdminLotDetails component.
 *
 * @component
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The rendered AdminLotDetails component.
 */
function AdminUserInfo(props) {

    const navigate = useNavigate();
    const { getUserDetails, updateUserDetails, uploadDocuments } = useService();
    let { uid } = useParams();

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [userInformation, setUserDetails] = useState({});
    const [createdOn, setCreatedOn] = useState('');
    const [reload, setReload] = useState(false);
    const [validated, setValidated] = useState(false);

    const [selectedGSTFile, setSelectedGSTFile] = useState(null);
    const [selectedPANFile, setSelectedPANFile] = useState(null);
    const [selectedAADHARFile, setSelectedAADHARFile] = useState(null);

    const [GSTFileUploadedStatus, setGSTFileUploadedStatus] = useState("no_image");
    const [PANFileUploadedStatus, setPANFileUploadedStatus] = useState("no_image");
    const [AADHARFileUploadedStatus, setAADHARFileUploadedStatus] = useState("no_image");

    const getUserHandler = async () => {
        // setTimeout(setLoading(true), 600);
        getUserDetails(uid)
            .then(async response => {
                console.log(response);
                if (response && Object.keys(response).length) {
                    setUserDetails(response);
                    setCreatedOn(format(new Date(response.createdAt), "dd/MM/yyyy, hh:MM aaaaa'm'"));

                    if (response.gstDocument) {
                        setGSTFileUploadedStatus("image_uploaded");
                    }
                    if (response.panDocument) {
                        setPANFileUploadedStatus("image_uploaded");
                    }
                    if (response.aadhaarDocument) {
                        setAADHARFileUploadedStatus("image_uploaded");
                    }
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const updateBuyerHandler = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            setShowToaster(true);
            setToasterMessage('Validation failed. Please check your inputs.');
            return false;
        }

        // Gather form data
        const formData = new FormData(form);
        const payload = {};
        for (let [key, value] of formData.entries()) {
            payload[key] = value;
        }

        updateUserDetails(payload, uid)
            .then(async response => {
                if (response && Object.keys(response).length) {
                    setShowToaster(true);
                    setToasterMessage('Buyer updated successfully');
                    setReload(true);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                console.log(error);
                // setTimeout(setLoading(false), 600);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const handleReupload = (e, docType) => {
        e.preventDefault();

        if (docType === 'GST') {
            setSelectedGSTFile(null);
            setGSTFileUploadedStatus("no_image");
        } else if (docType === 'PAN') {
            setSelectedPANFile(null);
            setPANFileUploadedStatus("no_image");
        } else if (docType === 'AADHAR') {
            setSelectedAADHARFile(null);
            setAADHARFileUploadedStatus("no_image");
        }
    }

    const handleGSTUpload = (e) => {
        console.log('GST', e.target.files[0]);
        setSelectedGSTFile(e.target.files[0]);
        setGSTFileUploadedStatus("image_added");
    }

    const handlePANUpload = (e) => {
        console.log('PAN', e.target.files[0]);
        setSelectedPANFile(e.target.files[0]);
        setPANFileUploadedStatus("image_added");
    }

    const handleAADHARUpload = (e) => {
        console.log('AADHAR', e.target.files[0]);
        setSelectedAADHARFile(e.target.files[0]);
        setAADHARFileUploadedStatus("image_added");
    }

    const handleSubmitDocumentsUpload = (e) => {
        e.preventDefault();

        uploadDocuments(selectedGSTFile, selectedPANFile, selectedAADHARFile, uid)
            .then(response => {
                console.log(response);
                if (response.status === 200 || response.status === 201) {
                    setShowToaster(true);
                    setToasterMessage('Documents uploaded successfully');
                    setReload(true);
                } else {
                    setShowToaster(true);
                    setToasterMessage('Document upload failed. Please try again');
                }
            })
            .catch(error => {
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    useEffect(() => {
        setReload(false);
        const getInitialData = async () => {
            await getUserHandler();
        };

        getInitialData();
    }, [reload]);


    return (
        <div className="main-parant-1">
            <section className="sec-1">
                <Sidebar />
                <section className="home-section">
                    <div className="main-rightbar-box">
                        <div>
                            <Form noValidate validated={validated} onSubmit={updateBuyerHandler}>
                                <div className="Rule-page">
                                    <div className="main-jaste">
                                        <div className="mi">
                                            <h1 className="left-title">Buyer Details</h1>
                                        </div>
                                        <div className="main-flxwer">
                                            <Button className='btm-blueed-btn pointer' name='update-save' type="submit">Update Buyer</Button>
                                        </div>

                                    </div>
                                    <PageNavigation page="buyer-info" nav="admin" />
                                    <div>

                                        <div>
                                            <div className="wihte-erya mb-4">
                                                <section className="lot-info-wrapper user-info-wrapper admin-lots">
                                                    <section className='ld-wrapper mb-3'>
                                                        <Row className="mb-3">
                                                            <Col xs={5}>
                                                                <div className='ld-wrapper'>
                                                                    <div className="liquid-label"><span className="liquid-text">Upload Documents</span></div>
                                                                </div>
                                                                <div className='upload-documents-wrapper mt-5 mb-4'>

                                                                    {GSTFileUploadedStatus === "no_image" &&
                                                                        (<label htmlFor="gst" className={`upload-file-wrapper stap-documents-wrapper`}>
                                                                            <div>
                                                                                <div className="klknwed">
                                                                                    <p className="Dropqw">Drop your GST file to upload</p>
                                                                                    <a className="Dropqw-like">Choose file </a>
                                                                                    <p className="Maxqw">Max file size : 5MB</p>
                                                                                    <input type="file" name="gst" className="hidden" id="gst" accept=".png, .jpg, .jpeg, .pdf" onChange={(event) => handleGSTUpload(event)}></input>
                                                                                </div>
                                                                            </div>
                                                                        </label>)
                                                                    }

                                                                    {GSTFileUploadedStatus === "image_added" &&
                                                                        (<div className="stap-documents-wrapper">
                                                                            <p className="Dropqw">GST Document</p>
                                                                            <div className="main-exert">
                                                                                <div className="in-exerqq">
                                                                                    <div className="spacerr">
                                                                                        <div className="main-exert12">
                                                                                            <div>
                                                                                                <img src="../assets/img/c.text.svg" className="qwe12" alt="" />
                                                                                            </div>
                                                                                            <div className="main-exert422">
                                                                                                <p className="dxasd">{selectedGSTFile.name}</p>
                                                                                                <p className="qwedq">{selectedGSTFile && (Math.round(selectedGSTFile.size / 1024) + " KB")}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="rang"></div>
                                                                                    </div>
                                                                                    <div className="main-exert12">
                                                                                        {/* <p className="qwedq">40%</p> */}
                                                                                        <img src="../assets/img/multiply.circle.svg" className="qwe12" alt="" onClick={(event) => handleReupload(event, 'GST')} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                    }

                                                                    {GSTFileUploadedStatus === "image_uploaded" &&
                                                                        (<div className="stap-documents-wrapper">
                                                                            <p className="Dropqw">GST Document</p>
                                                                            <div className="main-exert">
                                                                                <div className="in-exerqq">
                                                                                    <div className="spacerr">
                                                                                        <div className="main-exert12">
                                                                                            <div>
                                                                                                <img src="../assets/img/c.text.svg" className="qwe12" alt="" />
                                                                                            </div>
                                                                                            <div className="main-exert422">
                                                                                                <a href={userInformation?.panDocument} target='_blank' className="dxasd loaded-file-name">{userInformation && userInformation.gstDocument && getFileNameFromPath(userInformation.gstDocument)}</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="main-exert12">
                                                                                        {/* <p className="qwedq">40%</p> */}
                                                                                        <img src="../assets/img/multiply.circle.svg" className="qwe12" alt="" onClick={(event) => handleReupload(event, 'GST')} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                    }

                                                                    {PANFileUploadedStatus === "no_image" &&
                                                                        (<label htmlFor="pan" className={`upload-file-wrapper stap-documents-wrapper`}>
                                                                            <div>
                                                                                <div className="klknwed">
                                                                                    <p className="Dropqw">Drop your PAN file to upload</p>
                                                                                    <a className="Dropqw-like">Choose file </a>
                                                                                    <p className="Maxqw">Max file size : 5MB</p>
                                                                                    <input type="file" name="pan" className="hidden" id="pan" accept=".png, .jpg, .jpeg, .pdf" onChange={(event) => handlePANUpload(event)}></input>
                                                                                </div>
                                                                            </div>
                                                                        </label>)
                                                                    }

                                                                    {PANFileUploadedStatus === "image_added" &&
                                                                        (<div className="stap-documents-wrapper">
                                                                            <p className="Dropqw">PAN Document</p>
                                                                            <div className="main-exert">
                                                                                <div className="in-exerqq">
                                                                                    <div className="spacerr">
                                                                                        <div className="main-exert12">
                                                                                            <div>
                                                                                                <img src="../assets/img/c.text.svg" className="qwe12" alt="" />
                                                                                            </div>
                                                                                            <div className="main-exert422">
                                                                                                <p className="dxasd">{selectedPANFile.name}</p>
                                                                                                <p className="qwedq">{selectedPANFile && (Math.round(selectedPANFile.size / 1024) + " KB")}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="rang"></div>
                                                                                    </div>
                                                                                    <div className="main-exert12">
                                                                                        {/* <p className="qwedq">40%</p> */}
                                                                                        <img src="../assets/img/multiply.circle.svg" className="qwe12" alt="" onClick={(event) => handleReupload(event, 'PAN')} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                    }

                                                                    {PANFileUploadedStatus === "image_uploaded" &&
                                                                        (<div className="stap-documents-wrapper">
                                                                            <p className="Dropqw">PAN Document</p>
                                                                            <div className="main-exert">
                                                                                <div className="in-exerqq">
                                                                                    <div className="spacerr">
                                                                                        <div className="main-exert12">
                                                                                            <div>
                                                                                                <img src="../assets/img/c.text.svg" className="qwe12" alt="" />
                                                                                            </div>
                                                                                            <div className="main-exert422">
                                                                                                <a href={userInformation?.panDocument} target='_blank' className="dxasd loaded-file-name">{userInformation && userInformation.panDocument && getFileNameFromPath(userInformation.panDocument)}</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="main-exert12">
                                                                                        {/* <p className="qwedq">40%</p> */}
                                                                                        <img src="../assets/img/multiply.circle.svg" className="qwe12" alt="" onClick={(event) => handleReupload(event, 'PAN')} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                    }

                                                                    {AADHARFileUploadedStatus === "no_image" &&
                                                                        (<label htmlFor="aadhar" className={`upload-file-wrapper stap-documents-wrapper`}>
                                                                            <div>
                                                                                <div className="klknwed">
                                                                                    <p className="Dropqw">Drop your AADHAR file to upload</p>
                                                                                    <a className="Dropqw-like">Choose file </a>
                                                                                    <p className="Maxqw">Max file size : 5MB</p>
                                                                                    <input type="file" name="aadhar" className="hidden" id="aadhar" accept=".png, .jpg, .jpeg, .pdf" onChange={(event) => handleAADHARUpload(event)}></input>
                                                                                </div>
                                                                            </div>
                                                                        </label>)
                                                                    }

                                                                    {AADHARFileUploadedStatus === "image_added" &&
                                                                        (<div className="stap-documents-wrapper">
                                                                            <p className="Dropqw">AADHAR Document</p>
                                                                            <div className="main-exert">
                                                                                <div className="in-exerqq">
                                                                                    <div className="spacerr">
                                                                                        <div className="main-exert12">
                                                                                            <div>
                                                                                                <img src="../assets/img/c.text.svg" className="qwe12" alt="" />
                                                                                            </div>
                                                                                            <div className="main-exert422">
                                                                                                <p className="dxasd">{selectedAADHARFile.name}</p>
                                                                                                <p className="qwedq">{selectedAADHARFile && (Math.round(selectedAADHARFile.size / 1024) + " KB")}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="rang"></div>
                                                                                    </div>
                                                                                    <div className="main-exert12">
                                                                                        {/* <p className="qwedq">40%</p> */}
                                                                                        <img src="../assets/img/multiply.circle.svg" className="qwe12" alt="" onClick={(event) => handleReupload(event, 'AADHAR')} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                    }

                                                                    {AADHARFileUploadedStatus === "image_uploaded" &&
                                                                        (<div className="stap-documents-wrapper">
                                                                            <p className="Dropqw">AADHAR Document</p>
                                                                            <div className="main-exert">
                                                                                <div className="in-exerqq">
                                                                                    <div className="spacerr">
                                                                                        <div className="main-exert12">
                                                                                            <div>
                                                                                                <img src="../assets/img/c.text.svg" className="qwe12" alt="" />
                                                                                            </div>
                                                                                            <div className="main-exert422">
                                                                                                <a href={userInformation?.panDocument} target='_blank' className="dxasd loaded-file-name">{userInformation && userInformation.aadhaarDocument && getFileNameFromPath(userInformation.aadhaarDocument)}</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="main-exert12">
                                                                                        {/* <p className="qwedq">40%</p> */}
                                                                                        <img src="../assets/img/multiply.circle.svg" className="qwe12" alt="" onClick={(event) => handleReupload(event, 'AADHAR')} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                    }

                                                                </div>
                                                                <div className='text-center'><Button className='btm-blueed-btn pointer sm' onClick={handleSubmitDocumentsUpload}>Upload Documents</Button></div>

                                                            </Col>
                                                            <Col xs={7} >
                                                                <div className='lot-info-price-wrapper mb-3'>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Buyer ID"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    disabled
                                                                                    name='uid'
                                                                                    className='days-input-wrapper'
                                                                                    placeholder="Buyer ID"
                                                                                    aria-label="Buyer ID"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.uid || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="User Name"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    disabled
                                                                                    name='uname'
                                                                                    placeholder="User Name"
                                                                                    aria-label="User Name"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.uname || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Email ID"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    disabled
                                                                                    name='email'
                                                                                    placeholder="Email ID"
                                                                                    aria-label="Email ID"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.email || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Phone Number"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    disabled
                                                                                    type='tel'
                                                                                    name='phone'
                                                                                    placeholder="Phone Number"
                                                                                    aria-label="Phone Number"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.phone || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="First Name"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='firstName'
                                                                                    className='days-input-wrapper'
                                                                                    placeholder="First Name"
                                                                                    aria-label="First Name"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.firstName || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Last Name"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    required
                                                                                    name='lastName'
                                                                                    placeholder="Last Name"
                                                                                    aria-label="Last Name"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.lastName || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Manager 1"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    name='manager1'
                                                                                    placeholder="Manager 1"
                                                                                    aria-label="Manager 1"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.manager1 || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Manager 2"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    name='manager2'
                                                                                    placeholder="Manager 2"
                                                                                    aria-label="Manager 2"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.manager2 || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Manager 3"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    name='manager3'
                                                                                    placeholder="Manager 3"
                                                                                    aria-label="Manager 3"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.manager3 || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Manager 4"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    name='manager4'
                                                                                    placeholder="Manager 4"
                                                                                    aria-label="Manager 4"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.manager4 || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Created On"
                                                                            >
                                                                                <Form.Control size="sm"
                                                                                    disabled
                                                                                    name='email'
                                                                                    placeholder="Created On"
                                                                                    aria-label="Created On"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={createdOn || ''}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <FloatingLabel
                                                                                controlId="floatingTextarea2"
                                                                                label="Buyer Notes"
                                                                            >
                                                                                <Form.Control size="md"
                                                                                    as="textarea"
                                                                                    name='buyerNotes'
                                                                                    style={{ height: '100px' }}
                                                                                    placeholder="Buyer Notes"
                                                                                    aria-label="Buyer Notes"
                                                                                    aria-describedby="basic-addon2"
                                                                                    defaultValue={userInformation.buyerNotes || ''}

                                                                                />
                                                                            </FloatingLabel>
                                                                        </Col>
                                                                    </Row>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </section>
                                                </section>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </section>
            </section>

            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </div>
    );
}

export default AdminUserInfo;