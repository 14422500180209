import React, { useEffect, useState } from 'react';
import Sidebar from "../Basic/Sidebar";
import { validateInput, content } from '../Config/config';
import Toaster from "../Basic/Toaster";
import ReactTooltip from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { useService } from "../hooks/useService";
import Badge from 'react-bootstrap/Badge';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Loader from '../Basic/Loader';

function AdminDeliveryList({ role }) {

    const navigate = useNavigate();
    const { getDeliveries, updateDeliveryStatus } = useService();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);

    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [deliverysData, setDeliveriesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [selectedItem, setSelectedItem] = useState({});
    const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState('pending');

    const geDeliveriesHandler = (currentPage, search = '') => {
        setTimeout(setLoading(true), 600);
        getDeliveries(currentPage, search)
            .then(response => {
                if (response && response.length) {
                    setDeliveriesData(response);
                } else {
                    setDeliveriesData([]);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const handleActions = (item) => {
        console.log(item);
        setSelectedItem(item);
        if (item?.delivery_status) {
            setSelectedDeliveryStatus(item?.delivery_status);
        }
    }

    const updateDeliveryStatusHandler = (e) => {
        let status = e.target.value;
        setSelectedDeliveryStatus(status);
    }

    const updateDeliveryHandler = (e) => {
        let payload = {
            delivery_status: selectedDeliveryStatus
        }

        setTimeout(setLoading(true), 600);
        updateDeliveryStatus(selectedItem.lotid, payload)
            .then(response => {
                setReload(true);
                setShowToaster(true);
                setToasterMessage(response.message);

                setTimeout(() => {
                    window.location.reload();
                }, 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const _handleUserRows = (list) => {
        return (
            <tbody>
                {list.map((item, index) => (
                    <tr className="gry-tred" key={index}>
                        <td className="body-title">
                            <a className="numberw" onClick={() => navigate(`/selected-lot/${item.lotid}`)}>
                                {item.lotid}
                            </a>
                        </td>
                        <td className="body-title">{item.lot_name}</td>
                        <td className="body-title">{item.highest_bid_price || 0}</td>
                        <td className="body-title">
                            <a className="numberw" onClick={() => navigate('/buyer-list')}>
                                {item.winner_uid || "--"}
                            </a>
                        </td>
                        <td className="body-title">
                            {/* 'pending', 'packed', 'dispatched', 'delivered' */}
                            {item?.delivery_status === "delivered" ? (
                                <Badge bg="success">Delivered</Badge>
                            ) : item?.delivery_status === "partial" ? (
                                <Badge bg="warning">Partially Delivered</Badge>
                            ) : item?.delivery_status === "dispatched" ? (
                                <Badge bg="primary">Dispatched</Badge>
                            ) : item?.delivery_status === "packed" ? (
                                <Badge bg="secondary">Packed</Badge>
                            ) : (
                                <Badge bg="danger">Pending</Badge>
                            )
                            }
                        </td>
                        <td className="body-title text-center"><img width="30" src="assets/img/Frame1.svg" data-bs-toggle="modal" data-bs-target="#updateDelivery" className="pancen-1st" alt="" onClick={() => handleActions(item)} /></td>
                    </tr>
                ))}
            </tbody>
        )
    }

    const handleSearch = () => {
        let searchText = document.getElementById('searchItem');
        if (searchText.value.length >= 3) {

            geDeliveriesHandler(currentPage, searchText.value);
        } else if (searchText.value.length === 0) {
            geDeliveriesHandler(currentPage);
        }
    }

    useEffect(() => {
        let dispatchSection = document.getElementById("dispatchSection");
        dispatchSection.classList.add("active");
        geDeliveriesHandler(currentPage);
    }, [setReload]);


    return (
        <div className="main-parant-1">
            <section className="sec-1">
                <Sidebar />
                <section className="home-section">
                    <div className="main-rightbar-box">
                        <div>
                            <div className="Rule-page">
                                <div className="main-jaste">
                                    <div className="mi">
                                        <h1 className="left-title">Deliveries</h1>
                                    </div>
                                </div>
                                <div>
                                    {/* <div className="main-search-side mb-3">
                                        <div className="btn-input wimor adcqdq search-wrapper">
                                            <input type="text" id="searchItem" placeholder="Search by Lot ID" className="name-poi aede search" onChange={handleSearch} />
                                            <div>
                                                <img src="assets/img/SAERCH1.svg" className="SAERCH1" alt="" />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="wihte-erya">
                                        <div>
                                            <table className="w-100 m-0">
                                                <thead>
                                                    <tr className="gry-tr h-74">
                                                        <td className="head-title padin-24">Lot ID (123)</td>
                                                        <td className="head-title padin-24">Lot Name</td>
                                                        <td className="head-title padin-24">Buying Price</td>
                                                        <td className="head-title padin-24">Buyer ID</td>
                                                        <td className="head-title padin-24">Delivery Status</td>
                                                        <td className="head-title padin-24">Action</td>
                                                    </tr>
                                                </thead>
                                                {deliverysData.length !== 0 &&
                                                    _handleUserRows(deliverysData)
                                                }
                                            </table>
                                        </div>
                                        {deliverysData.length === 0 &&
                                            <div className="space-26px">
                                                <p className="create-oneedw">You haven't got any data yet, <a className="add-custm-btn" onClick={() => navigate('/payments')}> Check the payment status</a></p>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            {/* <!-- Modal Edit delivery --> */}
            <div className="modal fade" id="updateDelivery" tabIndex={"-1"} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog proder">
                    <div className="modal-content styled">
                        <div className="modal-FLAX">
                            <h5 className="modal-title">Update delivery status</h5>
                            <button type="button" className="btner" data-bs-dismiss="modal" aria-label="Close"><img src="assets/img/multiply.svg" className="multiply-ic" alt="" /></button>
                        </div>

                        <div className="four-inpute">
                            <Row>
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Delivery Status"
                                    >
                                        {/* 'pending', 'packed', 'dispatched', 'partial', 'delivered' */}
                                        <Form.Select aria-label="Select Slab" className='trader-dropdown' defaultValue={selectedDeliveryStatus} onChange={updateDeliveryStatusHandler}>
                                            <option>Open to select delivery status</option>
                                            <option value="pending">Pending Delivery</option>
                                            <option value="packed">Order Packed</option>
                                            <option value="dispatched">Order Dispatched</option>
                                            <option value="partial">Partially Delivered</option>
                                            <option value="delivered">Order Delivered</option>
                                        </Form.Select>

                                    </FloatingLabel>
                                </Col>
                            </Row>

                        </div>
                        <div className="main-cntwr">
                            <a className="SAVE-btn blue" onClick={updateDeliveryHandler} >UPDATE</a>
                        </div>
                    </div>
                </div>
            </div>

            <ReactTooltip html={true} className="tooltip" />
            <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMessage} error={true} />
        </div>
    );
}

export default AdminDeliveryList;