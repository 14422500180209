import React from 'react';
import { useNavigate } from "react-router-dom";
import Marketplace from '../Buyer/Marketplace';

function LeadingMarketplaces(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* topcategory-section */}
      <section className="topcategory-section centred">
        <Marketplace
          maxCount={props.maxCount}
          heading="Our Leading Marketplaces"
          subheading="Follow the most popular marketplaces and get exclusive items from Togofogo Lots"
          center="true"
          showCount="true"
        />
      </section>
      {/* topcategory-section end */}

    </>
  );
}

export default LeadingMarketplaces;