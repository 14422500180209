import PageNavigation from '../Basic/PageNavigation';

function PageNotFound() {
    return (
        <>
            <PageNavigation page="not-found" />
            <div className="no-page-found-wrapper">
                <section className="sec-1">
                    <svg className="not-found-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 650 512" id="page-not-found">
                        <path fill="#dbe8ec" d="M596.6769,246.03836V227.8472a13.07945,13.07945,0,0,0-13.07945-13.07946h-36.166A13.07945,13.07945,0,0,1,534.352,201.68829V183.49713c0-7.22358,15.85587-13.07945,23.07946-13.07945h1.45764a13.07945,13.07945,0,0,0,13.07945-13.07945V139.14707a13.07945,13.07945,0,0,0-13.07945-13.07946H116.98193a13.07945,13.07945,0,0,0-13.07945,13.07946v18.19116a13.07945,13.07945,0,0,0,13.07945,13.07945h0a13.07945,13.07945,0,0,1,13.07945,13.07945v18.19116A13.07945,13.07945,0,0,1,116.982,214.76774H66.40255A13.07945,13.07945,0,0,0,53.3231,227.8472v18.19116a13.07945,13.07945,0,0,0,13.07945,13.07945H86.24913a13.07945,13.07945,0,0,1,13.07945,13.07945v18.19125A13.07945,13.07945,0,0,1,86.24913,303.468h-.048a13.07945,13.07945,0,0,0-13.07945,13.07945v18.19108a13.07945,13.07945,0,0,0,13.07945,13.07945h2.87139A13.07945,13.07945,0,0,1,102.152,360.89739v18.19125a13.07945,13.07945,0,0,1-13.07945,13.07945H71.94805A13.07945,13.07945,0,0,0,58.8686,405.24755v18.19116a13.07945,13.07945,0,0,0,13.07945,13.07945h483.963a13.07945,13.07945,0,0,0,13.07945-13.07945V405.24755a13.07945,13.07945,0,0,0-13.07945-13.07946H544.57054a13.07945,13.07945,0,0,1-13.07945-13.07945V360.89739a13.07945,13.07945,0,0,1,13.07945-13.07945H571.3104a13.07945,13.07945,0,0,0,13.07945-13.07945V316.54741A13.07945,13.07945,0,0,0,571.3104,303.468h-8.11163a13.07945,13.07945,0,0,1-13.07945-13.07945V272.19726a13.07945,13.07945,0,0,1,13.07945-13.07945h20.39868A13.07945,13.07945,0,0,0,596.6769,246.03836Z"></path>
                        <path fill="#f9ae2b" d="M221.78248,214.16474A25.03081,25.03081,0,0,1,196.774,239.18431h-93.4501a19.2824,19.2824,0,0,1-4.09924-38.12438,22.24645,22.24645,0,0,1,22.1682-20.77985,21.592,21.592,0,0,1,6.72471,1.07435,35.1018,35.1018,0,0,1,65.34225,8.04976,24.97773,24.97773,0,0,1,28.3227,24.76055Zm0,0"></path>
                        <path fill="#409cb5" d="M384.28,361.08l-18.57849-27.62036a79.998,79.998,0,1,0-23.11361,14.3481l18.11051,26.80388A13.59408,13.59408,0,0,0,384.28,361.08Z"></path>
                        <circle cx="312.733" cy="273.513" r="57.048" fill="#ee781d"></circle>
                        <path fill="#e06714" d="M312.733,216.46549a57.46155,57.46155,0,0,0-8.1389.58762,57.0432,57.0432,0,0,1,0,112.92057,57.046,57.046,0,1,0,8.1389-113.50819Z"></path>
                        <circle cx="282.177" cy="274.625" r="8.829" fill="#f9ae2b"></circle>
                        <circle cx="341.279" cy="262.647" r="6.112" fill="#e06714"></circle>
                        <circle cx="307.149" cy="250.639" r="4.075" fill="#e06714"></circle>
                        <circle cx="308.658" cy="304.906" r="4.075" fill="#e06714"></circle>
                        <circle cx="332.207" cy="289.982" r="5.513" fill="#f9ae2b"></circle>
                        <circle cx="270.216" cy="326.179" r="3.132" fill="#3086a3"></circle>
                        <circle cx="372.913" cy="239.928" r="3.132" fill="#3086a3"></circle>
                        <path fill="#3086a3" d="M371.01077 307.043a62.00769 62.00769 0 01-6.9519 11.38769 67.63971 67.63971 0 01-9.42554 9.625 70.097 70.097 0 01-11.32837 7.438 64.12274 64.12274 0 01-26.11035 6.99951c-2.269.021-4.5332.10449-6.79395-.03271a61.37551 61.37551 0 01-6.73632-.75244 59.12344 59.12344 0 01-24.6753-10.04053 78.47226 78.47226 0 0025.08692 6.94678 71.86974 71.86974 0 0025.51245-2.09082 75.03937 75.03937 0 0012.04516-4.41651A81.4304 81.4304 0 00352.74 325.58893a83.73626 83.73626 0 009.84961-8.44532A94.24617 94.24617 0 00371.01077 307.043zM246.83377 286.85016a62.37457 62.37457 0 01.72266-35.16407 65.54686 65.54686 0 017.63794-16.2666 64.86242 64.86242 0 015.437-7.2041 67.57277 67.57277 0 016.32324-6.44824 72.55256 72.55256 0 017.14941-5.52246c1.2793-.79444 2.51319-1.66358 3.83814-2.38282l1.96264-1.11816 2.01929-1.01221c1.33716-.69189 2.74585-1.23535 4.11817-1.84765 1.41357-.51612 2.79614-1.11182 4.23828-1.54248A62.07975 62.07975 0 01299 206.1148a65.92283 65.92283 0 0117.85156-.69384 61.34708 61.34708 0 0117.14307 4.30761 75.38393 75.38393 0 00-17.26172-2.34716 72.22074 72.22074 0 00-17.10327 1.791 68.88679 68.88679 0 00-16.00122 5.94141 71.92164 71.92164 0 00-7.32837 4.36426 70.11477 70.11477 0 00-6.69312 5.27978 69.08613 69.08613 0 00-19.10571 27.92676A72.03517 72.03517 0 00246.83377 286.85016z"></path>
                        <path fill="#409cb5" d="M203.26879 332.51192v23.16521c0 20.1187 1.21938 21.64281 22.86133 23.77555v8.53609h-71.3275v-8.53609c22.86133-2.13274 24.38545-3.962 24.38545-24.08071v-22.86H98.7163v-9.14471c28.9578-41.15117 60.35394-85.654 90.83586-128.02415h13.71663v122.5363h29.87244v14.63256zM179.18807 227.34953c-19.20319 28.04273-40.54084 59.1337-61.57289 90.52983h61.57289zM504.42622 332.51192v23.16521c0 20.1187 1.21981 21.64281 22.86176 23.77555v8.53609h-71.3275v-8.53609c22.86091-2.13274 24.385-3.962 24.385-24.08071v-22.86H399.87416v-9.14471c28.95737-41.15117 60.35351-85.654 90.83586-128.02415h13.7162v122.5363h29.87287v14.63256zM480.3455 227.34953c-19.20319 28.04273-40.54084 59.1337-61.57246 90.52983H480.3455z"></path>
                        <path fill="#f9ae2b" d="M552.43892 143.69878a32.96942 32.96942 0 01-32.94005 32.95461H396.41078a25.39788 25.39788 0 01-5.39933-50.21565A29.302 29.302 0 01420.21038 99.0675a28.44023 28.44023 0 018.85746 1.41508 46.23445 46.23445 0 0186.06575 10.60276 32.89951 32.89951 0 0137.30533 32.61344zm0 0M552.266 401.37458a25.03081 25.03081 0 01-25.00852 25.01957H433.80737a19.2824 19.2824 0 01-4.09923-38.12437 22.24646 22.24646 0 0122.1682-20.77986 21.59234 21.59234 0 016.72471 1.07435A35.1018 35.1018 0 01523.9433 376.614a24.97773 24.97773 0 0128.3227 24.76055zm0 0"></path>
                        <line x1="119.225" x2="151.528" y1="119.327" y2="119.327" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
                        <line x1="119.225" x2="151.528" y1="128.637" y2="128.637" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
                        <line x1="119.225" x2="151.528" y1="137.948" y2="137.948" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
                        <line x1="273.595" x2="305.898" y1="395.257" y2="395.257" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
                        <line x1="273.595" x2="305.898" y1="404.568" y2="404.568" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
                        <line x1="273.595" x2="305.898" y1="413.878" y2="413.878" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
                        <line x1="536.115" x2="568.417" y1="271.982" y2="271.982" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
                        <line x1="536.115" x2="568.417" y1="281.293" y2="281.293" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
                        <line x1="536.115" x2="568.417" y1="290.603" y2="290.603" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
                        <circle cx="80.914" cy="399.628" r="16.428" fill="#b9d4db"></circle>
                        <circle cx="101.589" cy="373.267" r="8.557" fill="#b9d4db"></circle>
                        <circle cx="144.12" cy="411.536" r="7.406" fill="#b9d4db"></circle>
                        <circle cx="126.92" cy="389.583" r="4.936" fill="#b9d4db"></circle>
                        <circle cx="115.301" cy="414.559" r="11.833" fill="#b9d4db"></circle>
                        <circle cx="308.282" cy="162.289" r="16.428" fill="#b9d4db"></circle>
                        <circle cx="275.694" cy="178.717" r="7.406" fill="#b9d4db"></circle>
                        <circle cx="266.615" cy="150.456" r="11.833" fill="#b9d4db"></circle>
                    </svg>
                </section>
            </div>
        </>
    );
}

export default PageNotFound;
