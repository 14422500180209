import { createContext, useContext, useMemo } from "react";
// import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("trader_client_token", null);
  const [adminUser, setAdminUser] = useLocalStorage("trader_admin_token", null);
  // const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {

    if (data.role.toUpperCase() === 'ADMIN' || data.role.toUpperCase() === 'SUPERADMIN' || data.role.toUpperCase() === 'FINANCE') {
      setAdminUser(data);
    } else {
      setUser(data);
    }

    // navigate("/");
    // window.location.reload();
  };

  // call this function to sign out logged in user
  const logout = (role) => {
    if(role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SUPERADMIN' || role.toUpperCase() === 'FINANCE') {
      setAdminUser(null);
    } else {
      setUser(null);
    }
    // navigate("/", { replace: true });
    window.location.reload();
  };

  const value = useMemo(
    () => ({
      user,
      adminUser,
      login,
      logout,
    }),
    [user, adminUser]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};