import React, { useEffect } from 'react';
import HeaderNavbar from '../Basic/HeaderNavbar';
import Footer from '../Basic/Footer';
import Marketplace from './Marketplace';

function MarketplaceListPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
    // nav-all-mp-btn
    document.querySelector('.nav-all-mp-btn').classList.add('current');
  }, []);

  return (
    <>
      <HeaderNavbar />
      <div className="row col-lg-10 m-auto pt-4">
        <Marketplace maxCount="all" center="true" />
      </div>
      <Footer />
    </>
  );
}

export default MarketplaceListPage;
