import React, { useEffect } from 'react';
import HeaderNavbar from '../Components/Basic/HeaderNavbar';
import Footer from '../Components/Basic/Footer';
import { useNavigate } from "react-router-dom";

function Seller() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    // nav-seller-btn
    document.querySelector('.nav-seller-btn').classList.add('current');
  }, []);

  return (
    <>
      <HeaderNavbar />
      <div className='buyer-page-wrapper'>
        <div>
          {/* page-title */}
          <section className="page-title centred">
            <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/background/page-title.jpg)' }} />
            <div className="auto-container">
              <div className="content-box">
                <h1>Be a Seller with us</h1>
                <ul className="bread-crumb clearfix">
                  <li><i className="flaticon-home-1" /><a onClick={() => navigate('/')}>Home</a></li>
                  <li>Seller</li>
                </ul>
              </div>
            </div>
          </section>
          {/* page-title end */}
          {/* about-section */}
          <section className="about-section">
            <div className="auto-container">
              <div className="row align-items-center clearfix">
                <div className="col-lg-4 col-md-6 col-sm-12 text-column">
                  <div className="text-inner">
                    {/* <h2>Simple <br /><span>&amp;</span> Minimalistic</h2> */}
                    <h2><span>Sell your inventory</span><br />
                      directly to a pool of <br /> bulk buyers across India
                    </h2>
                    <h3>Be a Seller with us</h3>
                    {/* <p>Excepteur sint occaecat cupidat non proident sunt in culpa qui officia deserunt mollit anim est laborum.</p> */}
                    {/* <p>accusan enim ipsam voluptam quia voluptas sit aspern odit aut.</p> */}
                    <a href="tel:8860396039" className="theme-btn-one">Contact Us<i className="flaticon-right-1" /></a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 image-column">
                  {/* <figure className="image-box"><img src="assets/images/resource/about-1.jpg" alt="" /></figure> */}
                  <figure className="image-box"><img src="assets/img/buyer.jpeg" alt="" /></figure>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 text-column">
                  <div className="text-inner">
                    <div className="box">
                      <h4>Togofogo Overview</h4>
                      <p>At Togofogo, our mission is to revolutionize the way businesses and individuals connect, collaborate, and thrive. We provide a transparent, efficient, and secure bidding platform that bridges the gap between opportunity seekers and providers.</p>
                    </div>
                    <div className="box">
                      <h4>Our Mission</h4>
                      <p>- Facilitate seamless interactions between buyers and sellers</p>
                      <p>- Foster a culture of trust, fairness, and innovation</p>
                      <p> - Simplify the bidding process, saving time and resources</p>
                      <p>- Unlock new opportunities for growth and success</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* about-section end */}
          {/* chooseus-section */}
          <section className="chooseus-section">
            <div className="outer-container clearfix">
              <div className="content-column">
                <div className="inner-column">
                  <div className="content-box">
                    <div className="sec-title style-two">
                      <h2>Top Features</h2>
                      <p>We offer Platform to Sell Your Excess, Returned, Refurbished, and Decommissioned Inventory</p>
                      <span className="separator" style={{ backgroundImage: 'url(assets/images/icons/separator-3.png)' }} />
                    </div>
                    <div className="inner-box">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                          <div className="single-item wow fadeInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            {/* <div className="icon-box"><i className="flaticon-truck" /></div> */}
                            <div className="icon-box"><img width={50} src={'./../assets/img/high.png'} /></div>
                            <h3>Higher Growth</h3>
                            <p>Achieve higher growth driven by an expansive market reach</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                          <div className="single-item wow fadeInRight animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            {/* <div className="icon-box"><i className="flaticon-24-7" /></div> */}
                            <div className="icon-box"><img width={50} src={'./../assets/img/fast-delivery.png'} /></div>
                            <h3>Fast Delivery</h3>
                            <p>Deliver your inventory swiftly with our nationwide network</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                          <div className="single-item wow fadeInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            {/* <div className="icon-box"><i className="flaticon-credit-card" /></div> */}
                            <div className="icon-box"><img width={50} src={'./../assets/img/cross-selling.png'} /></div>
                            <h3>Flexible Selling</h3>
                            <p>Sell your inventory at your preferred locations with flexible options</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                          <div className="single-item wow fadeInRight animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            {/* <div className="icon-box"><i className="flaticon-undo" /></div> */}
                            <div className="icon-box"><img width={50} src={'./../assets/img/multitasking.png'} /></div>
                            <h3>Enhanced Productivity</h3>
                            <p>Boost productivity with automated billing, collections, and inventory management tools</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="image-column" style={{ backgroundImage: 'url(assets/images/background/chooseus-bg-1.jpg)' }} /> */}
              <div className="image-column" style={{ backgroundImage: 'url(assets/img/seller.jpg)' }} />
            </div>
          </section>
          {/* chooseus-section end */}
          {/* team-section */}
          <section className="team-section centred">
            <div className="auto-container">
              <div className="sec-title">
                <h2>What are we offering you?</h2>
                <p>We offer a better Trading Platform where you can find best buyers to drive better recovery</p>
                <span className="separator" style={{ backgroundImage: 'url(assets/images/icons/separator-1.png)' }} />
              </div>
              <div className="row clearfix">
                <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                  <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                      {/* <figure className="image-box">
                        <img src="assets/images/team/team-1.jpg" alt="" />
                      </figure> */}
                      <div className="lower-content">
                        <h3><a>Trading Platform</a></h3>
                        <span className="designation">Large Buyer Pool</span>
                        <span className="designation">Fully Hosted Secure Solution</span>
                        <span className="designation">Grading Tool</span>
                        <span className="designation">Granular Insights & Reporting</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                  <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                      {/* <figure className="image-box">
                        <img src="assets/images/team/team-2.jpg" alt="" />
                      </figure> */}
                      <div className="lower-content">
                        <h3><a>Buyer</a></h3>
                        <span className="designation">No Middle Men</span>
                        <span className="designation">All Size Lots</span>
                        <span className="designation">Detailed Item Level Manifests</span>
                        <span className="designation">Dedicated Customer Service</span>
                        <span className="designation">Transparent T&Cs</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                  <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                      {/* <figure className="image-box">
                        <img src="assets/images/team/team-3.jpg" alt="" />
                      </figure> */}
                      <div className="lower-content">
                        <h3><a>Seller</a></h3>
                        <span className="designation">Enhanced Compliance</span>
                        <span className="designation">Branded Liquidation E-Store</span>
                        <span className="designation">Maximized Recovery</span>
                        <span className="designation">No Channel Conflict</span>
                        <span className="designation">Payment Before Dispatch</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* team-section end */}
        </div>

      </div>

      <Footer />
    </>
  );
}

export default Seller;
