import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Sidebar from "../Basic/Sidebar";
import { format } from 'date-fns';
import { useAuth } from "../hooks/useAuth";
import { useService } from "../hooks/useService";
import { formatCurrency } from '../Config/config';
import ListPagination from '../Basic/Pagination';
import Chart from "chart.js/auto";
import { PolarArea, Scatter, Line, Bar, Doughnut } from "react-chartjs-2";

function Dashboard(props) {
    const navigate = useNavigate();
    const { adminUser, logout } = useAuth();
    const { getDashboardMatrics, getCategoriesChartData, getLocationsChartData, getBidsChartData, bidsList, soldLots } = useService();

    const chartOptions = {
        plugins: {
            legend: {
                "display": true,
                "position": "bottom",
                "align": "start"
            }
        }
    };

    const [loading, setLoading] = useState(false);
    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState('');
    const [dashboardMatricsVal, setDashboardMatrics] = useState({});
    const [categoriesChart, setCategoriesChartdData] = useState({});
    const [locationChart, setLocationChartData] = useState({});
    const [bidChart, setBidChartData] = useState({});
    const [dashboardBidList, setDashboardBidList] = useState([]);
    const [soldLotsList, setSoldLotsList] = useState([]);
    const [currentSegment, setCurrentSegment] = useState("actBidSegment");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const getBidsList = async (status, currentPage) => {
        setTimeout(setLoading(true), 600);
        bidsList(status, currentPage, 10)
            .then(response => {
                if (response && response.results && response.results.data && response.results.data.length) {
                    setTotalPages(response.results.totalPages);
                    setDashboardBidList(response.results.data);
                } else {
                    setTotalPages(1);
                    setDashboardBidList([]);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const getSoldLotsList = async (currentPage) => {
        setTimeout(setLoading(true), 600);
        soldLots(currentPage, 10)
            .then(response => {
                if (response && response.results && response.results.data && response.results.data.length) {
                    setTotalPages(response.results.totalPages);
                    setSoldLotsList(response.results.data);
                } else {
                    setTotalPages(1);
                    setSoldLotsList([]);
                }
                setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    }

    const changeSegment = async (segment) => {
        setCurrentSegment(segment);
        if (segment === "expBidSegment") {
            document.getElementById("soldSegment").classList.remove("active");
            document.getElementById("actBidSegment").classList.remove("active");
            document.getElementById("expBidSegment").classList.add("active");
        } else if (segment === "soldSegment") {
            document.getElementById("soldSegment").classList.add("active");
            document.getElementById("actBidSegment").classList.remove("active");
            document.getElementById("expBidSegment").classList.remove("active");
        } else {
            document.getElementById("soldSegment").classList.remove("active");
            document.getElementById("expBidSegment").classList.remove("active");
            document.getElementById("actBidSegment").classList.add("active");
        }

    }

    const dashboardMatrics = async () => {
        getDashboardMatrics()
            .then(response => {
                if (response && response.data) {
                    setDashboardMatrics(response.data);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const categoriesChartData = async () => {
        getCategoriesChartData()
            .then(response => {
                if (response && response.data) {
                    setCategoriesChartdData(response.data);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const locationsChartData = async () => {
        getLocationsChartData()
            .then(response => {
                if (response && response.data) {
                    setLocationChartData(response.data);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const bidsChartData = async () => {
        getBidsChartData()
            .then(response => {
                if (response && response.data) {
                    setBidChartData(response.data);
                }
                // setTimeout(setLoading(false), 600);
            })
            .catch(error => {
                setTimeout(setLoading(false), 600);
                console.log(error);
                if (error && error.response && error.response.statusText) {
                    setShowToaster(true);
                    setToasterMessage(error.response.statusText);
                }
            });
    };

    const getDashboardDataHandler = async () => {
        setTimeout(setLoading(true), 600);
        await dashboardMatrics();
        await categoriesChartData();
        await locationsChartData();
        await bidsChartData();
        setTimeout(setLoading(false), 600);
    }

    const _CategoriesChartWrapper = () => {
        const categoriesData = categoriesChart.lotsByCategory;
        const data = {
            labels: categoriesData.map(item => item.parent_category),
            datasets: [
                {
                    label: "Total Lots",
                    data: categoriesData.map(item => item.lotCount),
                    hoverOffset: 6
                }
            ],
        };

        // options={options}
        return (
            <div className="categories-chart">
                <Doughnut options={chartOptions} data={data} />
            </div>
        );
    };

    const _LocationChartWrapper = () => {
        const locationData = locationChart.locationsCount;
        const data = {
            labels: locationData.map(item => item._id),
            datasets: [
                {
                    label: "Total Lots",
                    data: locationData.map(item => item.count),
                    hoverOffset: 4
                },
            ],
        };

        return (
            <div className="location-chart">
                <PolarArea options={chartOptions} data={data} />
            </div>
        );
    };

    const _BidsChartWrapper = () => {
        const marketplaceData = bidChart.marketplaceLotsBids;
        const data = {
            labels: marketplaceData.map(item => item._id),
            datasets: [
                {
                    label: "Bids Status ",
                    data: marketplaceData.map(item => item.totalBids),
                    hoverOffset: 4
                },
                {
                    label: "Lots Status ",
                    data: marketplaceData.map(item => item.totalLots),
                    hoverOffset: 4
                },
            ],
        };

        return (
            <div className="location-chart">
                <Bar data={data} />
            </div>
        );
    };


    useEffect(() => {
        let dashboardSection = document.getElementById("dashboardSection");
        dashboardSection.classList.add("active");
        getDashboardDataHandler();
    }, []);

    useEffect(() => {
        const fetchBids = async () => {
            setLoading(true);
            try {
                const bidStatus = currentSegment === "actBidSegment" ? "active" : "closed";
                await getBidsList(bidStatus, currentPage);
            } catch (error) {
                console.error('Failed to fetch bids:', error);
                setShowToaster(true);
                setToasterMessage('Failed to load data');
            } finally {
                setLoading(false);
            }
        };

        if (currentSegment === "soldSegment") {
            getSoldLotsList(currentPage);
        } else {
            fetchBids();
        }
    }, [currentSegment, currentPage]); // Re-fetch when currentSegment changes

    return (
        <div className="main-parant-1">
            <section className="sec-1">
                <Sidebar />
                <section className="home-section">
                    <div className="main-rightbar-box">
                        <div>
                            <div className="Rule-page">
                                <div className="main-jaste">
                                    <div className="mi">
                                        <h1 className="left-title">Hey, {adminUser ? adminUser?.email?.split('@')?.[0].capitalize() : 'Admin'}</h1>
                                    </div>
                                    <div >
                                        <p data-bs-toggle="modal" data-bs-target="#LOGOUT" className="profilee">{adminUser?.email?.charAt(0)?.toUpperCase()}</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="blue-box-roki">
                                        <div className="starwed">
                                            <div>
                                                <img src="assets/img/perspective_matt.svg" className="perspective_matt.svg" alt="" />
                                            </div>
                                            <div className="selli12">
                                                <p className="stepse">Let’s get started!</p>
                                                <p className="stepsw">Ready to start bidding?</p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src="assets/img/arrow-wihte-next.svg" className="perspective_matt.svg" alt="" onClick={() => navigate("/")} />
                                        </div>
                                    </div>
                                    <div className="grider-threw">
                                        <div className="gri-tile">
                                            <p className="order-titl">CURRENT ACTIVE BIDS</p>
                                            <h6 className="zero-titl">{dashboardMatricsVal.totalActiveBidsCount}</h6>
                                            <p className="monthr">{dashboardMatricsVal.activeBidsLast30Days} in last 30 days</p>
                                        </div>
                                        <div className="gri-tile">
                                            <p className="order-titl">EXPIRED BIDS</p>
                                            <h6 className="zero-titl">{dashboardMatricsVal.totalClosedBidsCount}</h6>
                                            <p className="monthr">{dashboardMatricsVal.closedBidsLast30Days} in last 30 days</p>
                                        </div>
                                        {/* ₹ */}
                                        <div className="gri-tile clickable" onClick={() => navigate('/lot-list')}>
                                            <p className="order-titl">TOTAL ACTIVE LOTS</p>
                                            <h6 className="zero-titl">{dashboardMatricsVal.totalActiveLotsCount}</h6>
                                            <p className="monthr">{dashboardMatricsVal.activeLotsLast30Days} in last 30 days</p>
                                        </div>
                                    </div>
                                    <div className="grider-threw">
                                        <div className="gri-tile categories-chart-wrapper">
                                            <p className="order-titl">CATEGORIES</p>
                                            {categoriesChart && categoriesChart.lotsByCategory && categoriesChart.lotsByCategory.length > 0 ?
                                                (_CategoriesChartWrapper())
                                                : ('')}
                                        </div>
                                        <div className="gri-tile">
                                            <p className="order-titl">LOCATIONS</p>
                                            {locationChart && locationChart.locationsCount && locationChart.locationsCount.length > 0 ?
                                                (_LocationChartWrapper())
                                                : ('')}
                                        </div>
                                        <div className="gri-tile">
                                            <p className="order-titl">BID STATUS</p>
                                            {bidChart && bidChart.marketplaceLotsBids && bidChart.marketplaceLotsBids.length > 0 ?
                                                (_BidsChartWrapper())
                                                : ('')}
                                        </div>
                                    </div>
                                    <div className="Updates mb-3">
                                        <h1 className="left-title">Updates</h1>
                                    </div>
                                </div>
                                <div>
                                    <div className="wihte-erya">
                                        <div className="flaxertw">
                                            <div className="activ-sab">
                                                <a id="actBidSegment" className="pointer active-sub active" onClick={() => changeSegment("actBidSegment")}>Current Active Bids</a>
                                                <a id="expBidSegment" className="pointer active-sub" onClick={() => changeSegment("expBidSegment")}>Expired Bids</a>
                                                <a id="soldSegment" className="pointer active-sub" onClick={() => changeSegment("soldSegment")}>Buy Now Lots</a>
                                            </div>
                                        </div>
                                        <div className='table-bids'>
                                            {currentSegment === "actBidSegment" &&
                                                (
                                                    <table className="w-100 m-0">
                                                        <thead>
                                                            <tr className="gry-tr">
                                                                <td className="head-title">Bid ID</td>
                                                                <td className="head-title">Lot ID</td>
                                                                <td className="head-title">Start Date</td>
                                                                <td className="head-title">End Date</td>
                                                                <td className="head-title">Bid Amount</td>
                                                                <td className="head-title">Highest Bid</td>
                                                                <td className="head-title">Buyer ID</td>
                                                                <td className="head-title width-100">Status</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dashboardBidList.map((item, index) => (
                                                                <tr className="gry-tred" key={index}>
                                                                    <td className="body-title">{item.bidid}</td>
                                                                    <td className="body-title">
                                                                        <a onClick={() => navigate(`/selected-lot/${item.lot_info.lotid}`)} className="numberw"> {item.lot_info.lotid}</a>
                                                                    </td>
                                                                    <td className="body-title">{format(new Date(item.lot_info.bid_start_date), "dd/MM/yyyy, hh:MM aaaaa'm'")}</td>
                                                                    <td className="body-title">{format(new Date(item.lot_info.bid_end_date), "dd/MM/yyyy, hh:MM aaaaa'm'")}</td>
                                                                    <td className="body-title">{formatCurrency(item.bid_amount)}</td>
                                                                    <td className="body-title">{formatCurrency(item.lot_info.highest_bid_price)}</td>
                                                                    <td className="body-title">{item.user_info.email}</td>
                                                                    <td className="body-title">{item.status.capitalize()}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                )
                                            }
                                            {currentSegment === "expBidSegment" &&
                                                (
                                                    <table className="w-100 m-0">
                                                        <thead>
                                                            <tr className="gry-tr">
                                                                <td className="head-title">Bid ID</td>
                                                                <td className="head-title">Lot ID</td>
                                                                <td className="head-title">Start Date</td>
                                                                <td className="head-title">Bid Time</td>
                                                                <td className="head-title">Bid Amount</td>
                                                                <td className="head-title">Highest Bid</td>
                                                                <td className="head-title">Winner</td>
                                                                <td className="head-title width-100">Status</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dashboardBidList.map((item, index) => (
                                                                <tr className="gry-tred" key={index}>
                                                                    <td className="body-title">{item.bidid}</td>
                                                                    <td className="body-title">
                                                                        <a onClick={() => navigate(`/selected-lot/${item.lot_info.lotid}`)} className="numberw"> {item.lot_info.lotid}</a>
                                                                    </td>
                                                                    <td className="body-title">{format(new Date(item.lot_info.bid_start_date), "dd/MM/yyyy, hh:MM aaaaa'm'")}</td>
                                                                    <td className="body-title">{format(new Date(item.bid_time), "dd/MM/yyyy, hh:MM aaaaa'm'")}</td>
                                                                    <td className="body-title">{formatCurrency(item.bid_amount)}</td>
                                                                    <td className="body-title">{formatCurrency(item.lot_info.highest_bid_price)}</td>
                                                                    <td className="body-title">{item.user_info.email}</td>
                                                                    <td className="body-title">{item.status.capitalize()}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                )
                                            }
                                            {currentSegment === "soldSegment" &&
                                                (
                                                    <table className="w-100 m-0">
                                                        <thead>
                                                            <tr className="gry-tr">
                                                                <td className="head-title">Lot ID</td>
                                                                <td className="head-title">Start Date</td>
                                                                <td className="head-title">Sold Date</td>
                                                                <td className="head-title">Winning Price</td>
                                                                <td className="head-title">Winner</td>
                                                                <td className="head-title width-100">Status</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {soldLotsList.map((item, index) => (
                                                                <tr className="gry-tred" key={index}>
                                                                    <td className="body-title">
                                                                        <a onClick={() => navigate(`/selected-lot/${item.lotid}`)} className="numberw"> {item.lotid}</a>
                                                                    </td>
                                                                    <td className="body-title">{format(new Date(item.bid_start_date), "dd/MM/yyyy, hh:MM aaaaa'm'")}</td>
                                                                    <td className="body-title">{format(new Date(item.bid_end_date), "dd/MM/yyyy, hh:MM aaaaa'm'")}</td>
                                                                    <td className="body-title">{formatCurrency(item.buy_now_price)}</td>
                                                                    <td className="body-title">
                                                                        <a onClick={() => navigate(`/buyer-info/${item.winner_uid}`)} className="numberw"> {item.winner_uid}</a>
                                                                    </td>
                                                                    <td className="body-title">{item.status.capitalize()}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                )
                                            }
                                        </div>
                                        {dashboardBidList.length === 0 && currentSegment !== 'soldSegment'  &&
                                            (
                                                <div className="space-26px">
                                                    <p className="create-oneedw">No results</p>
                                                </div>
                                            )
                                        }
                                        <ListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            {/* <!-- Modal LOGOUT data-bs-toggle="modal" data-bs-target="#LOGOUT" --> */}
            <div className="modal fade" id="LOGOUT" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog addtag-three side-right">
                    <div className="modal-content styled">
                        <div className="">
                            <div className="positiqw ger">
                                <div className="custm-gride">
                                    <div className='logout-modal-role-wrapper'>
                                        <p className="Customer-13px">Logged in as </p>
                                        <p className='Customer-13px admin-role-wrapper'>{`${adminUser.role.toLowerCase().capitalize()}`}</p>
                                    </div>
                                    <p className="Customer-15px bold">{adminUser ? adminUser?.email : 'Admin'}</p>
                                </div>
                                <div>
                                    <a className="theme-btn-one login-btn" onClick={() => logout("ADMIN")} data-bs-dismiss="modal">LOG OUT</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
